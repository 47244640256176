import {
    Alert,
    Box,
    Button,
    FormControl,
    FormLabel,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import { Logo } from "../../../assets/logo";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import http from "../../../utils/http-common";
import {
    PasswordSvg,
    TickCircleSolid,
} from "../../../utils/icon/LandingPage/Icon";
import { Carousel_OG } from "../Components/Carousel";
import { Model_1 } from "../Components/Model";



export const Forgot_Password = () => {
    const [userMail, setUserMail] = useState("");
    const [otp, setOtp] = useState("");
    const [verified, setVerified] = useState(false);
    const [screen, setScreen] = useState(1);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [severity, setSeverity] = useState("success");
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const handleMailChange = (e) => {
        setUserMail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const validateForm = () => {
        let tempErrors = {};
        let isValid = true;

        // Email validation
        if (!userMail) {
            tempErrors.userMail = "Email is required.";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(userMail)) {
            tempErrors.userMail = "Email address is invalid.";
            isValid = false;
        }
        setErrors(tempErrors);
        return isValid;
    };
    const handleSubmit = () => {
        if (validateForm()) {
            Forgot_Password_1();
        }
        // else {
        //   // setMessage("Please correct the highlighted errors.");
        //   setSeverity("error");
        //   setOpen(true);
        // }
    };
    const OtpvalidateForm = () => {
        let tempErrors = {};
        let isValid = true;

        // Name validation
        if (!otp) {
            tempErrors.otp = "Enter Otp";
            isValid = false;
        }
        // Password validation
        if (
            confirmPassword.length < 8 ||
            !/[A-Z]/.test(confirmPassword) ||
            !/[a-z]/.test(confirmPassword) ||
            !/[0-9]/.test(confirmPassword) ||
            !/[!@#$%^&*]/.test(confirmPassword)
        ) {
            tempErrors.confirmPassword = "Enter valid password";
            isValid = false;
        }
        if (
            newPassword.length < 8 ||
            !/[A-Z]/.test(newPassword) ||
            !/[a-z]/.test(newPassword) ||
            !/[0-9]/.test(newPassword) ||
            !/[!@#$%^&*]/.test(newPassword)
        ) {
            tempErrors.newPassword = "Enter valid password";
            isValid = false;
        }

        setErrors(tempErrors);
        return isValid;
    };

    const ChangePassword = () => {
        if (OtpvalidateForm()) {
            Forgot_Password_2();
        }
        // else {
        //   setMessage("Please correct the highlighted errors.");
        //   setSeverity("error");
        //   setOpen(true);
        // }
    };
    const Forgot_Password_1 = async () => {
        try {
            // const response = await http.post('/forgot/password', JSON.stringify({
            const response = await http.post(
                "auth_service/forgot/password",
                JSON.stringify({
                    user_email: userMail,
                    // newPassword: newPassword,
                    // confirmPassword: confirmPassword
                })
            );

            console.log("res", response);

            if (response.status === 200) {
                setScreen(2);
                setMessage(response.data.message);
                localStorage.setItem("token", response.data.token);
                setSeverity("success");
                handleClick();
            }
        } catch (error) {
            console.error("Error verifying OTP", error);
            setMessage(error.response.data.message);
            setSeverity("error");
            handleClick();
        }
    };

    const Forgot_Password_2 = async () => {
        try {
            // const response = await http.post('/forgot/password', JSON.stringify({
            const response = await http.post(
                "auth_service/reset/password",
                JSON.stringify({
                    user_email: userMail,
                    otp: otp,
                    newPassword: newPassword,
                    confirmPassword: confirmPassword,
                })
            );

            console.log("res", response);

            if (response.data.message === "Password reset successfully!") {
                setMessage(response.data.message);
                setSeverity("success");
                handleClick();
                // setScreen(0);
                // setVerified(true)
                setTimeout(() => {
                    navigate("/Login");
                }, 2000);
            } else {
                setMessage(response.data.message);
                setSeverity("error");
                handleClick();
            }

            if (response.status === 200) {
                setOtp("");
                setMessage(response.data.message);
                // localStorage.setItem("token", response.data.token);
                setSeverity("success");
                handleClick();
            }
        } catch (error) {
            console.error("Error verifying OTP", error);
            setMessage(error.response.data.message);
            // setMessage("Error resetting password", error.message);
            setSeverity("error");
            handleClick();
        }
    };
    const ResendOtp = async () => {
        try {
            // const response = await http.post('/otpverification', JSON.stringify({
            const response = await http.post(
                "auth_service/Resend-OTP",
                JSON.stringify({
                    user_email: userMail,
                })
            );
            console.log("res", response);

            if (
                response.data.message ===
                "An Email sent to your account please verify" ||
                response.status === 201
            ) {
                setOtp("");
                setMessage(response.data.message);
                setSeverity("success");
                handleClick();
            }
        } catch (error) {
            console.error("Error verifying OTP", error);
            setMessage(error.response.data.message);
            // setMessage("Error resetting password", error.message);
            setSeverity("error");
            handleClick();
        }
    };

    return (
        <Box sx={{ display: "flex" }}>
            <Box
                sx={{
                    width: "50%",
                    height: "100vh",
                    display: { xs: "none", sm: "flex" },
                    flexDirection: "column",
                    background: "#000000",
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                    color: "#fff",
                    py: 4,
                    px: 5,
                }}
            >
                <Link to={"/"}>
                    <Logo />
                </Link>
                <Carousel_OG />
            </Box>
            {screen === 1 && (
                <Box
                    sx={{
                        width: { xs: "100%", sm: "50%" },
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#ffffff",
                        color: "#474747",
                        py: 10,
                        px: 5,
                    }}
                >
                    <FormControl sx={{ width: { xs: "100%", sm: "75%" } }}>
                        <Typography
                            sx={{
                                fontWeight: "700",
                                fontSize: "32px",
                                display: "flex",
                                mt: "15px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "600",
                                    fontSize: "32px",
                                    background:
                                        "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                }}
                            >
                                Change Password
                            </Typography>
                            ✨
                        </Typography>

                        <Box sx={{ display: "flex", flexDirection: "column", mb: "15px" }}>
                            <FormLabel>Email ID*</FormLabel>
                            <TextField
                                placeholder="Enter Email ID"
                                type="email"
                                required
                                sx={{ color: "#AFAFAF", height: "43px" }}
                                onChange={handleMailChange}
                                value={userMail}
                                error={!!errors.userMail}
                                helperText={errors.userMail}
                            />
                        </Box>
                        <Box
                            sx={{
                                position: "relative",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Button
                                sx={{
                                    color: "#fff",
                                    background: "#8A18D0",
                                    height: "43px",
                                    textTransform: "capitalize",
                                    my: "25px",
                                    "&:hover": { background: "#B37FFC" },
                                }}
                                onClick={handleSubmit}
                            >
                                Send Code
                            </Button>
                            <Snackbar
                                open={open}
                                autoHideDuration={2000}
                                onClose={handleClose}
                                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                sx={{
                                    position: "absolute",
                                    mt: "80px",
                                    width: "100%",
                                }}
                            >
                                <Alert
                                    onClose={handleClose}
                                    severity={severity}
                                    variant="filled"
                                    sx={{ width: "100%" }}
                                >
                                    {message}
                                </Alert>
                            </Snackbar>
                        </Box>
                    </FormControl>
                </Box>
            )}
            {screen === 2 && (
                <Box
                    sx={{
                        width: { xs: "100%", sm: "50%" },
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#ffffff",
                        color: "#474747",
                        py: 10,
                        px: 5,
                    }}
                >
                    <FormControl sx={{ width: { xs: "100%", sm: "75%" } }}>
                        <Typography
                            sx={{
                                fontWeight: "700",
                                fontSize: "32px",
                                display: "flex",
                                mt: "15px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "600",
                                    fontSize: "32px",
                                    background:
                                        "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                }}
                            >
                                Change Password
                            </Typography>
                            ✨
                        </Typography>

                        <Box sx={{ display: "flex", flexDirection: "column", mb: "15px" }}>
                            <FormLabel>Code*</FormLabel>
                            <TextField
                                placeholder="Enter code"
                                type="text"
                                sx={{ color: "#AFAFAF", height: "43px" }}
                                onChange={(e) => setOtp(e.target.value)}
                                value={otp}
                                error={!!errors.otp}
                                helperText={errors.otp}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "end",
                                my: { xs: "0", sm: "5px" },
                            }}
                        >
                            <Button
                                disableFocusRipple
                                disableTouchRipple
                                sx={{
                                    textTransform: "capitalize",
                                    color: "#474747",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                }}
                                onClick={() => ResendOtp()}
                            >
                                Resend Code?
                            </Button>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", mb: "15px" }}>
                            <Box sx={{ display: "flex", alignItems: "center", mb: "5px" }}>
                                <FormLabel sx={{ flexGrow: 1 }}>Password*</FormLabel>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        position: "relative",
                                        "&:hover .model-1": {
                                            display: "block",
                                        },
                                    }}
                                >
                                    <PasswordSvg />
                                    <Box
                                        sx={{
                                            display: "none",
                                            position: "absolute",
                                            right: 0,
                                            top: "100%",
                                            zIndex: 1,
                                        }}
                                        className="model-1"
                                    >
                                        <Model_1 />
                                    </Box>
                                </Box>
                            </Box>
                            <TextField
                                placeholder="Enter password"
                                type="password"
                                sx={{ color: "#AFAFAF", height: "43px" }}
                                onChange={handlePasswordChange}
                                value={newPassword}
                                error={!!errors.newPassword}
                                helperText={errors.newPassword || ""}
                            />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", my: "25px" }}>
                            <FormLabel>Re-Enter Password*</FormLabel>
                            <TextField
                                placeholder="Confirm password"
                                type="password"
                                sx={{ color: "#AFAFAF", height: "43px" }}
                                onChange={handleConfirmPasswordChange}
                                value={confirmPassword}
                                error={!!errors.confirmPassword}
                                helperText={errors.confirmPassword || ""}
                            />
                        </Box>
                        <Box
                            sx={{
                                position: "relative",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Button
                                sx={{
                                    color: "#fff",
                                    background: "#8A18D0",
                                    height: "43px",
                                    textTransform: "capitalize",
                                    my: "25px",
                                    "&:hover": { background: "#B37FFC" },
                                }}
                                onClick={ChangePassword}
                            >
                                Change Password
                            </Button>
                            <Snackbar
                                open={open}
                                autoHideDuration={2000}
                                onClose={handleClose}
                                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                sx={{ position: "absolute", mt: "78px", width: "100%" }}
                            >
                                <Alert
                                    onClose={handleClose}
                                    severity={severity}
                                    variant="filled"
                                    sx={{ width: "100%" }}
                                >
                                    {message}
                                </Alert>
                            </Snackbar>
                        </Box>
                    </FormControl>
                </Box>
            )}
            {verified && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <TickCircleSolid />
                    <Typography
                        sx={{
                            fontWeight: "600",
                            fontSize: "32px",
                            display: "flex",
                            mt: "10px",
                            mb: "17px",
                            background:
                                "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}
                    >
                        Verified
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: "500",
                            fontSize: "20px",
                            display: "flex",
                            mt: "10px",
                            mb: "17px",
                            color: "#000000",
                        }}
                    >
                        Your Account has been verified successfully.
                    </Typography>
                </Box>
            )}
            {/* <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar> */}
        </Box>
    );
};
