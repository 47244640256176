import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { Date_Icon } from "../../utils/icon/IndividualPages/Icon.jsx";

const DateRangePicker = ({
  allowFutureDates = true,
  highlightColor = "green",
  dateRange,
  setDateRange,
  Theme,
  EaseSelect = false,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [hoverDate, setHoverDate] = useState(null);

  const getCalendarDates = (year, month) => {
    const startDay = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    let dates = [];

    // Fill in empty slots for the first week
    for (let i = 0; i < startDay; i++) {
      dates.push(null);
    }

    // Fill in actual dates of the month
    for (let i = 1; i <= daysInMonth; i++) {
      dates.push(new Date(year, month, i));
    }

    return dates;
  };

  const handleDateClick = (date) => {
    if (!dateRange.startDate || (dateRange.startDate && dateRange.endDate)) {
      console.log("dr", dateRange);
      setDateRange({ startDate: date, endDate: null });
    } else if (
      dateRange.startDate &&
      !dateRange.endDate &&
      date > dateRange.startDate
    ) {
      console.log("dr 2", dateRange);
      setDateRange({ ...dateRange, endDate: date });
      setShowCalendar(false);
    } else if (
      dateRange.startDate &&
      !dateRange.endDate &&
      date < dateRange.startDate &&
      EaseSelect
    ) {
      setDateRange({ startDate: date, endDate: dateRange.startDate });
      setShowCalendar(false);
    }
  };
  const isDateInRange = (date) => {
    if (!dateRange.startDate || !dateRange.endDate) return false;
    return (
      (date >= dateRange.startDate && date <= dateRange.endDate) ||
      (date >= dateRange.startDate &&
        date <= hoverDate &&
        dateRange.endDate === null) ||
      (date <= dateRange.startDate &&
        date >= hoverDate &&
        dateRange.endDate === null)
    );
  };

  const isFutureDate = (date) => {
    const today = new Date();
    return date > today && allowFutureDates;
  };

  const renderCalendar = (year, month) => {
    const dates = getCalendarDates(year, month);
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          gap: "5px",
        }}
      >
        {["S", "M", "T", "W", "T", "F", "S"].map((day, i) => (
          <div
            key={i}
            style={{
              fontWeight: "bold",
              textAlign: "center",
              color: " #8A18D0",
            }}
          >
            {day}
          </div>
        ))}
        {dates.map((date, index) => (
          <Button
            key={index}
            sx={{
              minWidth: "40px",
              height: "40px",
              borderRadius: "50%",
              px: 0,
              backgroundColor: date
                ? isDateInRange(date)
                  ? highlightColor
                  : "white"
                : "transparent",
              color: isFutureDate(date)
                ? "gray"
                : isDateInRange(date)
                  ? "#fff"
                  : "black",
              pointerEvents: isFutureDate(date) ? "none" : "auto",
              cursor: date ? "pointer" : "default",
              textAlign: "center",
              "&:hover": {
                background: "#a369c7",
                color: "#fff",
                border: date ? "1px solid lightgray" : "none",
              },
            }}
            onClick={() => date && handleDateClick(date)}
            onMouseEnter={() => date && setHoverDate(date)}
            onMouseLeave={() => setHoverDate(null)}
          >
            {date ? date.getDate() : ""}
          </Button>
        ))}
      </div>
    );
  };

  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };
  const handleClear = () => {
    setDateRange({ startDate: "", endDate: "" });
  };
  return (
    <Box sx={{ position: { xs: 'initial', md: "relative" } }}>
      <Box sx={{ display: "flex", flexDirection: { xs: 'column', md: 'row' }, gap: "10px" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label
            style={{
              marginBottom: "4px",
              fontSize: "13px",
              fontWeight: "600",
              color: "#000000",
            }}
          >
            Select Data From
          </label>
          <div style={{ position: "relative" }}>
            <input
              type="text"
              value={
                dateRange.startDate
                  ? dateRange.startDate.toLocaleDateString()
                  : ""
              }
              placeholder="Date From"
              onFocus={() => setShowCalendar(true)}
              readOnly
              style={{
                padding: "5px",
                width: "150px",
                borderRadius: "4px",
                border: "1px solid lightgray",
              }}
            />
            <div
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                pointerEvents: "none",
              }}
            >
              <Date_Icon />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label
            style={{
              marginBottom: "4px",
              fontSize: "13px",
              fontWeight: "600",
              color: "#000000",
            }}
          >
            {" "}
            Select Data Till
          </label>
          <div style={{ position: "relative" }}>
            <input
              type="text"
              value={
                dateRange.endDate ? dateRange.endDate.toLocaleDateString() : ""
              }
              placeholder="Date To"
              onFocus={() => setShowCalendar(true)}
              readOnly
              style={{
                padding: "5px",
                width: "150px",
                borderRadius: "4px",
                border: "1px solid lightgray",
              }}
            />
            <div
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                pointerEvents: "none",
              }}
            >
              <Date_Icon />
            </div>
          </div>
        </div>
      </Box>

      {showCalendar && (
        <div
          style={{
            position: "absolute",
            top: "60px",
            left: "0",
            background: "white",
            border: "1px solid lightgray",
            padding: "10px",
            borderRadius: "4px",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Button
              onClick={handlePrevMonth}
              sx={{ padding: "5px", minWidth: "min-content" }}
            >
              <NavigateBefore />
            </Button>
            <span>{`${new Date(currentYear, currentMonth).toLocaleString(
              "default",
              { month: "long" }
            )} ${currentYear}`}</span>
            <Button
              onClick={handleNextMonth}
              sx={{ padding: "5px", minWidth: "min-content" }}
            >
              <NavigateNext />
            </Button>
          </div>
          {renderCalendar(currentYear, currentMonth)}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() => setShowCalendar(false)}
              style={{ marginTop: "10px", padding: "5px", color: " #8A18D0" }}
            >
              Close
            </Button>
            <Button
              onClick={handleClear}
              style={{ marginTop: "12px", padding: "5px", color: " #8A18D0" }}
            >
              Clear
            </Button>
          </div>
        </div>
      )}
    </Box>
  );
};

export default DateRangePicker;
