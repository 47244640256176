import { Box, Typography } from "@mui/material";
import { useContext, useState } from "react";
import CountryContext from "../../components/context/CountryContext";
import { Filter } from "../../components/filter";
import { brandIconMap } from "../../components/icons";
import { Chart } from "../../components/card/brand-card-2";
import { ProductTableData } from "../../utils/data/ProductTableData";
import { TableComp } from "../../components/table/type - 1";
import { subBrandDetails } from "../../utils/data/SubBrandDetails";
import { SellerData } from "../../utils/data/SellerData";


export const Brand = () => {
    const { country, setCountry, brand, setBrand, subBrand, setSubBrand, seller, setSeller, product, setProduct, separateProduct, setSeparateProduct, DashboardTableData } = useContext(CountryContext)
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: '70px', p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', my: '12px' }}>
                <Box
                    component="img"
                    src={brandIconMap[DashboardTableData[brand].Brand_Name]}
                    alt={DashboardTableData[brand].Brand_Name}
                    sx={{ mr: 2, width: '150px', height: 'auto' }}
                />
                {/* <img src={brandIconMap[data[brand].Brand_Name]} alt={data[brand].Brand_Name} sx={{ mr: 2, width: '150px', height: 'auto' }} /> */}
                <Typography sx={{ fontWeight: '500', fontSize: '18px', lineHeight: '28px' }}>Overview</Typography>
                <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#1DA1F2', px: '7px', py: 0, height: '25px', display: 'flex', alignItems: 'center', ml: '5px', background: '#E8F6FE', borderRadius: '16px' }}>4 Platforms</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', my: '10px' }}>
                <Chart i={0} />
                <Chart i={1} />
                <Chart i={2} />
                <Chart i={3} />
                {/* <Chart i={1} />
                <Chart i={2} /> */}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', my: '15px', width: '100%', alignItems: 'center' }}>
                <Typography sx={{ my: '15px', fontSize: '16px', fontWeight: '600' }}>Product List</Typography>
                {/* <Filter /> */}
            </Box>
            <Box sx={{ overflow: 'auto' }}>
                <TableComp Data={SellerData} />
                {/* <TableComp ith={brand} Data={ProductTableData} /> */}
            </Box>
        </Box>
    );
}