import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { Dashboard } from "./pages/dashboard/index.jsx";
import { Main } from "./pages/main/index.jsx";
import { Brand } from "./pages/brand/index.jsx";
import { Sellers } from "./pages/seller/index.jsx";
import { DetailedSeller } from "./pages/detailed-seller/index.jsx";
import { Product } from "./pages/product/index.jsx";
import { Home } from "./pages/home/index.jsx";
import {
  Forgot_Password,
  Login,
  Otp,
  Registeration,
} from "./pages/Auth/index.jsx";
import { About } from "./pages/about/index.jsx";
import { Contact } from "./pages/contact/index.jsx";
import { Main_2 } from "./pages/main-2/index.jsx";
import IndividualUserLandingPage from "./pages/IndividualUserLandingPage/index.jsx";
import { DataSelection } from "./pages/DataSelection/index.jsx";
import { RechargeCredits } from "./pages/RechargeCredits/index.jsx";
import { Bundle_Log } from "./pages/Bundle_Log/index.jsx";
import { Raise_Ticket } from "./pages/Raise_Ticket/index.jsx";
import { Bundle_Creation } from "./pages/Bundle_Creation/index.jsx";
import { Bundle_Run_Log } from "./pages/Bundle_Run_Log/index.jsx";
import { Custom_Bundle } from "./pages/Custom_Bundle/index.jsx";
import http from "./utils/http-common.js";
import { Sample_Data } from "./pages/Sample-Data/index.jsx";
import { Create_Bundle } from "./pages/Create_Bundle/index.jsx";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./themes/index.js";
import { Main_3 } from "./pages/main-3/index.jsx";
import { CreditRequest } from "./pages/Credit_Request/index.jsx";
import { Faq } from "./pages/Faq/index.jsx";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./store/index.js";
import { Static_Data } from "./pages/Static_Data/index.jsx";
import { Test } from "./pages/Test/index.jsx";
import { Pricing } from "./pages/Pricing/index.jsx";

const PrivateRoute = ({ element: Element, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        console.log("ent fn");
        const response = await http.post("/auth_service/token");
        if (response.status === 200) {
          console.log("ent 200");
          setIsAuthenticated(true);
        } else {
          console.log("not ent 200");
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
        console.log(error);
      }
    };

    checkAuthentication();
  }, []);

  // const isAuthenticated2 = localStorage.getItem('token')
  if (isAuthenticated === null) {
    // While the authentication status is being checked, you might want to show a loading indicator.
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <Element {...rest} /> : <Navigate to="/Login" />;
  // return isAuthenticated2 ? <Element {...rest} /> : <Navigate to="/Login" />;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/faq",
    element: <Faq />,
  },
  {
    path: "/Reg",
    element: <Registeration />,
  },
  {
    path: "/Login",
    element: <Login />,
  },
  {
    path: "/Otp",
    element: <Otp />,
  },
  {
    path: "/Forgot-Password",
    element: <Forgot_Password />,
  },
  {
    path: "/data-info",
    element: <Static_Data />
  },
  {
    path: "/Pricing",
    element: <Pricing />
  },
  {
    path: "/Dashboard/Individual-Dashboard",
    element: <PrivateRoute element={Main_2} />,
    children: [
      {
        path: "",
        element: <IndividualUserLandingPage />,
      },
      {
        path: "Bundle-Log",
        element: <Bundle_Log />,
      },
      {
        path: "Create-Bundle",
        element: <Create_Bundle />,
      },
      {
        path: "User-Wizard",
        element: <DataSelection />,
      },
      {
        path: "Custom-Bundle",
        element: <Custom_Bundle />,
      },
      {
        path: "Bundle-Creation",
        element: <Bundle_Creation />,
      },
      {
        path: "Sample-Data",
        element: <Sample_Data />,
      },
      {
        path: "Bundle-Run-Log",
        element: <Bundle_Run_Log />,
      },
      {
        path: "Recharge",
        element: <RechargeCredits />,
      },
      {
        path: "Raise-Ticket",
        element: <Raise_Ticket />,
      },
      // {
      //   path: "test",
      //   element: <Test />,
      // },
    ],
  },
  {
    path: "/Admin/Dashboard",
    element: <PrivateRoute element={Main_3} />,
    children: [
      {
        path: "",
        element: <CreditRequest />,
      },
    ],
  },
  {
    path: "/Dashboard",
    element: <PrivateRoute element={Main} />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "Brands",
        element: <Brand />,
      },
      {
        path: "Product",
        element: <Product />,
      },
      {
        path: "Sellers",
        element: <Sellers />,
      },
      {
        path: "Detailed-Seller",
        element: <DetailedSeller />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />, // Redirect unknown routes to home
  },
]);
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </ReduxProvider>
  </React.StrictMode>
);
