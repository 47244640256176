import { Box, Typography } from "@mui/material";

export const Card_2 = ({ data }) => {
    // console.log('wd', data.wd)
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: 'center',
                borderRadius: "7px",
                border: "1px solid #EAECF0",
                py: "14px",
                pl: { xs: '8px', md: 0 },
                // p: "24px",
                // minWidth: "220px",
                width: { xs: 'calc(50% - 8px)', sm: 'calc(50% - 8px)', md: data.wd },
                mx: { xs: '4px', md: '0px' },
                mb: { xs: '8px', md: '0' },
            }}
        >
            <data.icon />
            <Box sx={{ display: "flex", flexDirection: "column", ml: { xs: 1, md: 3 } }}>
                <Typography
                    sx={{ fontSize: "12px", fontWeight: "600", color: "#000000", textWrap: { xs: 'wrap', md: 'nowrap' } }}
                >
                    {data.title}
                </Typography>
                <Typography sx={{ fontSize: "18px", fontWeight: "500", color: "#000" }}>
                    {data.value}
                </Typography>
            </Box>
        </Box>
    );
};


export const Card_3 = ({ data }) => {
    // console.log('wd', data.wd)
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "7px",
                border: data.bd,
                p: { xs: '15px', md: '24px' },
                px: { xs: '5px', md: '24px' },
                // minWidth: "220px",
                width: { xs: 'calc(50% - 8px)', sm: 'calc(50% - 8px)', md: `${data.wd}` },
                // width: { xs: '100%', md: data.wd },
                my: { xs: 1, md: 0 },
                background: data.bg,
                // mx: "4px",
            }}
        >
            <data.icon />
            <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
                <Typography
                    sx={{ fontSize: "12px", fontWeight: "600", color: "#000000" }}
                >
                    {data.title}
                </Typography>
                <Typography sx={{ fontSize: "18px", fontWeight: "500", color: "#000" }}>
                    {data.value}
                </Typography>
            </Box>
        </Box>
    );
};
