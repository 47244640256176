import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

export const StyledTypography = styled(Typography)({
  color: "#6b6666",
  fontWeight: "400",
  fontSize: "15px",
  marginLeft: "10px",
});

export const StyledGrid = styled("div")({
  borderLeft: "2px #064CD0 solid",
  height: "20px",
  marginTop: "6px",
});

export const StyledDataGrid = styled(DataGrid)(({ theme, dataLength }) => ({
  // border: '0',
  borderRadius: 10,
  backgroundColor: "#fff",
  boxShadow:
    "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  border: "1px solid #EDEFF0",
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnHeaders": {
    minHeight: "45px !important",
    height: "45px !important",
  },
  "& .MuiDataGrid-virtualScrollerContent": {
    height: dataLength > 0 ? "auto" : "350px !important",
  },
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    fontSize: "12px",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    fontSize: "10.5px",
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.65)",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  "& .customHeader": {
    backgroundColor: "#8A18D01A",
    color: '',
  },
  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
    outline: "none",
  },
  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
    {
      outline: "none",
    },
}));

export const basePopperStyles = {
  "& .MuiPaper-root": {
    borderRadius: "10px",
  },
  "& .MuiDataGrid-menuList": {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    p: 1,
    width: 140,
    backgroundImage:
      'url("../../assets/images/cyan-blur.png"),url("../../assets/images/red-blur.png")',
    backgroundRepeat: "no-repeat, no-repeat",
    backgroundSize: "50%, 50%",
    backgroundPosition: "right top, left bottom",
    borderRadius: "10px",
    "& .MuiMenuItem-root": {
      px: 1,
      typography: "body2",
      borderRadius: 0.75,
      "& .MuiListItemIcon-root": {
        color: "unset",
      },
    },
  },
};

export const columnMenuStyles = {
  backgroundColor: "#8A18D01A",
};
