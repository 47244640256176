import { Box } from "@mui/material";
import { TopNav_2 } from "../../components/nav/top_nav_2";
import { Outlet } from 'react-router-dom';
import { useEffect, useState } from "react";
import { SideNav_3 } from "../../components/nav/side_nav_3";
import { adminNavItms, navItms, routesMap, shortSideNav, sideNav } from "../../utils/data/IndividualDashboard/Data";


export const Main_2 = () => {
    const [nav, setNav] = useState(-1);
    const [subNav, setSubNav] = useState(-1);
    const [minimize, setMinimize] = useState(false)
    const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1100)
    const handleResize = () => {
        setMinimize(window.innerWidth < 1100);
        setSmallScreen(window.innerWidth < 1100);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <Box sx={{ display: 'flex', height: '100vh', width: '100%' }}>
            <SideNav_3 nav={nav} subNav={subNav} minimize={minimize} smallScreen={smallScreen} setSmallScreen={setSmallScreen} setMinimize={setMinimize} setSubNav={setSubNav} setNav={setNav} Data={!minimize ? sideNav : shortSideNav} routesMap={routesMap} commonPath={'/Dashboard/Individual-Dashboard'} />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: { xs: '100%', md: '100%' } }}>
                <TopNav_2 setNav={setNav} minimize={minimize} setMinimize={setMinimize} Data={!smallScreen ? navItms : adminNavItms} />
                <Box sx={{ height: '100%', overflowY: 'auto' }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}