import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { EditSvg } from "../Bundle_Log";
import { Link, useNavigate } from "react-router-dom";
import {
  BrandsIcon,
  CategoriesChoosenIcon,
  CountriesAddedIcon,
  EcommerceIcon,
  Model_1,
  modifyDataIDP,
  TotalCreditCostIcon,
} from "../IndividualUserLandingPage";
import { useEffect, useState } from "react";
import { TableComp6 } from "../../components/table/type - 6";
import http from "../../utils/http-common";
import { DatePlace, generateTableData } from "../DataSelection/Private";
import { ConfigStyleBar } from "../../utils/data/IndividualDashboard/Data";
import { PlaySvg2 } from "../../utils/icon/IndividualPages/Icon";
import { Run_Bundle } from "../../components/Public/RunBundle";
import { downloadCSV } from "../../components/Public/DownloadCSV";
import { useDispatch, useSelector } from "react-redux";
import { updateBundle } from "../../slice/bundle";
import { Card_2 } from "../../components/card/Bundle-Card";
import { dateClnse3, RawDate } from "../../components/Public/DateClnse";
import { updateCredit } from "../../slice/credits";

export const Bundle_Creation = () => {
  const [open, setOpen] = useState(false);
  const navi = useNavigate();
  const credi = useSelector((state) => state.credit.credit);
  const [bundleNameEdit, setBundleNameEdit] = useState(false);
  const dispatch = useDispatch();
  const bundle = useSelector((state) => state.bundles.bundle);
  const [bundleData, setBundleData] = useState({});
  const [bundleName, setBundleName] = useState(
    bundleData.data ? bundleData.data.ind_bundle_name : ""
  );
  const [openS, setOpenS] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const handleClick = () => {
    setOpenS(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenS(false);
  };
  // const [bundleName, setBundleName] = useState(
  //   'Darshan'
  // );
  useEffect(() => {
    if (bundle && bundle.data) {
      console.log("ent bc", bundle);
      setBundleData(bundle);
      setBundleName(bundle && bundle.data ? bundle.data.ind_bundle_name : "");
    } else {
      console.log("exiting", bundle);
      navi("/Dashboard/Individual-Dashboard");
    }
  }, [bundle]);
  const remainingDatas = {
    card: [
      {
        icon: TotalCreditCostIcon,
        title: "Total Credit Cost",
        value: +(bundleData.data ? bundleData.data.estimated_credits : 0),
      },
      {
        icon: CategoriesChoosenIcon,
        title: "Categories Chosen",
        value: "1",
      },
      {
        icon: CountriesAddedIcon,
        title: "Countries Added",
        value: "1",
      },
      {
        icon: EcommerceIcon,
        title: "E-Commerce Sites",
        value: bundleData.data
          ? bundleData.data.ind_bundle_e_commerce.length
          : 0,
      },
      {
        icon: BrandsIcon,
        title: "Brands Selected",
        value: bundleData.data ? bundleData.data.ind_bundle_brand.length : 0,
      },
    ],
    table: {
      Category: bundleData.data ? bundleData.data.ind_bundle_category : "",
      Country: bundleData.data ? bundleData.data.ind_bundle_country : "",
      "E-Commerce Site": bundleData.data
        ? bundleData.data.ind_bundle_e_commerce
        : "",
      Brand: bundleData.data ? bundleData.data.ind_bundle_brand : "",
      "Configured Date": bundleData.data
        ? bundleData.data.ind_bundle_config_date
        : "",
    },
  };
  // console.log
  const FetchDatas = async () => {
    try {
      const response = await http.post(
        "/master_service/bundle/log/list",
        JSON.stringify({ user_id: localStorage.getItem("user_id") })
      );
      const modifiedData = response.data.data.filter(
        (data) => data.ind_bundle_id === bundleData.data.ind_bundle_id
      );
      // setRespData(modifiedData);
      // setBundleCreated(response.data.Bundle_Created)
      // setBundleRuns(response.data.Bundle_Runs)
      // await Credit()
      dispatch(updateBundle({ data: modifiedData[0] }));
      setBundleData({ data: modifiedData[0] });
      console.log("respon", response, response.data.data, modifiedData);

      // setMessage(response.data.message);
      // setSeverity("success");
      // handleClick();
    } catch (error) {
      console.log("fetching error", error);
      // setMessage(
      //   error.response
      //     ? error.response.data.message
      //     : "Unable to Fetch the Data."
      // );
      // setSeverity("error");
      // handleClick();
    }
  };

  const RunBundle = async (bundle_id) => {
    console.log("bngl", bundleData);
    if (bundleData.data === null) return;
    try {
      const payload = {
        ind_bundle_id: bundle_id,
        user_id: localStorage.getItem("user_id"),
      };
      const response = await http.post("master_service/run/bundle", payload);
      console.log("resp", response, response.data.data);
      dispatch(
        updateCredit({
          available_credit: response.data.credit_info.balance_credit,
          used_credit: response.data.credit_info.used_credit,
        })
      );
      // dispatch(updateCredit({ available_credit: response.data.available_credit, used_credit: response.data.used_credit }))
      downloadCSV(
        response.data.data,
        `${bundleData.data.ind_bundle_name}__${dateClnse3(
          new Date()
        )}_${new Date().toLocaleTimeString()}`
      );
      console.log("bund", bundle);
      await FetchDatas();
      setMessage("Bundle run sucessfully");
      setSeverity("success");
      handleClick();
    } catch (error) {
      console.error("Error with RunBundle:", error);
      setMessage(
        error.response ? error.response.data.message : "Unable to Run the Data."
      );
      setSeverity("error");
      handleClick();
    }
  };
  const DownloadBundle = async (bundle_id) => {
    if (!bundleData.data) return;
    try {
      const payload = {
        ind_bundle_id: bundle_id,
        user_id: localStorage.getItem("user_id"),
      };
      const response = await http.post(
        "master_service/download_bundle",
        payload
      );
      console.log("resp", response, response.data.data);
      dispatch(
        updateCredit({
          available_credit: response.data.available_credit,
          used_credit: response.data.used_credit,
        })
      );
      downloadCSV(
        response.data.data,
        `${bundleData.data.ind_bundle_name}__${dateClnse3(
          new Date()
        )}_${new Date().toLocaleTimeString()}`
      );
      await FetchDatas();

      setMessage(response.data.message);
      setSeverity("success");
      handleClick();
    } catch (error) {
      console.error("Error with RunBundle:", error);
      console.log("Error with RunBundle:", error);
      setMessage(
        error.response
          ? error.response.data.message
          : "Unable to Download the Data."
      );
      setSeverity("error");
      handleClick();
    }
  };

  const Sample_Data = async () => {
    if (!bundleData.data) return;
    try {
      // const f = dateRange[0];
      // const f = dateClnse2(dateRange.startDate);
      // const f = dateClnse2(dateRange[0]);
      // const t = dateRange[1];
      // const t = dateClnse2(dateRange.endDate);
      // const t = dateClnse2(dateRange[1]);
      // console.log("ft", f, t);
      const payload = {
        category_id: bundleData.data.ind_bundle_category_id,
        site_id: bundleData.data.ind_bundle_e_commerce_id,
        brand_id: bundleData.data.ind_bundle_brand_id,
        country_id: bundleData.data.ind_bundle_country_id,
        from_date: bundleData.data.ind_bundle_start_date,
        to_date: bundleData.data.ind_bundle_end_date,
      };
      const response = await http.post(
        "master_service/sample/bundle/data",
        payload
      );
      downloadCSV(
        response.data.data,
        bundleData.data.ind_bundle_name + "_Sample_Data"
      );
      // setBundleData(response.data.data);
      // setTbl({
      //   Category: response.data.payload.ind_bundle_category,
      //   Country: response.data.payload.ind_bundle_country,
      //   "E-Commerce Site": response.data.payload.ind_bundle_e_commerce,
      //   Brand: response.data.payload.ind_bundle_brand,
      //   "Configured Date": response.data.payload.ind_bundle_config_date,
      // });
      console.log(
        "res smpl data",
        response,
        response.data.payload.ind_bundle_category
      );
      // localStorage.setItem('sample_datas', JSON.stringify(response.data))
      // window.location.href = '/Dashboard/Individual-Dashboard/Sample-Data'
    } catch (error) {
      console.error("Error fetching sample data:", error);
    }
  };

  const data = [
    {
      title: "Test_Bundle_01",
      btn_1: "Sample Data",
      btn_1_Link: "/Dashboard/Individual-Dashboard/Bundle-Creation",
      btn_2: "Send Quotation",
      btn_2_Link: "/Dashboard/Individual-Dashboard/Bundle-Creation",
      btn_3: "Run Bundle",
      btn_3_2pO: "Download Bundle",
      // btn_3_Link: '/Dashboard/Individual-Dashboard/Bundle-Creation'
    },
  ];
  // const ModelData = {
  //   title: "Run Bundle",
  //   emoji: "",
  //   txt: [
  //     "Running the bundle costs credits.",
  //     "You need <b>20 Credits</b> to run this bundle.",
  //   ],
  //   cl: "40",
  //   btn_1: "Cancel",
  //   btn_1_cancel: true,
  //   // btn_1_Link: '',
  //   btn_2: "Proceed",
  //   btn_2_cancel: "",
  //   // btn_2_Link: '/Dashboard/Individual-Dashboard/Bundle-Creation',
  //   btn_2_fn: RunBundle,
  // };
  const [modelData, setModelData] = useState({
    title: "Run Bundle",
    // emoji: "🎉",
    txt: [
      "Running the bundle costs credits.",
      [{ text: 'You need ' }, { text: `${bundleData.data ? bundleData.data.estimated_credits : ""}`, isBold: true }, { text: ' Credits to run this bundle.' }],
      // "You need 20 Credits to run this bundle.",
    ],
    cl: credi.available_credit,
    btn_1: "Cancel",
    btn_1_cancel: true,
    // btn_1_Link: "/Dashboard/Individual-Dashboard/Recharge",
    btn_2: "Proceed",
    btn_2_cancel: true,
    // btn_2_Link: "/Dashboard/Individual-Dashboard/User-Wizard",
    btn_2_fn: RunBundle,
    Credits: true,
    CreditsLnk: "/Dashboard/Individual-Dashboard/Recharge",
    // btn_2_fn_props: {},
  });
  const runbndl = {
    title: "Run Bundle",
    // emoji: "🎉",
    txt: [
      "Running the bundle costs credits.",
      [{ text: 'You need ' }, { text: `${bundleData.data ? bundleData.data.estimated_credits : ""} Credits`, isBold: true }, { text: ' to run this bundle.' }],
      // `You need ${
      //   bundleData.data ? bundleData.data.estimated_credits : ""
      // } Credits to run this bundle.`,
    ],
    cl: credi.available_credit,
    btn_1: "Cancel",
    btn_1_cancel: true,
    // btn_1_Link: "/Dashboard/Individual-Dashboard/Recharge",
    btn_2: "Proceed",
    btn_2_cancel: true,
    // btn_2_Link: "/Dashboard/Individual-Dashboard/User-Wizard",
    btn_2_fn: RunBundle,
    Credits: true,
    CreditsLnk: "/Dashboard/Individual-Dashboard/Recharge",
    // btn_2_fn_props: {},
  };
  const dnbndl = {
    title: "Download Bundle",
    // emoji: "🎉",
    txt: [
      "You have used the free downloads.",
      [{ text: 'You need ' }, { text: `${2} Credits`, isBold: true }, { text: ' to download this bundle.' }],
      // "You need 2 Credits to download this bundle.",
    ],
    cl: credi.available_credit,
    btn_1: "Cancel",
    btn_1_cancel: true,
    // btn_1_Link: "/Dashboard/Individual-Dashboard/Recharge",
    btn_2: "Proceed",
    btn_2_cancel: true,
    Credits: true,
    CreditsLnk: "/Dashboard/Individual-Dashboard/Recharge",
    // btn_2_Link: "/Dashboard/Individual-Dashboard/User-Wizard",
    btn_2_fn: DownloadBundle,
  };
  const BndlePops = ({ x, id }) => {
    console.log("BndlePops", x, id);
    if (x === 0) {
      setModelData({ ...runbndl, btn_2_fn_props: id });
      setOpen(true);
    } else if (x === 1) {
      setModelData({ ...dnbndl, btn_2_fn_props: id });
      setOpen(true);
    } else if (x === 2) {
      DownloadBundle(id);
    }
  };

  const EditBundle = async () => {
    try {
      const bundle_id = localStorage.getItem("bundle_id");
      // const filteredData = respData.filter((data) => data.ind_bundle_id === bundle_id);
      const selectedData2 = bundleData.data;
      console.log("edt", selectedData2);
      // const dr = { startDate: selectedData2.ind_bundle_start_date, endDate: selectedData2.ind_bundle_start_date }
      const dr = {
        startDate: RawDate(selectedData2.ind_bundle_start_date),
        endDate: RawDate(selectedData2.ind_bundle_end_date),
      };
      // console.log('respData', dr, respData, filteredData, filteredData[0])
      const set = {
        SelectData: {
          category: selectedData2.ind_bundle_category,
          category_id: selectedData2.ind_bundle_category_id,
          country: selectedData2.ind_bundle_country,
          country_id: selectedData2.ind_bundle_country_id,
          ecommerceSites: selectedData2.ind_bundle_e_commerce,
          ecommerceSites_id: selectedData2.ind_bundle_e_commerce_id,
          brand: selectedData2.ind_bundle_brand,
          brand_id: selectedData2.ind_bundle_brand_id,
        },
        bundleName: selectedData2.ind_bundle_name,
        ind_bundle_id: selectedData2.ind_bundle_id,
        daterange: dr,
        state: 0,
      };
      console.log("set", set);
      await dispatch(updateBundle(set));
      console.log("bundle data", bundle);
      navi("/Dashboard/Individual-Dashboard/User-Wizard");
      // localStorage.setItem('DataSelectionEdit', JSON.stringify(set))
      // localStorage.removeItem('bundle_id')
      // window.location.href = '/Dashboard/Individual-Dashboard/User-Wizard'
    } catch (error) {
      console.log("Edit bundle error", error);
      // setMessage(
      //   error.response
      //     ? error.response.data.message
      //     : "Unable to Set the Edit Data."
      // );
      // setSeverity("error");
      // handleClick();
      localStorage.removeItem("bundle_id");
    }
  };
  const DeleteBundle = async () => {
    try {
      const bundle_id = localStorage.getItem("bundle_id");
      const response = await http.delete(
        `/master_service/bundle/delete/${localStorage.getItem(
          "user_id"
        )}/${bundle_id}`
      );
      console.log("respon", response);
      setOpenS(true);
      setMessage(response.message || "Bundle deleted successfully!");
      setSeverity("success");
      handleClick();
      localStorage.removeItem("bundle_id");
      setTimeout(() => {
        navi("/Dashboard/Individual-Dashboard");
        setOpenS(false); // Optionally hide the popup after navigation
      }, 500);
      // navi("/Dashboard/Individual-Dashboard");
    } catch (error) {
      console.log("Delete bundle error", error);
      setMessage(
        error.response ? error.response.message : "Unable to Delete Data."
      );
      setSeverity("error");
      handleClick();
      localStorage.removeItem("bundle_id");
    }
  };
  const CopyBundle = async () => {
    try {
      const bundle_id = localStorage.getItem("bundle_id");
      const response = await http.post(
        `/master_service/duplicate_bundle`,
        JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          ind_bundle_id: bundle_id,
        })
      );
      console.log("respon", response);
      setMessage(response.data.message);
      setSeverity("success");
      handleClick();
      localStorage.removeItem("bundle_id");
      navi("/Dashboard/Individual-Dashboard");
    } catch (error) {
      console.log("Delete bundle error", error);
      setMessage(
        error.response ? error.response.data.message : "Unable to copy Data."
      );
      setSeverity("error");
      handleClick();
      localStorage.removeItem("bundle_id");
    }
  };
  const ChangeModelData = (itm, rb) => {
    console.log("clk");
    if (itm === 0) {
      if (!rb) {
        // setModelData({
        //     title: 'Edit Brand',
        //     // emoji: '🎉',
        //     txt: rb ? ['You cannot directly change the datas of the created bundle.', 'Duplicate the bundle to change the datas.'] : ['Are you sure you want to edit the bundle??'],
        //     cl: '',
        //     btn_1: 'Cancel',
        //     btn_1_cancel: true,
        //     // btn_1_Link: '/Dashboard/Individual-Dashboard/Recharge',
        //     btn_2: 'Select Data',
        //     btn_2_fn: EditBundle,
        //     // btn_2_Link: '/Dashboard/Individual-Dashboard/User-Wizard'
        // })
        // setOpen(true)
        EditBundle();
      } else {
        CopyBundle();
      }
    } else if (itm === 1) {
      setModelData({
        title: "Delete Bundle",
        // emoji: '🎉',
        txt: ["Are you sure you want to delete the bundle??"],
        cl: "",
        btn_1: "Cancel",
        btn_1_cancel: true,
        // btn_1_Link: '/Dashboard/Individual-Dashboard/Recharge',
        btn_2: "Delete",
        btn_2_fn: DeleteBundle,
        btn_2_cancel: true,
        // btn_2_Link: '/Dashboard/Individual-Dashboard/User-Wizard'
      });
      setOpen(true);
    }
  };
  return (
    <Box sx={{ p: 2 }}>
      <Model_1 open={open} setOpen={setOpen} data={modelData} />
      <Button disableRipple sx={{ textTransform: "capitalize" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link to={"/Dashboard/Individual-Dashboard/Bundle-Log"}>
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.207391 5.50221C0.0745985 5.36938 0 5.18925 0 5.00142C0 4.8136 0.0745985 4.63346 0.207391 4.50063L4.21443 0.49359C4.27977 0.425937 4.35793 0.371974 4.44435 0.334851C4.53077 0.297728 4.62372 0.278188 4.71777 0.27737C4.81182 0.276553 4.9051 0.294475 4.99215 0.330091C5.0792 0.365707 5.15829 0.418303 5.2248 0.48481C5.2913 0.551317 5.3439 0.630404 5.37951 0.717455C5.41513 0.804507 5.43305 0.89778 5.43223 0.991832C5.43142 1.08588 5.41188 1.17883 5.37475 1.26525C5.33763 1.35167 5.28367 1.42983 5.21602 1.49517L1.70976 5.00142L5.21602 8.50767C5.34504 8.64127 5.41644 8.82019 5.41483 9.00591C5.41321 9.19164 5.33872 9.3693 5.20739 9.50063C5.07606 9.63196 4.8984 9.70645 4.71267 9.70807C4.52695 9.70968 4.34802 9.63828 4.21443 9.50926L0.207391 5.50221Z"
                fill="black"
              />
            </svg>
          </Link>
          <Link to={"/Dashboard/Individual-Dashboard"}>
            <Typography
              sx={{ pl: 1, fontWeight: "600", fontSize: "20px", color: "#000" }}
            >
              Bundle Log
            </Typography>
          </Link>
        </Box>
      </Button>
      {data.map((e, i) => (
        <Box key={i}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {bundleNameEdit ? (
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {
                      padding: 0,
                      border: "none",
                      fontWeight: "600",
                      width: "max-content",
                      fontSize: "16px",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                        boxShadow: "none",
                      },
                      "&.Mui-focused": {
                        boxShadow: "none",
                      },
                    },
                    "& .MuiInputBase-input:focus": {
                      boxShadow: "none",
                    },
                  }}
                  value={bundleName}
                  onChange={(e) => setBundleName(e.target.value)}
                  onBlur={() => setBundleNameEdit(false)}
                  autoFocus
                />
              ) : (
                <Typography sx={{ mr: 2, fontWeight: "600", fontSize: "16px" }}>
                  {bundleName}
                </Typography>
              )}
              <Button disableRipple onClick={() => setBundleNameEdit(true)}>
                <EditSvg />
              </Button>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Button
                variant="outlined"
                sx={{
                  color: "#8A18D0",
                  mx: 1,
                  borderColor: "#8A18D0",
                  textTransform: "capitalize",
                  px: 3,
                }}
                onClick={() => Sample_Data()}
              >
                <Link to={e.btn_1_Link && e.btn_1_Link}>{e.btn_1}</Link>
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#8A18D0",
                  mx: 1,
                  borderColor: "#8A18D0",
                  textTransform: "capitalize",
                  px: 3,
                }}
              >
                <Link to={e.btn_2_Link && e.btn_2_Link}>{e.btn_2}</Link>
              </Button>
              {bundleData &&
                bundleData.data &&
                bundleData.data.ind_bundle_run ? (
                <Button
                  sx={{
                    background: "#8A18D0",
                    color: "#fff",
                    mx: 1,
                    textTransform: "capitalize",
                    px: 3,
                    "&:hover": { background: "#B37FFC" },
                  }}
                  // onClick={() => { if (e.ind_bundle_download_limit === "0") { BndlePops({ x: 1, id: e.ind_bundle_id }) } else { console.log('dl', e.ind_bundle_download_limit); BndlePops({ x: 2, id: e.ind_bundle_id }) } }}
                  onClick={() => {
                    if (bundleData.data.ind_bundle_download_limit === "0") {
                      BndlePops({ x: 1, id: bundleData.data.ind_bundle_id });
                    } else {
                      BndlePops({ x: 2, id: bundleData.data.ind_bundle_id });
                    }
                  }}
                // onClick={() => DownloadBundle(bundleData.data.ind_bundle_id)}
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 10L4.375 6.875L5.25 5.96875L6.875 7.59375V2.5H8.125V7.59375L9.75 5.96875L10.625 6.875L7.5 10ZM3.75 12.5C3.40625 12.5 3.11208 12.3777 2.8675 12.1331C2.62292 11.8885 2.50042 11.5942 2.5 11.25V9.375H3.75V11.25H11.25V9.375H12.5V11.25C12.5 11.5938 12.3777 11.8881 12.1331 12.1331C11.8885 12.3781 11.5942 12.5004 11.25 12.5H3.75Z"
                      fill="white"
                    />
                  </svg>
                  <Typography sx={{ ml: 1 }}>{e.btn_3_2pO}</Typography>
                </Button>
              ) : (
                <Button
                  sx={{
                    background: "#8A18D0",
                    color: "#fff",
                    mx: 1,
                    textTransform: "capitalize",
                    px: 3,
                    "&:hover": { background: "#B37FFC" },
                  }}
                  onClick={() =>
                    BndlePops({ x: 0, id: bundleData.data.ind_bundle_id })
                  }
                // onClick={() => RunBundle(bundleData.data.ind_bundle_id)}
                >
                  <PlaySvg2 /> <Typography sx={{ ml: 1 }}>{e.btn_3}</Typography>
                </Button>
              )}
              {/* <Run_Bundle data={{ h: '', p: 3 }} /> */}
            </Box>
          </Box>
          {/* <Box></Box>
                    <Box></Box>
                    <Box></Box> */}
        </Box>
      ))}
      <Box>
        <Box
          sx={{
            width: "100%",
            my: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: "600", mr: 1, color: "#000" }}
            >
              Data From :
            </Typography>
            {/* <Box sx={{ display: "flex" }}> */}
            <DatePlace
              date={
                bundleData && bundleData.data
                  ? bundleData.data.ind_bundle_start_date
                  : new Date()
              }
            />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                ml: 3,
                mr: 1,
                color: "#000",
              }}
            >
              Data Till :
            </Typography>
            <DatePlace
              date={
                bundleData && bundleData.data
                  ? bundleData.data.ind_bundle_end_date
                  : new Date()
              }
            />
            {/* <Typography sx={{ p: 1, border: '0.8px solid #474747CC', borderRadius: '20px', px: 1, color: '#474747CC', mr: 1 }}>Data From : {dateClnse(bundleData.data.ind_bundle_start_date)}</Typography> */}
            {/* <Typography sx={{ p: 1, border: '0.8px solid #474747CC', color: '#474747CC', borderRadius: '20px', ml: 1 }}>Data Till : {dateClnse(bundleData.data.ind_bundle_end_date)}</Typography> */}
            {/* </Box> */}
          </Box>
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            my: 1,
            color: "#474747CC",
          }}
        >
          {bundleData && bundleData.data && bundleData.data.ind_bundle_run
            ? `Note: You have ${bundleData && bundleData.data
              ? bundleData.data.ind_bundle_download_limit
              : ""
            } free downloads left`
            : "Please Run the Bundle"}
        </Typography>
        <Box sx={{ display: "flex", my: 2 }}>
          {remainingDatas.card.map((f, j) => (
            <Card_2
              key={j}
              data={{
                icon: f.icon,
                title: f.title,
                value: f.value,
                wd: `${100 / remainingDatas.card.length}%`,
              }}
            />
          ))}
        </Box>
        <TableComp6
          Data={generateTableData(
            remainingDatas.table.Category,
            remainingDatas.table.Country,
            remainingDatas.table["E-Commerce Site"],
            remainingDatas.table.Brand,
            remainingDatas.table["Configured Date"]
          )}
          Style={ConfigStyleBar}
          bundle_id={
            bundleData && bundleData.data ? bundleData.data.ind_bundle_id : 0
          }
          rb={
            bundleData && bundleData.data
              ? bundleData.data.ind_bundle_run
              : false
          }
          cb={false}
          ChangeModelData={ChangeModelData}
        />
      </Box>
      <Snackbar
        open={openS}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ position: "absolute", mt: "38px" }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
