import React, { useState } from 'react';
import { TextField, Box, FormControl } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

export const CustomDatePicker = ({ dateRange = [null, null], handleDateChange }) => {
    const [dateRangeState, setDateRange] = useState(dateRange);

    const formatDate = (date) => date ? dayjs(date).format('DD/MM/YYYY') : '';

    const handleStartDateChange = (startDate) => {
        const formattedStartDate = startDate ? dayjs(startDate).toISOString() : null;
        handleDateChange([formattedStartDate, dateRange[1]]);
        console.log('st', startDate, formattedStartDate, dateRange)
    };

    const handleEndDateChange = (endDate) => {
        const formattedEndDate = endDate ? dayjs(endDate).toISOString() : null;
        handleDateChange([dateRange[0], formattedEndDate]);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <DatePicker
                    label="Start"
                    value={dateRange[0] ? dayjs(dateRange[0]).format('DD/MM/YYYY') : null}
                    onChange={handleStartDateChange}
                    maxDate={dayjs()}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            value={dateRange[0] ? dayjs(dateRange[0]).format('DD/MM/YYYY') : ''}
                            sx={{ width: '230px', height: '40px' }}
                            InputProps={{ sx: { height: '40px' } }}
                        />
                    )}
                />
                <Box sx={{ mx: 2 }}> to </Box>
                <DatePicker
                    label="End"
                    value={dateRange[1] ? dateRange[1] : null}
                    // value={dateRange[1] ? dayjs(dateRange[1]).format('DD/MM/YYYY') : null}
                    onChange={handleEndDateChange}
                    maxDate={dayjs()}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            value={dateRange[1] ? dateRange[1] : ''}
                            sx={{ width: '230px', height: '40px' }}
                            InputProps={{ sx: { height: '40px' } }}
                        />
                    )}
                />
            </Box>
        </LocalizationProvider>
    );
};

CustomDatePicker.propTypes = {
    dateRange: PropTypes.arrayOf(PropTypes.string),
    handleDateChange: PropTypes.func.isRequired,
};

export const CustomDatePicker2 = ({ dateRange, handleDateChange }) => {
    const formatDate = (date) => date ? dayjs(date).format('DD/MM/YYYY') : '';
    const maxDate = dayjs();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
                startText="Start"
                endText="End"
                value={dateRange}
                maxDate={maxDate}
                onChange={(newValue) => {
                    handleDateChange(newValue.map(date => date ? dayjs(date).toISOString() : null));
                }}
                renderInput={(startProps, endProps) => (
                    <React.Fragment>
                        <TextField
                            {...startProps}
                            value={formatDate(startProps.inputProps.value)}
                            sx={{ width: '230px', height: '40px' }}
                            InputProps={{ sx: { height: '40px' } }}
                        />
                        <Box sx={{ mx: 2 }}> to </Box>
                        <TextField
                            {...endProps}
                            value={formatDate(endProps.inputProps.value)}
                            sx={{ width: '230px', height: '40px' }}
                            InputProps={{ sx: { height: '40px' } }}
                        />
                    </React.Fragment>
                )}
            />
        </LocalizationProvider>
    );
};

export const DateField = React.forwardRef(
    ({ label, error, required, helperText, variant, ...props }, ref) => (
        <FormControl fullWidth>
            {/* <DatePicker
                ref={ref}
                selected={props.value}
                onChange={(date) => props.onChange(date)} // Pass the onChange function as a prop
                dateFormat="MM/dd/yyyy" // Customize the date format as needed
                {...props}
            /> */}
            <TextField
                ref={ref}
                type='date'
                date
                label={label} // Include the label prop here
                InputLabelProps={{ shrink: true }} // Ensure the label shrinks when there is a value
                selected={props.value}
                error={error} // Pass the error prop to the TextField component
                FormHelperTextProps={{ sx: { color: error ? "#FF4C51" : "inherit" } }} // Apply color based on error
                helperText={helperText}
                variant={variant}
                {...props}
            />
        </FormControl>
    )
);
DateField.propTypes = {
    label: PropTypes.string.isRequired,
    error: PropTypes.bool,
    required: PropTypes.bool,
    helperText: PropTypes.string,
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
};