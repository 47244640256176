import { Data2 } from "../../../../utils/data/LandingPage/Data";
import { Box, Typography } from "@mui/material"


const Navigate_The_World_Of_Ecommerce = () => {
    return (
        // <>
        //     {
        //         Array(4)
        //             .fill(0)
        //             .map((_, i) => (
        <Box sx={{
            boxSizing: 'border-box', overflow: 'hidden'
            // '@keyframes marquee': {
            //     '0%': { transform: 'translateX(100%)' },
            //     '100%': { transform: 'translateX(-100%)' },
            // },
        }}>
            <Box sx={{
                display: 'flex', alignItems: 'center', width: '80%', justifyContent: 'space-between', animation: 'marquee 10s linear infinite',
                '@keyframes marquee': {
                    '0%': { transform: 'translateX(0)' },
                    '100%': { transform: 'translateX(-50%)' },
                },
                // '&:hover': { animationPlayState: 'paused' }
            }}>
                {Data2.map((e, i) => <Typography key={i} sx={{ mx: '85px', fontWeight: '700', fontSize: '28px', textWrap: 'nowrap', '&:hover': { transform: 'scale(1.1)' } }}>{e}</Typography>)}
            </Box>
        </Box>
        //             ))
        //     }
        // </>
        // <Marquee pauseOnHover className={`[--duration:20s]`}>
        //     {Datas.map((e, i) => <Box key={i}>{e.img}</Box>)}
        // </Marquee>
    );
}

export default Navigate_The_World_Of_Ecommerce