import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  TextField,
  DatePicker,
  Typography,
  Select,
  FormLabel,
  Autocomplete,
  TextareaAutosize,
  FormHelperText,
  Chip,
} from "@mui/material";
import { nanoid } from "nanoid";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimeField, TimePicker } from "@mui/x-date-pickers-pro";

const TextBoxField = React.forwardRef(
  (
    {
      label,
      error,
      placeholder,
      required,
      shrink,
      helperText,
      variant,
      ...props
    },
    ref
  ) => {
    const uniqueId = nanoid();

    return (
      <FormControl fullWidth>
        <TextField
          ref={ref}
          id={uniqueId}
          type='text'
          InputLabelProps={{ shrink: shrink }}
          error={error} // Pass the error prop to the TextField component
          FormHelperTextProps={{ sx: { color: error ? "#FF4C51" : "inherit" } }} // Apply color based on error
          label={label}
          variant={variant}
          {...props}
          helperText={helperText}
        />
      </FormControl>
    );
  }
);
TextBoxField.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,

  helperText: PropTypes.string,
};

const SelectField = React.forwardRef(
  (
    { label, error, required, helperText, options, variant, margin, ...props },
    ref
  ) => (
    <FormControl fullWidth>
      <TextField
        ref={ref}
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        type='select'
        select
        {...props}
        label={label}
        variant={variant}
        error={error} // Pass the error prop to the TextField component
        FormHelperTextProps={{ sx: { color: error ? "#FF4C51" : "inherit" } }} // Apply color based on error
        helperText={helperText}>
        {options.map((option) => (
          <MenuItem key={option} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  )
);
SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,

  helperText: PropTypes.string,
};

const CheckboxField = React.forwardRef(
  ({ label, error, required, helperText, variant, ...props }, ref) => (
    <FormControl fullWidth>
      <FormLabel>{label}</FormLabel>
      <Checkbox
        id='demo-simple-check'
        labelId='demo-simple-check-label'
        ref={ref}
        type={"checkbox"}
        Checkbox
        row
        labelPlacement='start'
        variant={variant}
        helperText={helperText}
        error={error} // Pass the error prop to the TextField component
        FormHelperTextProps={{ sx: { color: error ? "#FF4C51" : "inherit" } }} // Apply color based on error
        {...props}
      />
    </FormControl>
  )
);
CheckboxField.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
};

const RadioGroupField = React.forwardRef(
  (
    {
      id,
      label,
      error,
      required,
      helperText,
      options,
      variant,
      value,
      ...props
    },
    ref
  ) => {
    return (
      <FormControl fullWidth>
        <FormLabel>{label}</FormLabel>
        <RadioGroup
          id={id}
          ref={ref}
          row
          value={value}
          type={"radio"}
          helperText={helperText}
          variant={variant}
          error={error} // Pass the error prop to the TextField component
          FormHelperTextProps={{ sx: { color: error ? "#FF4C51" : "inherit" } }} // Apply color based on error
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              required={required}
              name='gender'
              control={<Radio />}
              checked={value === option.value}
              {...props}
              label={
                <span style={{ color: error ? "#FF4C51" : "inherit" }}>
                  {option.label}
                </span>
              } // Apply color based on error
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
);
RadioGroupField.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const DateField = React.forwardRef(
  ({ label, error, required, helperText, variant, ...props }, ref) => (
    <FormControl fullWidth>
      {/* <DatePicker
        ref={ref}
        selected={props.value}
        onChange={(date) => props.onChange(date)} // Pass the onChange function as a prop
        dateFormat="MM/dd/yyyy" // Customize the date format as needed
        {...props}
      /> */}
      <TextField
        ref={ref}
        type='date'
        date
        label={label} // Include the label prop here
        InputLabelProps={{ shrink: true }} // Ensure the label shrinks when there is a value
        selected={props.value}
        error={error} // Pass the error prop to the TextField component
        FormHelperTextProps={{ sx: { color: error ? "#FF4C51" : "inherit" } }} // Apply color based on error
        helperText={helperText}
        variant={variant}
        {...props}
      />
    </FormControl>
  )
);
DateField.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
};
const AutoCompleteField = React.forwardRef(
  (
    {
      label,
      error,
      placeholder,
      required,
      helperText,
      options,
      variant,
      ...props
    },
    ref
  ) => {
    const uniqueId = nanoid();

    return (
      <FormControl fullWidth sx={{ height: "40px" }}>
        <Autocomplete
          sx={{ padding: "unset", height: "40px" }}
          ref={ref}
          id={uniqueId}
          freeSolo
          options={options}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              type='text'
              error={error}
              FormHelperTextProps={{
                sx: { color: error ? "#FF4C51" : "inherit" },
              }}
              label={label}
              required={required}
              variant={variant}
              helperText={helperText}
            />
          )}
          {...props}
        />
      </FormControl>
    );
  }
);

AutoCompleteField.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired, // Adjust the PropTypes for options based on your data structure
};
const TimePickerField = React.forwardRef(
  ({ label, error, required, helperText, value, onChange, ...props }, ref) => {
    const uniqueId = nanoid();

    return (
      <FormControl fullWidth>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["TimePicker"]}>
            <TimePicker
              label={label}
              value={value}
              onChange={onChange}
              format='hh:mm a'
              sx={{ width: "383px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  type='text'
                  error={error}
                  FormHelperTextProps={{
                    sx: { color: error ? "#FF4C51" : "inherit" },
                  }}
                  label={label}
                  required={required}
                  helperText={helperText}
                />
              )}
              {...props}
            />
          </DemoContainer>
        </LocalizationProvider>
      </FormControl>
    );
  }
);

TimePickerField.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
};
const MultilineComponent = ({
  label,
  error,
  required,
  helperText,
  value,
  onChange,
  placeholder,
  ...props
}) => {
  return (
    <FormControl fullWidth error={error} required={required}>
      {label && <InputLabel>{label}</InputLabel>}
      <TextareaAutosize
        value={value}
        aria-label='empty textarea'
        onChange={onChange}
        placeholder={placeholder}
        minRows={3} // You can adjust the number of rows as needed
        {...props}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    },
  },
};

const MultilineSelect = ({
  label,
  value,
  onChange,
  options,
  placeholder,
  ...props
}) => {
  return (
    <FormControl fullWidth>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        multiple
        value={value}
        onChange={onChange}
        input={<InputLabel />}
        renderValue={(selected) => (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {selected.map((val) => (
              <Chip key={val} label={val} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
        {...props}>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
MultilineSelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
};

export {
  TextBoxField,
  SelectField,
  CheckboxField,
  RadioGroupField,
  DateField,
  AutoCompleteField,
  TimePickerField,
  MultilineComponent,
  MultilineSelect,
};
