import PropTypes from "prop-types";
import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { StyledGrid, StyledTypography } from "./Style";
import { GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DataGridPagination from "./DataGridPagination";

function DataGridTitle({
  title,
  handleAdd,
  createButtonTitle,
  access,
  selectedData,
  selectionButton,
  handleSelectionClick,
  printTitle,
}) {
  return (
    <Box padding={""}>
      {/* <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
        {typeof title === "string" ? (
          <StyledGrid item xs={true}>
            <StyledTypography>{title}</StyledTypography>
          </StyledGrid>
        ) : (
          title
        )} */}

        <div>
          <div style={{display:"flex",justifyContent:"space-between"}}>
          <div style={{padding:"10px"}}>
          <GridToolbarExport
            sx={{ marginRight: "10px" }}
            csvOptions={{
              fileName: printTitle,
            }}></GridToolbarExport>
          <GridToolbarQuickFilter
            sx={{ marginRight: "10px" }}></GridToolbarQuickFilter>
            </div>
            
            <DataGridPagination style={{padding:"none"}}/>
            </div>
          {access?.create && handleAdd && (
            <Button
              onClick={() => handleAdd({ type: "Add" })}
              icon={AddCircleOutlineIcon}
              title={createButtonTitle ?? "Create"}
              size='small'
              variant='contained'
              color='#EAF6F1'
              fullWidth
              sx={{ borderRadius: "10px" }}
            />
          )}
          {selectedData?.length > 0 &&
            selectionButton &&
            selectionButton?.map((button) => (
              <Button
                key={button.name} // Make sure to add a unique key when mapping over elements
                onClick={() => handleSelectionClick(selectedData, button)}
                title={button.name}
                size='small'
                variant='contained'
                color='primary'
                fullWidth
                sx={{ borderRadius: "10px" }}
              />
            ))}
        </div>
      {/* </Grid> */}
    </Box>
  );
}
DataGridTitle.propTypes = {
  title: PropTypes.string,
  handleAdd: PropTypes.func,
  createButtonTitle: PropTypes.string,
};

export default DataGridTitle;
