import { AppBar, Box, Divider, IconButton, Link, Toolbar, Typography } from "@mui/material";
// import NotificationsIcon from '@mui/icons-material/Notifications';
import HomeIcon from '@mui/icons-material/Home';

export const Top_Nav = ({ page }) => {
    return (
        <AppBar position="fixed" sx={{ background: '#fff', boxShadow: 'none', height: '64px' }}>
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* <IconButton aria-label="Example" color='invert' sx={{ ml: 1 }}>
                    <LanguageIcon />
                </IconButton> */}
                {/* <Button onClick={() => console.log(JSON.stringify(Amazon, null, 2))}>Press</Button> */}
                <Typography>Dashboard</Typography>
                <Box>
                    <IconButton aria-label="Example" color='invert' sx={{ ml: 1 }}>
                        <HomeIcon />
                    </IconButton>
                </Box>
            </Toolbar>
            <Divider />
        </AppBar>
    );
};
