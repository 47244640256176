import { Box, Button, Grid, Typography } from "@mui/material";
import TopNav from "./private-components/nav/TopNav";
import { Element } from "react-scroll";
import { Link as ScrollLink } from "react-scroll";
import BG from "../../assets/CTA/CTA Background.png";
import CTA from "../../assets/CTA/CTA img.png";
import Content from "./private-components/Content";
import Content2 from "./private-components/Content2";
import { Logo } from "../../assets/logo";
import FirstPage from "./private-components/screens/FirstPage";
import SecondPage from "./private-components/screens/SecondPage";
import ThirdPage from "./private-components/screens/ThirdPage";
import FourthPage from "./private-components/screens/FourthPage";
import OurPlans from "./private-components/screens/OurPlans";
import ComparePlans from "./private-components/screens/ComparePlans";
import FrequentlyAskedQuestions from "./private-components/screens/FrequentlyAskedQuestions";
import { icons, navItems } from "../../utils/data/LandingPage/Data";
// import Footer from "./private-components/screens/Footer";

import { Link, Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { AtLast } from "./private-components/screens/AtLast";
import Footer from "./private-components/screens/Footer";
import { useEffect } from "react";
import { LastPage } from "../about/private/screen/LastPage";

export const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const tk = localStorage.getItem('token')
    if (tk) navigate('/Dashboard/Individual-Dashboard')
  }, [])
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#000000",
        color: "#fff",
        minHeight: "100vh",
        fontFamily: "Inter Variable",
      }}
    >
      <Box
        sx={{
          width: "100%",
          pb: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "85%" },
            position: "sticky",
            top: "0",
            zIndex: "999",
          }}
        >
          <Box
            sx={{ display: { xs: "flex" }, justifyContent: { xs: "center" } }}
          >
            <Box sx={{ width: { xs: "80%", md: "100%" } }}>
              <TopNav />
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: { xs: "100%", md: "85%" } }}>
          <Element name="main">
            <FirstPage />
          </Element>
          <Box
            sx={{
              // width: '100vw',  // Full viewport width
              position: 'absolute',
              left: 0,
              right: 0,
              borderBottom: '1px solid #D8D8D866',
            }}
          />
          <Element name="why-brand-aura">
            <SecondPage />
          </Element>
          <Element name="features">
            <ThirdPage />
          </Element>
          <Element>
            <FourthPage />
          </Element>
        </Box>
        <Box sx={{ width: "100%", background: "#1a1a1a" }}>
          {/* <Element name="pricing">
            <OurPlans />
          </Element>
          <Element name="features">
            <ComparePlans />
          </Element> */}
          {/* <Element name="faq">
            <FrequentlyAskedQuestions />
          </Element> */}
        </Box>
        <Box sx={{ width: "100%", background: "#fff", py: 6 }}>
          <Element name="atLast">
            {/* <section className="self-stretch flex flex-row items-start justify-start py-0 px-20 box-border max-w-full mq750:pl-10 mq750:pr-10 mq750:box-border">
                            <div className="flex-1 flex flex-col items-start justify-start rounded-[10px] gap-[96px] bg-[#000] max-w-full mq750:gap-[48px] mq450:gap-[24px]">
                                <Content />
                            </div>
                        </section> */}
            {/* <AtLast /> */}
            <LastPage />
          </Element>
        </Box>
        <Box sx={{ width: { xs: "100%", md: "80%" } }}>
          <Element name="about-us">
            <Footer />
          </Element>
        </Box>
      </Box>
    </Box>
  );
};

export const Footer2 = () => {
  const nav = [
    "About us",
    "Why Brand Aura?",
    "Features",
    "Pricing",
    "FAQ",
    "Contact us",
  ];
  // const navItems = [
  //   { label: "About us", to: "about-us", route: "/about" },
  //   { label: "Why Brand Aura?", to: "why-brand-aura", route: "/" },
  //   // { label: "Features", to: "features", route: "/" },
  //   // { label: "Pricing", to: "pricing", route: "/" },
  //   { label: "FAQ", to: "faq", route: "/faq" },
  //   { label: "Contact us", to: "contact-us", route: "/contact" },
  // ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        px: { xs: 2, md: 5 },
        py: 5,
        backgroundColor: "#000",
        color: "#fff",
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{ borderBottom: "1px solid #E2E8F0", pb: 3 }}
      >
        <Grid
          item
          xs={12}
          md={3}
          sx={{ textAlign: { xs: "center", md: "left" } }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
            }}
          >
            <ScrollLink
              to={"main"}
              spy={true}
              smooth={true}
              offset={-70}
              duration={900}
              style={{
                cursor: "pointer",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Logo />
            </ScrollLink>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
              mt: 2,
            }}
          >
            {icons.map((e, i) => (
              <Box
                key={i}
                sx={{
                  background: "#F2F2F2",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  textTransform: "capitalize",
                  width: "30px",
                  borderRadius: "50%",
                  mx: 0.5,
                }}
              >
                {e}
              </Box>
            ))}
          </Box>
          {/* <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' }, mt: 2 }}>
                        {icons.map((e, i) => (
                            <Button key={i} variant="square" disableTouchRipple sx={{ background: '#F2F2F2', height: '30px', textTransform: 'capitalize', width: '30px', borderRadius: '50%', mx: 0.5 }}>
                                {e}
                            </Button>
                        ))}
                    </Box> */}
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            {/* {'/' === window.location.pathname ? (

                            <ScrollLink
                                to="main"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={900}
                                style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'none' }}
                            >
                                <Logo />
                            </ScrollLink>
                        ) : (
                                <RouterLink to={'/'} style={{ textDecoration: 'none' }}>
                                    <Logo />
                                </RouterLink>
                            )} */}
            <Grid
              item
              xs={12}
              md={9}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: { xs: "center", md: "flex-start" },
              }}
            >
              {navItems.map((item, i) =>
                item.route === window.location.pathname ? (
                  <ScrollLink
                    key={i}
                    to={item.to}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={900}
                    style={{
                      cursor: "pointer",
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    <Button
                      disableTouchRipple
                      sx={{
                        fontWeight: "500",
                        fontSize: "16px",
                        textTransform: "capitalize",
                        color: "#fff",
                        mx: 1,
                      }}
                    >
                      {item.label}
                    </Button>
                  </ScrollLink>
                ) : (
                  <RouterLink
                    key={i}
                    to={item.route}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      sx={{
                        // color: "inherit",
                        flexWrap: "nowrap",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "16px",
                        textTransform: "capitalize",
                        color: "#fff",
                        mx: 1,
                      }}
                    >
                      {item.label}
                    </Button>
                  </RouterLink>
                )
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{ textAlign: { xs: "center", md: "right" } }}
            >
              <Link to={'/contact'}>
                <Button
                  sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "#fff",
                    backgroundColor: "#8A18D0",
                    px: 2,
                    textTransform: "capitalize",
                    py: 1,
                    "&:hover": { background: "#8a18d099" },
                  }}
                >
                  Request a Demo
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography
        sx={{ textAlign: "center", fontSize: "14px", fontWeight: "400", mt: 3 }}
      >
        © Copyright 2024, All Rights Reserved by Brand Aura
      </Typography>
    </Box>
  );
};

// Define icons

const GradientText = ({ children }) => (
  <Typography
    sx={{
      fontSize: "16px",
      fontWeight: "400",
      background: "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    }}
  >
    {children}
  </Typography>
);

const AtLast3 = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        pt: 8,
        pb: 8,
      }}
    >
      {/* <Box sx={{ width: '80%', height: '7rem', background: `url(${BG})`, backgroundSize: 'cover', backgroundPosition: 'center' }}> */}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
          width: "80%",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: `url(${BG})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            // zIndex: -1,
          }}
        />
        <Box
          sx={{
            position: "relative",
            // zIndex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // padding: '3rem 0',
            textAlign: "center",
            px: 8,
            py: 2,
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: "32px",
              userSelect: "none",
              py: "10px",
              fontWeight: "600",
              py: 2,
            }}
          >
            Take Your
            <Typography
              component="span"
              sx={{
                background:
                  "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                mx: "10px",
                fontSize: "inherit",
                fontWeight: "700",
              }}
            >
              Analysis
            </Typography>
            <br />
            to the Next Level
          </Typography>
        </Box>
      </Box>
      {/* </Box> */}
    </Box>
  );
};
