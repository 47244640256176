

export const DashboardTableData = [{
    Brand_Name: 'Bosch',
    Brand_Url: 'www.boschtools.com',
    table: [
        {
            name: 'Flipkart',
            min_p: '215',
            max_p: '899',
            sentiment: 'Very Good',
            ratings: '4',
            sellers: '25',
            products: '4'
        },
        {
            name: 'Amazon',
            min_p: '250',
            max_p: '20,219',
            sentiment: 'Not Bad',
            ratings: '4',
            sellers: '25',
            products: '4'
        },
        {
            name: 'Indiamart',
            min_p: '1,000',
            max_p: '99,080',
            sentiment: 'Poor',
            ratings: '4',
            sellers: '25',
            products: '4'
        },
        {
            name: 'Ebay',
            min_p: '1,000',
            max_p: '49,080',
            sentiment: 'Poor',
            ratings: '4',
            sellers: '55',
            products: '4'
        },
    ],
}, {
    Brand_Name: 'Makita',
    Brand_Url: 'www.makita.com',
    table: [
        {
            name: 'Flipkart',
            min_p: '1,258',
            max_p: '15,400',
            sentiment: 'Very Good',
            ratings: '4',
            sellers: '25',
            products: '4'
        },
        {
            name: 'Amazon',
            min_p: '2,200',
            max_p: '61,390',
            sentiment: 'Not Bad',
            ratings: '4',
            sellers: '25',
            products: '4'
        },
        {
            name: 'Indiamart',
            min_p: '3,100',
            max_p: '22,000',
            sentiment: 'Poor',
            ratings: '4',
            sellers: '25',
            products: '4'
        },
        {
            name: 'Ebay',
            min_p: '3,100',
            max_p: '12,000',
            sentiment: 'Poor',
            ratings: '4',
            sellers: '125',
            products: '14'
        },
    ],
}, {
    Brand_Name: 'Dewalt',
    Brand_Url: 'www.dewalt.com',
    table: [
        {
            name: 'Flipkart',
            min_p: '255',
            max_p: '34,000',
            sentiment: 'Very Good',
            ratings: '4',
            sellers: '25',
            products: '4'
        },
        {
            name: 'Amazon',
            min_p: '2,500',
            max_p: '44,000',
            sentiment: 'Not Bad',
            ratings: '4',
            sellers: '25',
            products: '4'
        },
        {
            name: 'Indiamart',
            min_p: '500',
            max_p: '50,000',
            sentiment: 'Poor',
            ratings: '4',
            sellers: '25',
            products: '4'
        },
        {
            name: 'Ebay',
            min_p: '100',
            max_p: '10,000',
            sentiment: 'Poor',
            ratings: '4',
            sellers: '225',
            products: '4'
        },
    ],
},];