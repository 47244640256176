import { Box, Typography } from "@mui/material";
import { Card } from "../components";
import { I3 } from "../../../../utils/icon/IndividualPages/Icon";
import Mission_Vision from "../../../../assets/about/mission-vision.png";
import Our_Story from "../../../../assets/about/our-story.png";

export const SecondPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "#fff",
        color: "#000",
        px: { xs: 0, md: 5 }, // Responsive padding
        py: { xs: 2, md: 5 }, // Responsive vertical padding
      }}
    >
      <Box sx={{ my: { xs: 2, md: 2 } }}>
        {" "}
        {/* Adjusted margin for mobile */}
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: { xs: "32px", md: "58px" }, // Responsive font size
          }}
        >
          Mission & Vision
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" }, // Stack vertically on mobile
            justifyContent: "space-between", // Ensure proper spacing
            gap: { xs: 2, md: 4 }, // Add gap between elements
            p: { xs: 2, md: 10 },
            px: { xs: 1, md: 10 }, // Responsive padding
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "45%" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: { xs: 2, md: 0 }, // Margin bottom for mobile
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "95%" },
                border: "1px solid #EAECF0",
                background: "#FAFAFA",
                borderRadius: "16px",
                py: 2,
                px: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={Mission_Vision}
                alt="Mission and Vision"
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  borderRadius: "12px",
                  mt: 15
                }}
                sx={{
                  height: { xs: "auto", md: "1600px" },
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "45%" },
              display: "flex",
              flexDirection: "column",
              my: { xs: 2, md: 0 }, // Margin for mobile
              py: 2,
              textAlign: { xs: "justify", md: "justify" }, 
              hyphens: "none",
            }}
          >
            <Card
              data={{
                icon: <I3 />,
                title: "Our Mission",
                text: "Our mission is to empower organizations by providing comprehensive digital presence analysis, uniting data teams and business teams around actionable data to drive impactful business outcomes.",
              }}
            />
            <Box sx={{ my: 2 }} /> {/* Spacer */}
            <Card
              data={{
                icon: <I3 />,
                title: "Our Vision",
                text: "Our vision is to help corporates and individuals, offering unparalleled insights into digital touchpoints, market sentiments, and product positioning to foster data-driven decision-making and strategic growth.",
              }}
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ my: { xs: 2, md: 2 }, }}>
        {" "}
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: { xs: "32px", md: "58px" }, // Responsive font size
            
          }}
        >
          Our Story
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" }, // Stack vertically on mobile
            justifyContent: { xs: "center", md: "space-evenly" }, // Align items
            gap: { xs: 2, md: 2 }, // Add gap between elements
            px: { xs: 2, md: 5 }, // Responsive padding
            my: { xs: 2, md: 4 }, // Margin adjustments

          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "45%" },
              display: "flex",
              flexDirection: "column",
              my: { xs: 2, md: 0 }, // Margin for mobile
              py: 2,
              textAlign: { xs: "justify", md: "justify" }, 
              hyphens: "none",

            }}>
            <Card
              data={{
                icon: <I3 />,
                title: "Our Story",
                text: "Brand Aura was founded with a single goal: to help businesses navigate the complexities of the digital marketplace. Recognizing the challenges posed by evolving technology, shifting consumer behaviors, and overwhelming data, we developed a solution to transform raw data into actionable insights, empowering businesses to thrive.",
              }}
            />
            <Box sx={{ my: 2 }} /> {/* Spacer */}
            <Card
              data={{
                text: "Driven by innovation, dedication, and a relentless pursuit of excellence, we are passionate about helping our clients succeed. Our team of experts provides exceptional service, leveraging extensive knowledge and experience to deliver outstanding results.",
              }}
            />
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "45%" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: { xs: 2, md: 0 },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "95%" },
                border: "1px solid #EAECF0",
                background: "#FAFAFA",
                borderRadius: "16px",
                py: 2,
                px: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={Our_Story}
                alt="Our Story"
                style={{
                  width: "100%",
                  height: "auto", // Maintain aspect ratio
                  objectFit: "cover",
                  borderRadius: "12px",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
