import React, { useState, useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button, Typography, Avatar, Box, colors } from '@mui/material';
import { Pie } from '../charts/Pie';
import BarChartWrapper from '../charts/BarChartComponent';
import PowerToolsPieChart from '../charts/PowerToolsPieChart';
import { tableData } from '../../utils/data/Brand';
import Graph_1 from '../../assets/Graph 1.png'
import Graph_2 from '../../assets/Graph 2.png'

export const AutoCarousel = () => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);

    const items = [
        {
            imageUrl: Pie,
            alt: 'Platform distribution',
            data: [
                { id: 0, value: 9.3, label: 'Amazon', color: '#febd69' },
                { id: 1, value: 13.4, label: 'Indiamart', color: '#ff7676' },
                { id: 2, value: 30.0, label: 'eBay', color: '#a4b0be' },
                { id: 3, value: 47.3, label: 'Flipkart', color: '#55efc4' },
            ]
        },
        {
            imageUrl: BarChartWrapper,
            alt: 'Number of Products Per Brand',
            data: tableData
        },
        {
            imageUrl: Pie,
            alt: 'Seller Distribution',
            data: [
                { id: 0, value: 9.2, label: 'Amazon', color: '#febd69' },
                { id: 1, value: 8.0, label: 'Indiamart', color: '#ff7676' },
                { id: 2, value: 58.0, label: 'eBay', color: '#a4b0be' },
                { id: 3, value: 24.8, label: 'Flipkart', color: '#55efc4' },
            ]
        },
        {
            imageUrl: BarChartWrapper,
            alt: 'Number of Sellers Per Brand',
            data: tableData
        },
        {
            imageUrl: BarChartWrapper,
            alt: 'Product Rating',
            data: tableData
        },
        {
            imageUrl: Pie,
            alt: 'Price Distribution',
            data: [
                { id: 0, value: 9.3, label: 'Amazon', color: '#febd69' },
                { id: 1, value: 13.4, label: 'Indiamart', color: '#ff7676' },
                { id: 2, value: 30.0, label: 'eBay', color: '#a4b0be' },
                { id: 3, value: 47.3, label: 'Flipkart', color: '#55efc4' },
            ]
        },
        {
            imageUrl: Graph_1,
            typ: 'img',
            alt: 'Price Distribution',
        },
        {
            imageUrl: Graph_2,
            typ: 'img',
            alt: 'Price Distribution',
        },
        // {
        //     imageUrl: PowerToolsPieChart,
        //     alt: 'New one',
        //     data: tableData
        // },
    ];

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         setActiveItemIndex((prevIndex) => (prevIndex + 1) % items.length);
    //     }, 7000);

    //     return () => {
    //         clearInterval(intervalId);
    //     };
    // }, [items.length]);

    return (
        <Carousel
            // animation="slide"
            autoPlay={true}
            indicatorContainerProps={{
                style: {
                    textAlign: 'center',
                    // height: '12px',
                    // width: '12px',
                    colors: '#000000'
                },
            }}
            activeIndicatorIconButtonProps={{
                style: {
                    width: '30px',
                    height: '12px',
                    borderRadius: '20px',
                    color: '#ff0000',
                    backgroundColor: '#FF0000', // Customize the active color as needed
                },
            }}
            next={(nextIndex) => setActiveItemIndex(nextIndex)}
            index={activeItemIndex}
        >
            {items.map((item, index) => (
                <Item key={index} item={item} />
            ))}
        </Carousel>
    );
};

const Item = ({ item }) => {
    return (
        <Paper style={{ height: 600, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* Replace 'item.imageUrl' with 'item.imageUrl' if 'Pie' is a React component */}
            {/* <img src={item.imageUrl} alt={item.alt} style={{ width: '100%', height: 'auto' }} /> */}
            {item.typ ? (
                <Avatar src={item.imageUrl} alt='' style={{ height: 500, width: 'auto', borderRadius: 0 }} />
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '100%', width: '100%' }}>
                    <Typography variant="subtitle1" sx={{ my: '15px', mt: '15px', fontSize: '30px', fontWeight: '400', textAlign: 'center' }}>{item.alt}</Typography>
                    <item.imageUrl data={item.data} />
                </Box>
            )}
        </Paper>
    );
};
