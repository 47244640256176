import {
  Alert,
  Box,
  Button,
  FormControl,
  FormLabel,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Logo } from "../../../assets/logo";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import http from "../../../utils/http-common";
import { TickCircleSolid } from "../../../utils/icon/LandingPage/Icon";
import { Carousel_OG } from "../Components/Carousel";

export const Otp = () => {
  // const expiry = useSelector((state) => state.expiry.expiry);
  // const [timeRemaining, setTimeRemaining] = useState({ minutes: 0, seconds: 0 });
  const [otp, setOtp] = useState("");
  const [verified, setVerified] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [open, setOpen] = useState(false);
  const [mail, setMail] = useState(localStorage.getItem("email") || "");
  const navigate = useNavigate();

  useEffect(() => {
    const e = localStorage.getItem("email");
    const o = localStorage.getItem("otp");
    if (!e) navigate("/");
  }, []);
  const maskedEmail = mail.replace(/^(.{3})(.*)(@.*)$/, (match, p1, p2, p3) => {
    return `${p1}${"*".repeat(p2.length)}${p3}`;
  });

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const Otp = async () => {
    try {
      // const response = await http.post('/otpverification', JSON.stringify({
      const response = await http.post(
        "auth_service/otpverification",
        JSON.stringify({
          otp: otp,
        })
      );
      console.log("res", response, response.user);

      if (
        response.data.message === "User verified successfully" ||
        response.status === 200
      ) {
        setMessage(
          response.data.message
            ? response.data.message
            : "User is Verified with the Otp Successfully"
        );
        setSeverity("success");
        handleClick();
        setVerified(true);
        setTimeout(() => {
          // if (company) {
          //     navigate("/Pricing");
          // }
          navigate("/Login");
        }, 4000); // execute after 4 seconds
        setOtp("");
      }
    } catch (error) {
      console.error("Error verifying OTP", error);
      setMessage(error.response.data.message);
      setSeverity("error");
      handleClick();
    }
  };
  const ResendOtp = async () => {
    try {
      // const response = await http.post('/otpverification', JSON.stringify({
      const response = await http.post(
        "auth_service/Resend-OTP",
        JSON.stringify({
          user_email: localStorage.getItem("email"),
        })
      );
      console.log("res", response);

      if (
        response.data.message ===
          "An Email sent to your account please verify" ||
        response.status === 201
      ) {
        setOtp("");
        setMessage(response.data.message);
        setSeverity("success");
        handleClick();
      }
    } catch (error) {
      console.error("Error verifying OTP", error);
      setMessage(error.response.data.message);
      setSeverity("error");
      handleClick();
    }
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          width: "50%",
          height: "100vh",
          display: { xs: "none", sm: "flex" },
          flexDirection: "column",
          background: "#000000",
          borderTopRightRadius: "12px",
          borderBottomRightRadius: "12px",
          color: "#fff",
          py: 4,
          px: 5,
        }}
      >
        <Link to={"/"}>
          <Logo />
        </Link>
        <Carousel_OG />
      </Box>
      <Box
        sx={{
          width: { xs: "100%", sm: "50%" },
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "#ffffff",
          color: "#474747",
          py: 10,
          px: 5,
        }}
      >
        {verified ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TickCircleSolid />
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "32px",
                display: "flex",
                mt: "10px",
                mb: "17px",
                background:
                  "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Verified
            </Typography>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "20px",
                display: "flex",
                mt: "10px",
                mb: "17px",
                color: "#000000",
              }}
            >
              Your Account has been verified successfully.
            </Typography>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "20px",
                color: "#000000",
                mb: "0px",
              }}
            >
              Redirecting to the login page.
            </Typography>
          </Box>
        ) : (
          <FormControl
            sx={{ width: { xs: "100%", sm: "75%", padding: "50px" } }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "32px",
                display: "flex",
                mt: "15px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "32px",
                  background:
                    "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Verify Your Account{" "}
              </Typography>{" "}
              🧑‍💻
            </Typography>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "16px",
                display: "flex",
                mt: "10px",
                mb: "17px",
                color: "#000000",
              }}
            >
              We have just sent a verification code to your mail {maskedEmail}.
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", mb: "15px" }}>
              <FormLabel
                sx={{ fontWeight: "500", fontSize: "14px", color: "#474747" }}
              >
                Code*
              </FormLabel>
              <TextField
                placeholder="Enter code"
                type="text"
                size="small"
                sx={{ color: "#AFAFAF", height: "20px" }}
                value={otp}
                onChange={handleOtpChange}
              ></TextField>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                my: { xs: "0", sm: "5px" },
              }}
            >
              <Button
                disableFocusRipple
                disableTouchRipple
                sx={{
                  textTransform: "capitalize",
                  color: "#474747",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
                onClick={() => ResendOtp()}
              >
                Resend Code?
              </Button>
            </Box>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Button
                sx={{
                  color: "#fff",
                  background: "#8A18D0",
                  height: "43px",
                  textTransform: "capitalize",
                  my: "5px",
                  "&:hover": { background: "#B37FFC" },
                }}
                onClick={Otp}
              >
                Verify
              </Button>
              <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                sx={{
                  position: "absolute",
                  // top: "60px",
                  marginTop: "50px",
                  width: "100%",
                  left: 0,
                  zIndex: 1300,
                }}
              >
                <Alert
                  onClose={handleClose}
                  severity={severity}
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  {message}
                </Alert>
              </Snackbar>
            </Box>
          </FormControl>
        )}
      </Box>
    </Box>
  );
};
