import { Box, Grid, Typography, Button, Link } from "@mui/material";
import Bg_Img from "../../assets/bg.png"; // Adjust the path as needed

const Customize = () => {
    return (
        // <Box
        //     sx={{
        //         width: "100%",
        //         height: "100vh",
        //         display: "flex",
        //         justifyContent: "center",
        //         alignItems: "center",
        //         position: "relative",
        //         background: `url(${Bg_Img}) no-repeat center center/cover`, 
        //         overflow: "hidden",
        //         // transform: "scaleY(-1)",
        //     }}
        // >
        //     <Box
        //         sx={{
        //             position: "absolute",
        //             top: 0,
        //             left: 0,
        //             width: "100%",
        //             height: "100%",
        //             backgroundColor: "rgba(0, 0, 0, 0.6)",
        //             zIndex: 1, 
        //         }}
        //     />
        //     <Box
        //         sx={{
        //             position: "relative",
        //             zIndex: 2,
        //             display: "flex",
        //             flexDirection: "column",
        //             alignItems: "center",
        //             justifyContent: "center",
        //             width: "100%",
        //             textAlign: "center",
        //             px: { xs: 2, md: 8 },
        //             py: 2,
        //         }}
        //     >
        //         <Typography
        //             variant="h1"
        //             sx={{
        //                 fontSize: { xs: '32px', md: '56px' },
        //                 fontWeight: '700',
        //                 color: "#fff",
        //                 textAlign: 'center',
        //                 userSelect: 'none',
        //             }}
        //         >
        //             Customize your
        //         </Typography>
        //         <Typography
        //             variant="h1"
        //             sx={{
        //                 fontWeight: '700',
        //                 fontSize: { xs: '32px', md: '56px' },
        //                 textAlign: 'center',
        //                 background: "linear-gradient(90deg, #8A18D0, #FF4081)", 
        //                 WebkitBackgroundClip: 'text',
        //                 WebkitTextFillColor: 'transparent',
        //                 userSelect: 'none',
        //                 mb: 0,
        //             }}
        //         >
        //             Data Experience
        //         </Typography>
        //         <Typography
        //             sx={{
        //                 fontSize: { xs: '14px', md: '20px' },
        //                 fontWeight: '400',
        //                 color: "#fff", 
        //                 textAlign: 'center',
        //                 userSelect: 'none',
        //                 mt: 2,
        //                 mb: 4,
        //                 maxWidth: { xs: '90%', md: '60%' }, 
        //                 lineHeight: 1.6,
        //             }}
        //         >
        //             Explore a wide range of categories, brands, and e-commerce platforms to tailor your data extraction to your specific needs.
        //         </Typography>
        //         <Box
        //             sx={{
        //                 display: "flex",
        //                 flexDirection: { xs: "column", md: "row" },
        //                 gap: "20px", 
        //                 justifyContent: "center",
        //             }}
        //         >
        //             <Button
        //                 sx={{
        //                     color: "#fff",
        //                     background: "#8A18D0",
        //                     fontSize: "16px",
        //                     fontWeight: "700",
        //                     height: "52px",
        //                     width: "160px",
        //                     textTransform: "capitalize",
        //                     "&:hover": { background: "#8A18D099" },
        //                 }}
        //             >
        //                 <Link href={"/Reg"} underline="none" color="inherit">
        //                     Get Started
        //                 </Link>
        //             </Button>
        //             <Button
        //                 sx={{
        //                     color: "#fff",
        //                     fontSize: "16px",
        //                     fontWeight: "500",
        //                     height: "52px",
        //                     width: "160px",
        //                     border: "1px solid #fff",
        //                     textTransform: "capitalize",
        //                     "&:hover": {
        //                         borderColor: "#8A18D0", 
        //                     },
        //                 }}
        //             >
        //                 <Link href={"/contact"} underline="none" color="inherit">
        //                     Product Demo
        //                 </Link>
        //             </Button>
        //         </Box>
        //     </Box>
        // </Box>
<Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: `url(${Bg_Img})`,
          backgroundSize: "cover",
          backgroundPositionY: "bottom",
          backgroundPositionX: "center",
          transform: "scaleY(-1)",
        }}
      />
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
          px: { xs: 1, md: 8 },
          py: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: { xs: "100%", md: "90%" },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: 32, md: 64 },
              userSelect: "none",
              py: "10px",
              fontWeight: "500",
            }}
          >
            Customize Your
            <Typography
              component="span"
              sx={{
                background:
                  "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                mx: "10px",
                fontSize: "inherit",
                fontWeight: "700",
              }}
            >
              Data Experience
            </Typography>
          </Typography>
          <Typography
            sx={{
              py: 2,
              fontSize: { xs: "12px", md: "20px" },
              px: { xs: "12px", md: "95px" },
            }}
          >
            Explore our wide range of categories, countries, brands, 
            and e-commerce platforms to tailor your data extraction 
            to your specific needs.
          </Typography>
          <Box
              sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: "20px",
                  justifyContent: "center",
              }}
          >
              <Link href={"/Reg"} underline="none" color="inherit">
                  <Button
                      sx={{
                          color: "#fff",
                          background: "#8A18D0",
                          fontSize: "16px",
                          fontWeight: "700",
                          height: "52px",
                          width: "160px",
                          textTransform: "capitalize",
                          "&:hover": { background: "#8A18D099" },
                      }}
                  >
                      Get Started
                  </Button>
              </Link>

              <Link href={"/contact"} underline="none" color="inherit">
                  <Button
                      sx={{
                          color: "#fff",
                          fontSize: "16px",
                          fontWeight: "500",
                          height: "52px",
                          width: "160px",
                          border: "1px solid #fff",
                          textTransform: "capitalize",
                          "&:hover": {
                              borderColor: "#8A18D0",
                          },
                      }}
                  >
                      Product Demo
                  </Button>
              </Link>
          </Box>
        </Box>
      </Box>
    </Box>
        
    );
};

export default Customize;
