import { Box, Typography } from "@mui/material";
import Bg_Img from "../../../../assets/bg.png";

export const FirstPage = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: `url(${Bg_Img})`,
          backgroundSize: "cover",
          backgroundPositionY: "bottom",
          backgroundPositionX: "center",
          transform: "scaleY(-1)",
        }}
      />
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
          px: { xs: 1, md: 8 },
          py: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: { xs: "100%", md: "90%" },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: 32, md: 64 },
              userSelect: "none",
              py: "10px",
              fontWeight: "500",
            }}
          >
            Helping you
            <Typography
              component="span"
              sx={{
                background:
                  "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                mx: "10px",
                fontSize: "inherit",
                fontWeight: "700",
              }}
            >
              Win
            </Typography>
            {/* <Typography component="h1" sx={{ display: 'block' }} /> */}
            in the
            <Typography
              component="span"
              sx={{
                background:
                  "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                mx: "10px",
                fontSize: "inherit",
                fontWeight: "700",
              }}
            >
              Digital Jungle
            </Typography>
            <Typography component="h1" sx={{ display: "block" }} />
            by making sense of your data
          </Typography>
          <Typography
            sx={{
              py: 2,
              fontSize: { xs: "12px", md: "20px" },
              px: { xs: "12px", md: "95px" },
            }}
          >
            Founded by Technology Maverics with a deep understanding of
            eCommerce, Social Media and Online Communities, Brand Aura offers
            you a foothold into the vast expanse of data - Present,
             {/* <br /> */}
            past and a peek into the future.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
