import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../slice/Test'
import bundle from '../slice/bundle'
import credits from '../slice/credits'

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        credit: credits,
        bundles: bundle
    }
})
