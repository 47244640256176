import {
    Alert,
    Box,
    Button,
    FormControl,
    FormLabel,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import { Logo } from "../../../assets/logo";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import http from "../../../utils/http-common";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
    PasswordSvg,
} from "../../../utils/icon/LandingPage/Icon";
import { Carousel_OG } from "../Components/Carousel";
import { Model_1 } from "../Components/Model";



export const Registeration = () => {
    const [btn, setBtn] = useState(true);
    const btnTrue = {
        textTransform: "capitalize",
        color: "#fff",
        background: "#8A18D0",
        textWrap: "nowrap",
        fontSize: "14px",
        fontWeight: "600",
        display: "flex",
        borderRadius: "15px",
        width: "50%",
        "&:hover": {
            background: "#8A18D0",
        },
    };
    const btnFalse = {
        textTransform: "capitalize",
        color: "#000000",
        textWrap: "nowrap",
        fontSize: "14px",
        fontWeight: "500",
        display: "flex",
        width: "50%",
    };
    const [fn, setFn] = useState("");
    const [ln, setLn] = useState("");
    const [country, setCountry] = useState({ name: "", code: "us" });
    const [ph, setPh] = useState("");
    const [cn, setCn] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const navigate = useNavigate();
    // const dispatch = useDispatch()

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const validateForm = () => {
        let tempErrors = {};
        let isValid = true;

        // Name validation
        if (!fn) {
            tempErrors.fn = "Name is required.";
            isValid = false;
        }

        // Country validation
        if (!country.name) {
            tempErrors.country = "Country is required.";
            isValid = false;
        }

        // Phone number validation
        if (!ph || ph.length < 10) {
            tempErrors.ph = "A valid phone number is required.";
            isValid = false;
        }

        // Company name validation
        if (!cn && !btn) {
            tempErrors.cn = "Company name is required.";
            isValid = false;
        }

        // Email validation
        if (!email) {
            tempErrors.email = "Email is required.";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            tempErrors.email = "Email address is invalid.";
            isValid = false;
        }

        // Password validation
        // const passwordErrors = [];
        // if (password.length < 8) {
        //   passwordErrors.push("Password must be at least 8 characters long.");
        // }
        // if (!/[A-Z]/.test(password)) {
        //   passwordErrors.push(
        //     "Password must include at least one uppercase letter."
        //   );
        // }
        // if (!/[a-z]/.test(password)) {
        //   passwordErrors.push(
        //     "Password must include at least one lowercase letter."
        //   );
        // }
        // if (!/[0-9]/.test(password)) {
        //   passwordErrors.push("Password must include at least one digit.");
        // }
        // if (!/[!@#$%^&*]/.test(password)) {
        //   passwordErrors.push(
        //     "Password must include at least one special character."
        //   );
        // }
        // if (passwordErrors.length > 0) {
        //   tempErrors.password = passwordErrors.join(" ");
        //   isValid = false;
        // }
        // Password validation
        if (
            password.length < 8 ||
            !/[A-Z]/.test(password) ||
            !/[a-z]/.test(password) ||
            !/[0-9]/.test(password) ||
            !/[!@#$%^&*]/.test(password)
        ) {
            tempErrors.password = "Enter valid password";
            isValid = false;
        }

        setErrors(tempErrors);
        return isValid;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            Register();
        }
        //  else {
        //   setMessage("Please correct the highlighted errors.");
        //   setSeverity("error");
        //   setOpen(true);
        // }
    };

    const Register = async () => {
        try {
            const userType = btn ? "individual" : "company";
            // const response = await http.post('/Registeration', JSON.stringify({
            const response = await http.post(
                "/auth_service/Registeration",
                JSON.stringify({
                    user_first_name: fn,
                    user_last_name: ln,
                    user_country: country.name,
                    user_phone_number: ph,
                    user_company_name: cn,
                    user_email: email,
                    user_password: password,
                    user_type: userType,
                })
            );
            console.log("res", response);

            if (
                response.data.message === "An Email sent to your account please verify"
            ) {
                localStorage.setItem("email", email);
                localStorage.setItem("otp", true);
                if (!btn) {
                    localStorage.setItem("company", true);
                }
                setFn("");
                setLn("");
                setCountry("");
                setPh("");
                setCn("");
                setEmail("");
                setPassword("");
                setMessage(response.data.message);
                setSeverity("success");
                handleClick();
                // setExpiry(response.data.expires)
                // dispatch(changeExpiry(response.data.expires));
                navigate("/Otp");
            }
        } catch (error) {
            console.error("There was an error registering the user!", error);
            setMessage(
                error.response
                    ? error.response.data.message
                    : "Registration failed. Please try again."
            );
            setSeverity("error");
            handleClick();
        }
    };

    return (
        <Box sx={{ display: "flex", height: "100vh" }}>
            <Box
                sx={{
                    width: "50%",
                    height: "100%",
                    display: { xs: "none", sm: "flex" },
                    flexDirection: "column",
                    background: "#000000",
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                    color: "#fff",
                    py: 4,
                    px: 5,
                }}
            >
                <Link to={"/"}>
                    <Logo />
                </Link>
                <Carousel_OG />
            </Box>
            <Box
                sx={{
                    width: { xs: "100%", sm: "50%" },
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#ffffff",
                    color: "#474747",
                    py: 20,
                    // padding: "30px",
                }}
            >
                <FormControl
                    sx={{
                        width: { xs: "100%", sm: "75%" },
                        my: { xs: "15px", sm: "0" },
                        px: { xs: 3, md: 5 },
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "700",
                            fontSize: "32px",
                            display: "flex",
                            my: "15px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: "600",
                                fontSize: "32px",
                                background:
                                    "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                            }}
                        >
                            Let's get you Registered{" "}
                        </Typography>
                        ✨
                    </Typography>
                    <Box
                        sx={{
                            height: "34px",
                            width: "100%",
                            p: "4px",
                            borderRadius: "20px",
                            border: "1px solid #E4E4E4",
                            background: "#fff",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button
                            disableRipple
                            sx={btn ? btnTrue : btnFalse}
                            onClick={() => setBtn(true)}
                        >
                            Individual User
                        </Button>
                        <Button
                            disableRipple
                            sx={!btn ? btnTrue : btnFalse}
                            onClick={() => setBtn(false)}
                        >
                            {" "}
                            Company User
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            width: "100%",
                            justifyContent: "space-between",
                            my: { xs: "0", sm: "15px" },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                my: { xs: "15px", sm: "0" },
                            }}
                        >
                            <FormLabel>Name*</FormLabel>
                            <TextField
                                size="small"
                                placeholder="Enter name"
                                type="text"
                                value={fn}
                                required
                                onChange={(e) => setFn(e.target.value)}
                                sx={{ color: "#AFAFAF", height: "35px" }}
                                error={!!errors.fn}
                                helperText={errors.fn}
                            ></TextField>
                        </Box>
                        {/* <Box sx={{ display: 'flex', flexDirection: 'column', width: { xs: '100%', sm: '46%' }, my: { xs: '15px', sm: '0' }, }}>
                              <FormLabel>Last name</FormLabel>
                              <TextField placeholder="Last Name" type="text" value={ln} onChange={(e) => setLn(e.target.value)} sx={{ color: '#AFAFAF', height: '43px' }}></TextField>
                          </Box> */}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            flexDirection: { xs: "column", sm: "row" },
                            justifyContent: "space-between",
                            my: { xs: "0", sm: "15px" },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: { xs: "100%", sm: "46%" },
                                my: { xs: "15px", sm: "0" },
                            }}
                        >
                            <FormLabel>Country*</FormLabel>
                            <TextField
                                size="small"
                                placeholder="Enter country"
                                type="text"
                                value={country.name}
                                required
                                onChange={(e) =>
                                    setCountry({ ...country, name: e.target.value })
                                }
                                sx={{ color: "#AFAFAF", height: "30px" }}
                                error={!!errors.country}
                                helperText={errors.country}
                            ></TextField>
                            {/* <Country
                  country={country}
                  setCountry={setCountry}
                  required={true}
                  sx={{
                    color: "#AFAFAF",
                    height: "40px",
                    border: "0.74px solid #CBD1D8",
                  }}
                  error={!!errors.country}
                  helperText={errors.country}
                /> */}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: { xs: "100%", sm: "46%" },
                                my: { xs: "20px", sm: "0" },
                            }}
                        >
                            <FormLabel>Phone*</FormLabel>
                            {/* <TextField type="phone" value={ph} onChange={(e) => setPh(e.target.value)} sx={{ color: '#AFAFAF', height: '43px' }}></TextField> */}
                            <PhoneInput
                                country={country.code}
                                value={ph}
                                onChange={(phone) => setPh(phone)}
                                containerStyle={{ width: "100%" }}
                                inputStyle={{ width: "100%", height: "40px", color: "#AFAFAF" }}
                                buttonStyle={{ border: "none", background: "transparent" }}
                                isValid={!errors.ph}
                                // isValid={(value, country) => {
                                //   if (value.length < 10) {
                                //     return "Phone number is invalid";
                                //   }
                                // }}
                                inputProps={{
                                    required: true,
                                }}
                            />
                            {errors.ph && (
                                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                    {errors.ph}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            my: "5px",
                        }}
                    >
                        <FormLabel>Company name{!btn && '*'}</FormLabel>
                        <TextField
                            size="small"
                            placeholder="Enter company name"
                            value={cn}
                            onChange={(e) => setCn(e.target.value)}
                            required
                            type="text"
                            sx={{ color: "#AFAFAF", height: "30px" }}
                            error={!!errors.cn}
                            helperText={errors.cn}
                        ></TextField>
                    </Box>
                    <Box
                        sx={{ display: "flex", flexDirection: "column", my: "17px", mt: 3 }}
                    >
                        <FormLabel>Email ID*</FormLabel>
                        <TextField
                            size="small"
                            placeholder="Enter Email ID"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            type="email"
                            sx={{ color: "#AFAFAF", height: "30px" }}
                            error={!!errors.email}
                            helperText={errors.email}
                        ></TextField>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", my: "15px" }}>
                        <Box sx={{ display: "flex", alignItems: "center", mb: "5px" }}>
                            <FormLabel sx={{ flexGrow: 1 }}>Password*</FormLabel>

                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    position: "relative",
                                    "&:hover .model-1": {
                                        display: "block",
                                    },
                                }}
                            >
                                <PasswordSvg />
                                <Box
                                    sx={{
                                        display: "none",
                                        position: "absolute",
                                        right: 0,
                                        top: "100%",
                                        zIndex: 1,
                                    }}
                                    className="model-1"
                                >
                                    <Model_1 />
                                </Box>
                            </Box>
                        </Box>

                        <TextField
                            size="small"
                            placeholder="Enter password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            sx={{ color: "#AFAFAF", height: "30px" }}
                            error={!!errors.password}
                            helperText={errors.password || ""}
                        />
                    </Box>

                    <Box
                        sx={{
                            position: "relative",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Button
                            sx={{
                                color: "#fff",
                                background: "#8A18D0",
                                height: "40px",
                                textTransform: "capitalize",
                                my: "20px",
                                // my: !!errors.password ? { my: "80px" } : { my: "20px" },
                                "&:hover": { background: "#B37FFC" },
                            }}
                            onClick={handleSubmit}
                        >
                            Register
                        </Button>
                        <Snackbar
                            open={open}
                            autoHideDuration={2000}
                            onClose={handleClose}
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            sx={{ position: "absolute", mt: "78px", width: "100%" }}
                        >
                            <Alert
                                onClose={handleClose}
                                severity={severity}
                                variant="filled"
                                sx={{ width: "100%" }}
                            >
                                {message}
                            </Alert>
                        </Snackbar>
                    </Box>
                    <Typography
                        sx={{
                            color: "#0F1419",
                            textAlign: "center",
                            fontWeight: "400",
                            fontSize: "12px",
                        }}
                    >
                        Already have an account?{" "}
                        <Link to={"/Login"} style={{ fontWeight: "600", fontSize: "12px" }}>
                            Login
                        </Link>
                    </Typography>
                </FormControl>
            </Box>
        </Box>
    );
};
