import { Box, Rating, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useContext } from "react";
import CountryContext from "../../context/CountryContext";
import { Link } from "react-router-dom";
import { getParsedRating } from "../type - 2";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

export const TableComp3 = () => {
    const { country, setCountry, brand, setBrand, subBrand, setSubBrand, seller, setSeller, product, setProduct, DashboardTableData } = useContext(CountryContext)
    return (
        <Table aria-label="brand table" sx={{ border: '1px solid #E1E1E1', borderRadius: '25px' }}>
            <TableHead sx={{}}>
                <TableRow>
                    <TableCell>Sl. No</TableCell>
                    <TableCell>Seller Name</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Discount</TableCell>
                    <TableCell>Stock</TableCell>
                    <TableCell>Platform</TableCell>
                    <TableCell>Average Ratings</TableCell>
                    {/* <TableCell>MRP (₹)</TableCell> */}
                    {/* <TableCell></TableCell> */}
                </TableRow>
            </TableHead>
            <TableBody sx={{ overflow: "auto" }}>
                {data.map((row, i) => (
                    <TableRow
                        key={i}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                        <TableCell>{i + 1}.</TableCell>
                        <TableCell><Link to={'/Detailed-Seller'} onClick={() => setSeller(i)}>{row.seller_name}</Link></TableCell>
                        <TableCell>{row.price}</TableCell>
                        <TableCell>{row.discount}</TableCell>
                        <TableCell>{row.stock}</TableCell>
                        <TableCell>{row.platform}</TableCell>
                        <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Rating
                                    name="rating"
                                    value={getParsedRating(row.avgRatings)}
                                    precision={0.1}
                                    readOnly
                                    emptyIcon={<StarBorderIcon fontSize="small" style={{ color: 'gray' }} />}
                                    icon={<StarIcon fontSize="small" style={{ color: '#fdd835' }} />}
                                />
                                &nbsp;{row.ratings}
                            </Box>
                        </TableCell>
                    </TableRow>
                )
                )
                }
            </TableBody>
        </Table>
    );
}

const data = [
    {
        seller_name: 'Clicktech Retail',
        price: '10',
        discount: '10',
        stock: '10',
        platform: 'Indiamart',
        avgRatings: '3.5',
        ratings: '77,324',
    },
    {
        seller_name: 'Clicktech Retail',
        price: '10',
        discount: '10',
        stock: '10',
        platform: 'Flipkart',
        avgRatings: '3.5',
        ratings: '2,174',
    },
    {
        seller_name: 'Toolsmyne',
        price: '10',
        discount: '10',
        stock: '10',
        platform: 'Ebay',
        avgRatings: '3',
        ratings: '13,274',
    },
    {
        seller_name: 'Diplomat',
        price: '10',
        discount: '10',
        stock: '10',
        platform: 'Indiamart',
        avgRatings: '5',
        ratings: '174',
    },
    {
        seller_name: 'N N Power Tools',
        price: '10',
        discount: '10',
        stock: '10',
        platform: 'Flipkart',
        avgRatings: '4',
        ratings: '174',
    },
    {
        seller_name: 'Vivek Electronics',
        price: '10',
        discount: '10',
        stock: '10',
        platform: 'Amazon',
        avgRatings: '3.5',
        ratings: '174',
    },
    {
        seller_name: 'AL Hussaini Machinary',
        price: '10',
        discount: '10',
        stock: '10',
        platform: 'Amazon',
        avgRatings: '2',
        ratings: '10',
    },
]