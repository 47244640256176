import { Box, Button, Typography, Grid } from "@mui/material";
import { Plans } from "../../../../utils/data/LandingPage/Data";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { renderIcon } from "../../../../utils/icon/LandingPage/Icon";

const OurPlans = () => {
  const [clickedPlan, setClickedPlan] = useState(2);
  const handleCardClick = (index) => {
    setClickedPlan(index);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (route) => {
    navigate(route);
    handleMenuClose();
  };

  return (
    <Box
      sx={{
        background: "#fff",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        pt: 8,
        pb: 0,
      }}
    >
      <Box sx={{ width: { xs: "100%", md: "80%" } }}>
        <Box sx={{ textAlign: "center", mb: 5 }}>
          <Typography
            sx={{
              color: "#000",
              fontSize: { xs: "30px", md: "40px" },
              fontWeight: "600",
              pr: "10px",
              display: "inline-block",
            }}
          >
            Our
          </Typography>
          <Typography
            sx={{
              color: "#8A18D0",
              fontSize: { xs: "30px", md: "40px" },
              fontWeight: "600",
              display: "inline-block",
            }}
          >
            Plans
          </Typography>
        </Box>
        <Grid container spacing={2}
          sx={{
            alignItems: 'center',
            justifyContent: { xs: 'center', sm: 'space-between' }, // Ensure center alignment on smaller screens
          }}
        >
          {Plans.map((plan, index) => (
            <Grid item xs={11} sm={6} md={3} key={index}>
              <Box
                onClick={() => handleCardClick(index)}
                sx={{
                  border: "1px solid #E4E4E4",
                  color: clickedPlan === index ? "#FFFFFF" : "#000000",
                  background: clickedPlan === index ? "#000000" : "",
                  p: 2,
                  borderRadius: "8px",
                  minWidth: "200px",
                  width: '100%',
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  overflow: "hidden",
                  height: "auto",
                  boxSizing: "border-box",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  transform: clickedPlan === index ? "scale(1.05)" : "scale(1)",
                  boxShadow: clickedPlan === index ? "0px 10px 20px rgba(0,0,0,0.2)" : "none",
                  cursor: "pointer",
                  perspective: "1000px",
                  transformStyle: "preserve-3d"
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "24px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      mb: 2,
                    }}
                  >
                    {plan.title}
                  </Typography>

                  {plan.text && (
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "15px",
                        color: "#727272",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                        mb: 3,
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                    >
                      {plan.text}
                    </Typography>
                  )}

                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    {plan.price && (
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "32px",
                          pr: "5px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "normal",
                        }}
                      >
                        {plan.price}
                      </Typography>
                    )}
                    {plan.discount && (
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "12px",
                          background:
                            clickedPlan === index ? "#4B4B4B" : "#F2F2F2",
                          padding: "2px 6px",
                          borderRadius: "4px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "normal",
                        }}
                      >
                        {plan.discount}
                      </Typography>
                    )}
                  </Box>
                  {plan.subText && (
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "12px",
                        color: clickedPlan === index ? "#AFAFAF" : "",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                      }}
                    >
                      {plan.subText}
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "14px",
                      color: clickedPlan === index ? "#D8D8D8" : "#000",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "normal",
                    }}
                  >
                    {plan.mainSubText}
                  </Typography>
                  <Box>
                    {plan.subTextData.map((item, idx) => (
                      <Box
                        key={idx}
                        sx={{ display: "flex", alignItems: "center", py: "10px" }}
                      >
                        {renderIcon(clickedPlan === index)}
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "14px",
                            pl: "7px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "normal",
                          }}
                        >
                          {item.title}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Button
                  sx={{
                    border: clickedPlan !== index && "1px solid #8A18D0",
                    textTransform: "capitalize",
                    background: clickedPlan === index ? "#8A18D0" : "transparent",
                    "&:hover": { background: clickedPlan === index && "#8a18d099" },
                    color: clickedPlan === index ? "#fff" : "#8A18D0",
                    width: "100%",
                    mt: 2,
                    height: "40px",
                  }}
                  component={RouterLink}
                  to={index === 3 ? "/contact" : "/Login"}
                >
                  {index === 3 ? "Contact Us" : "Get Started"}
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "calc(100% - 16px)", sm: "100%" },
            maxWidth: "100%",
            height: { xs: "100px", sm: "60px" },
            my: "32px",
            mx: { xs: "8px", sm: "0" },
            backgroundColor: "#F3E8FA",
            borderRadius: "8px",
            px: 2,
            textAlign: "center",
            boxSizing: "border-box",
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontWeight: "200",
              mb: { xs: 1, sm: 0 },
            }}
          >
            Are you an individual user? We have custom plans for you as well.
          </Typography>
          <RouterLink to="/Reg" style={{ textDecoration: "none" }}>
            <Typography
              onClick={handleMenuClose}
              sx={{
                color: "#8A18D0",
                display: "block",
                textAlign: "center",
                px: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              Explore
            </Typography>
          </RouterLink>
        </Box>
      </Box>
    </Box>
  );
};

export default OurPlans;
