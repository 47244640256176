import { Drawer, Box, Select, MenuItem, List, ListItem, ListItemIcon, ListItemText, Avatar, ListItemButton, Toolbar, Divider } from '@mui/material';
import { SpaceDashboard as SpaceDashboardIcon } from '@mui/icons-material';
// import { Items } from '../SideNav/SideNavItms';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Logo } from '../../../assets/logo';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import CountryContext from '../../context/CountryContext';
import { Items } from '../../../utils/data/SideNavItms';

export const Side_Nav = () => {


    const { country, setCountry, brand, setBrand, subBrand, setSubBrand, seller, setSeller, product, setProduct, separateProduct, setSeparateProduct, DashboardTableData } = useContext(CountryContext)

    const handleChange = (event) => {
        const selectedCountry = event.target.value;
        const index = Items.findIndex((item) => item.country === selectedCountry);
        console.log('onclicking select', selectedCountry, index)
        setCountry(index);
        console.log('Country', country)
    };

    return (
        <Drawer
            anchor="left"
            open={true}
            variant="permanent"
            sx={{
                width: '240px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: '240px',
                    boxSizing: 'border-box',
                    py: '15px'
                    // borderRight: '1px solid',
                },
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%' }}>
                <Link to={'/Dashboard'}>
                    <Logo />
                </Link>
                <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', }}>
                    <ListItem sx={{ width: '100%' }}>
                        <Link to={'/Dashboard'} style={{ width: '100%' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: 'initial',
                                    // px: 2.5,
                                    '&:hover': { backgroundColor: '#e6f4ff' },
                                    borderRadius: '3px',
                                }}
                            >
                                <ListItemIcon>
                                    <SpaceDashboardIcon />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItemButton>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Power Tools" />
                    </ListItem>
                    <Toolbar sx={{ width: '100%' }}>
                        <Select
                            value={Items[country].country}
                            onChange={(e) => handleChange(e)}
                            sx={{ height: '35px', width: '100%', px: '0px' }}
                            variant="outlined"
                        >
                            {Items.map((item) => (
                                <MenuItem key={item.country} value={item.country}>{item.country}</MenuItem>
                            ))}
                        </Select>
                    </Toolbar>
                    {Items[country].itms.map((text, index) => (
                        <ListItem key={index} sx={{ width: '100%' }}>
                            <Link to={'/Dashboard/Brands'} style={{ width: '100%' }}>
                                {console.log(text)}
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: 'initial',
                                        // px: 2.5,
                                        '&:hover': { backgroundColor: '#e6f4ff' },
                                        borderRadius: '3px',
                                    }}
                                    onClick={() => { setBrand(index); setSeparateProduct({ display: false, subBrand: 0 }) }}
                                >
                                    {/* {console.log('items',text, index)} */}
                                    <ListItemIcon>
                                        {text.icn ? <text.icn /> : <Avatar src={text.imgLink} alt="" sx={{ height: 25, width: 'auto', borderRadius: '5px' }} />}
                                    </ListItemIcon>
                                    <ListItemText primary={text.name} />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                    ))}
                </List>
            </Box>
            {/* <Box>
                <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', px: '5px' }}>
                    <ListItem>
                        <ListItemButton disableRipple sx={{ ":hover": { backgroundColor: '#fff' } }}>
                            <ListItemIcon><SettingsOutlinedIcon /></ListItemIcon>
                            <ListItemText>Settings</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton disableRipple sx={{ ":hover": { backgroundColor: '#fff' } }}>
                            <ListItemIcon><LogoutOutlinedIcon /></ListItemIcon>
                            <ListItemText>Logout</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box> */}
        </Drawer>
    );
};

