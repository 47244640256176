import { Box, Button, IconButton, Menu, MenuItem, Paper, Rating, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useContext, useState } from "react";
import CountryContext from "../../context/CountryContext";
import { Link } from "react-router-dom";
import { getParsedRating } from "../type - 2";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { snakeCaseToNormal } from "../../../utils/Utility";
import { ThreeDots } from "../../../utils/icon/IndividualPages/Icon";
import http from '../../../utils/http-common'

export const TableComp6 = ({ Data = [], Style = {}, bundle_id = '', rb, cb = true, ChangeModelData }) => {
    const sanitizedData = Array.isArray(Data) ? Data : [];
    const Head = sanitizedData && sanitizedData.length > 0 ? Object.keys(sanitizedData[0]) : [];
    const Row = Head && Head.length > 0
        ? Head.map((x) => ({
            title: snakeCaseToNormal(x),
            field: x,
        }))
        : [];

    // console.log('tbl datas', Data, Head, Row)

    /*
    const Style = {
        table: {},
        head: {},
        headRow: {},
        headCell: {},
        body: {},
        row: {},
        cell: {},
    }
    
    */

    return (
        <Box sx={{ overflow: 'auto', border: "0.8px solid #797F8F80", borderRadius: '8px', width: '100%' }}>
            <TableContainer component={Paper} sx={{ width: '100%', maxWidth: '100%' }}>
                <Table aria-label="brand table" sx={{ borderRadius: '25px', ...(Style.table ? { ...Style.table } : {}) }}>
                    {/* <Table aria-label="brand table" sx={{ border: '1px solid #E1E1E1', borderRadius: '25px', ...(Style.table ? { ...Style.table } : {}) }}> */}
                    <TableHead sx={{ ...(Style.head ? { ...Style.head } : {}) }}>
                        <TableRow sx={{ ...(Style.headRow ? { ...Style.headRow } : {}) }}>
                            {Row.map((row, i) => (
                                <TableCell key={i} sx={{ ...(Style.headCell ? { ...Style.headCell } : {}) }}>{row.title}</TableCell>
                            ))}
                            {Style.isThreeDot && <TableCell sx={{ ...(Style.headCell ? { ...Style.headCell } : {}) }}></TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ overflow: "auto", ...(Style.body ? { ...Style.body } : {}) }}>
                        {sanitizedData.map((row, i) => (
                            <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 }, ...(Style.oddEvenColor ? { background: i % 2 !== 0 ? '#FAFAFA' : '#FFFFFF' } : {}), ...(Style.row ? { ...Style.row } : {}) }}>
                                {Row.map((col, j) => (
                                    <TableCell key={j} sx={{ ...(Style.cell ? { ...Style.cell } : {}) }}>{Array.isArray(row[col.field]) ? (<Box>{row[col.field].map((e, i) => (<Box key={i} sx={{ my: 1 }}>{e}</Box>))}</Box>) : row[col.field]}</TableCell>
                                ))}
                                {Style.isThreeDot && <TableCell sx={{ ...(Style.threeDot ? { ...Style.threeDot } : {}) }}><ThreeDot bundle_id={bundle_id} rb={rb} cb={cb} ChangeModelData={ChangeModelData} /></TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}


const ThreeDot = ({ ChangeModelData, rb, cb, bundle_id }) => {
    const [anchorElUser, setAnchorElUser] = useState(null);
    // const navigate = useNavigate();

    const handleOpenUserMenu = (event) => {
        console.log('cb', cb)
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    // const DeleteBundle = async (bundle_id) => {
    //     try {
    //         const response = await http.delete(`/master_service/bundle/delete/${localStorage.getItem('user_id')}/${bundle_id}`)
    //         await FetchDatas()
    //         window.location.reload
    //         console.log('respon', response)
    //     } catch (error) {
    //         console.log('Delete bundle error', error)
    //     }
    // }


    const lg = async () => {
        console.log('lg');
        localStorage.clear();
        navigate('/');
    };

    const handleMenuItemClick = (setting, index) => {
        console.log('clicked', bundle_id)
        if (setting.fn) {
            localStorage.setItem('bundle_id', bundle_id)
            setting.fn(index, rb);
        } else if (setting.link) {
            window.location.href = setting.link;
        }
        handleCloseUserMenu();
    };

    const settingsForRb = [
        { title: 'Edit Bundle', fn: ChangeModelData, link: '' },
        { title: 'Delete Bundle', fn: ChangeModelData, link: '' },
    ];
    const settingsForNot = [
        { title: 'Copy Bundle', fn: ChangeModelData, cb: true, link: '' },
        { title: 'Delete Bundle', fn: ChangeModelData, link: '' },
    ];

    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
                <IconButton disableRipple onClick={handleOpenUserMenu} sx={{ p: 2 }}>
                    <ThreeDots />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {!rb ?
                    <>
                        {settingsForRb.map((setting, index) => (
                            <MenuItem
                                key={index}
                                onClick={() => handleMenuItemClick(setting, index)}
                            >
                                <Link to={setting.link && setting.link}><Typography textAlign="center">{setting.title}</Typography></Link>
                            </MenuItem>
                        ))}
                    </>
                    :
                    <>
                        {settingsForNot.map((setting, index) => (
                            (cb && setting.cb) || (!cb && !setting.cb)||cb ?
                                <MenuItem
                                    key={index}
                                    onClick={() => handleMenuItemClick(setting, index)}
                                >
                                    <Link to={setting.link && setting.link}><Typography textAlign="center">{setting.title}</Typography></Link>
                                </MenuItem>
                                :
                                null
                        ))}
                    </>}
            </Menu>
        </Box>
    );
};