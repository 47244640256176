import { Avatar, Box, Button, Typography } from "@mui/material";
import Bg_Up from "../../../../assets/bg.png";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";

const DisplayCard_01 = ({ Data, mh }) => {
  return (
    <Box sx={{ border: "1px solid #4C5155", borderRadius: "24px" }}>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: mh,
          width: "100%",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: `url(${Bg_Up})`,
            backgroundSize: "cover",
            backgroundPositionY: "bottom",
            backgroundPositionX: "center",
            transform: "scaleY(-1)",
          }}
        />
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            px: 1,
            py: 2,
          }}
        >
          <Typography sx={{ fontSize: "32px", fontWeight: "600", pt: 1 }}>
            {Data.Title}
          </Typography>
          <Typography sx={{ fontSize: "32px", fontWeight: "600", pb: 1 }}>
            {Data.subTitle}
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "400",
              width: "80%",
              py: 2,
              pb: 5,
            }}
          >
            {Data.Text}
          </Typography>
          {Data.Btn && (
            <Link
              to={Data.btn_lnk && Data.btn_lnk}
              onClick={() => {
                setTimeout(() => {
                  scroller.scrollTo(Data.btn_to, {
                    smooth: true,
                    duration: 1500,
                  });
                }, 50);
                // window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              <Button
                sx={{
                  background: "#fff",
                  borderRadius: "40px",
                  textTransform: "capitalize",
                  textWrap: "nowrap",
                  height: "40px",
                  py: 1,
                  px: { xs: 1, md: 3 },
                  "&:hover": { background: "#fff" },
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", py: 2 }}>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontWeight: "600",
                      fontSize: "16px",
                      pr: "7px",
                    }}
                  >
                    {Data.Btn}
                  </Typography>
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_3_9270)">
                      <rect
                        x="0.5"
                        width="20"
                        height="20"
                        rx="10"
                        fill="#8A18D0"
                      />
                      <path
                        d="M10.91 14.645L9.884 13.63L12.951 10.562H5.5V9.082H12.952L9.885 6.019L10.909 5L15.731 9.822L10.91 14.645Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3_9270">
                        <rect
                          x="0.5"
                          width="20"
                          height="20"
                          rx="10"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </Box>
              </Button>
            </Link>
          )}
        </Box>
      </Box>
      {Data.type === 0 && (
        <Box sx={{ py: 3 }}>
          {renderCarousel(Data.FirstCarousel, "Left")}
          {renderCarousel(Data.SecondCarousel, "Right")}
        </Box>
      )}
      {Data.type === 1 && (
        <Box
          sx={{
            display: "flex",
            borderRadius: "inherit",
            flexDirection: "column",
            justifyContent: "center",
            overflowY: "hidden",
            height: "275px",
            boxShadow:
              "inset radial-gradient(circle farthest-side, #000, #000) 0px 0px 80px -12px",
          }}
        >
          {renderCarousel(Data.FirstCarousel, "Left")}
          {renderCarousel(Data.SecondCarousel, "Right")}
          {renderCarousel(Data.ThirdCarousel, "Left")}
        </Box>
      )}
    </Box>
  );
};

const renderCarousel = (carouselData, direction) => (
  <Box sx={{ boxSizing: "border-box", overflow: "hidden", py: 1 }}>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "80%",
        justifyContent: "space-between",
        animation: `marquee${direction} 10s linear infinite`,
        "@keyframes marqueeLeft": {
          "0%": { left: "100%" },
          "100%": { left: "0", transform: "translateX(-100%)" },
          // '0%': { transform: 'translateX(100%)' },
          // '100%': { transform: 'translateX(-100%)' },
        },
        "@keyframes marqueeRight": {
          // '0%': { right: '100%' }, //try 2
          // '100%': { right: '0', transform: 'translateX(10%)' },
          // '0%': { transform: 'translateX(-100%)' }, //try 1
          // '100%': { transform: 'translateX(100%)' },
          // '0%': { transform: 'translateX(0%)' }, //try 3
          // '100%': { transform: 'translateX(100%)' },
          "100%": { left: 0 },
          "0%": { left: "100%", transform: "translateX(-100%)" }, //try 4
        },
        // '&:hover': { animationPlayState: 'running' }
        "&:hover": { animationPlayState: "paused" },
      }}
    >
      {Array(32)
        .fill(0)
        .map((_, repeatIndex) =>
          carouselData.map((e, i) =>
            e.bg ? (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "8px",
                  width: "max-content",
                  px: "12px",
                  mx: "5px",
                  py: "5px",
                  background: "#3A3A3A",
                  whiteSpace: "nowrap",
                }}
              >
                {!e.isSvg ? (
                  <Avatar
                    sx={{
                      bgcolor: e.bg ? e.bg : "transparent", // Set background color only if `bg` is passed
                      height: "24px",
                      width: "24px",
                    }}
                    variant="rounded"
                  >
                    <img
                      src={e.img}
                      alt="avatar"
                      style={{ height: "100%", width: "100%" }}
                    />
                  </Avatar>
                ) : (
                  <Box component="span">
                    <e.img />
                  </Box>
                )}
                <Typography sx={{ fontSize: "14px", fontWeight: "500", pl: 1 }}>
                  {e.Title}
                </Typography>
              </Box>
            ) : (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  // justifyContent: "center",
                  textAlign: "center",
                  border: "1px solid #414249",
                  borderRadius: "12px",
                  minHeight: "80px",
                  minWidth: "80px",
                  width: 'min-content',
                  px: "12px",
                  mx: "5px",
                  py: "5px",
                  background: "#161819",
                  whiteSpace: "wrap",
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: e.bg ? e.bg : "transparent", // Set background color only if `bg` is passed
                    // height: '24px',
                    // width: '24px',
                  }}
                  variant="rounded"
                >
                  {!e.isSvg ? (
                    <img
                      src={e.img}
                      alt="avatar"
                      style={{ height: "100%", width: "100%" }}
                    />
                  ) : (
                    e.img && (
                      <Box component="span">
                        <e.img />
                      </Box>
                    )
                  )}
                </Avatar>
                <Typography sx={{ fontSize: "14px", fontWeight: "500", pl: 1 }}>
                  {e.Title}
                </Typography>
              </Box>
            )
          )
        )}
    </Box>
  </Box>
);

export default DisplayCard_01;

// --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const DisplayCard_03 = ({ Data }) => {
  return (
    <Box sx={{ border: "1px solid #4C5155", borderRadius: "24px" }}>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background:
              "url(https://s3-alpha-sig.figma.com/img/c20f/8f62/5da499bd90837550820c129b21d6658a?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=hlCcwfMvEAn7CSPJnYYFVrdutJTqG4OEkDczXlNq7mXYWxIeGTE3bhdE29HsSbG~VhGqrE22QXjv~SRIuNZVrVm6iAO1N8bQ60uX79ziSEsUSDJUZWCbixpg55n8E4Ds8gJJRSEQyS2YLgV33JtuvuJN0QOXm9dAWcLn0eFxJO6mLD-zc3tb4Z90~dRb7xZ52QXW3vfyA8sAHs4ztU-hSfvQvBGLhsKC5rVJE6C7BuviDxOiOhipms1GyZLasRd1Xqi5eN2wadiBFfHaYBUrlKjtDSddgbgOrS6kDtSa962l0Ot-kF0VTWLhInMhd7uRozW0YpTaIIUVc08VhHyvnQ__)",
            backgroundSize: "cover",
            backgroundPositionY: "bottom",
            backgroundPositionX: "center",
            transform: "scaleY(-1)",
            // zIndex: -1,
          }}
        />
        <Box
          sx={{
            position: "relative",
            // zIndex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // padding: '3rem 0',
            textAlign: "center",
            px: 8,
            py: 2,
          }}
        >
          <Typography sx={{ fontSize: "32px", fontWeight: "600", py: 2 }}>
            {Data.Title}
          </Typography>
          <Typography
            sx={{ fontSize: "20px", fontWeight: "400", py: 2, pb: 5 }}
          >
            {Data.Text}
          </Typography>
          <Button
            sx={{
              background: "#fff",
              borderRadius: "40px",
              height: "40px",
              py: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", py: 2 }}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "16px",
                  pr: "7px",
                }}
              >
                {Data.Btn}
              </Typography>
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_3_9270)">
                  <rect x="0.5" width="20" height="20" rx="10" fill="#8A18D0" />
                  <path
                    d="M10.91 14.645L9.884 13.63L12.951 10.562H5.5V9.082H12.952L9.885 6.019L10.909 5L15.731 9.822L10.91 14.645Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3_9270">
                    <rect x="0.5" width="20" height="20" rx="10" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Box>
          </Button>
        </Box>
      </Box>
      {Data.type === 0 && (
        <Box sx={{ py: 3 }}>
          <Box sx={{ boxSizing: "border-box", overflow: "hidden", py: 1 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "80%",
                justifyContent: "space-between",
                animation: "marquee 10s linear infinite",
                "@keyframes marquee": {
                  "0%": { transform: "translateX(50%)" },
                  "100%": { transform: "translateX(-50%)" },
                },
                "&:hover": { animationPlayState: "paused" },
              }}
            >
              {Array(8)
                .fill(0)
                .map((_, repeatIndex) =>
                  Data.FirstCarousel.map((e, i) => (
                    <Box
                      key={i}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "8px",
                        width: "max-content",
                        px: "12px",
                        mx: "5px",
                        py: "5px",
                        background: "#3A3A3A",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Avatar
                        sx={{ bgcolor: `${e.bg}` }}
                        src={e.img}
                        variant="rounded"
                      />
                      <Typography
                        sx={{ fontSize: "14px", fontWeight: "500", pl: 1 }}
                      >
                        {e.Title}
                      </Typography>
                    </Box>
                  ))
                )}
            </Box>
          </Box>
          <Box sx={{ boxSizing: "border-box", overflow: "hidden", py: 1 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "80%",
                justifyContent: "space-between",
                animation: "marquee 10s linear infinite",
                "@keyframes marquee": {
                  "0%": { transform: "translateX(-50%)" },
                  "100%": { transform: "translateX(50%)" },
                },
                "&:hover": { animationPlayState: "paused" },
              }}
            >
              {Array(8)
                .fill(0)
                .map((_, repeatIndex) =>
                  Data.SecondCarousel.map((e, i) => (
                    <Box
                      key={i}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "8px",
                        width: "max-content",
                        px: "12px",
                        mx: "5px",
                        py: "5px",
                        background: "#3A3A3A",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Avatar
                        sx={{ bgcolor: `${e.bg}` }}
                        src={e.img}
                        variant="rounded"
                      />
                      <Typography
                        sx={{ fontSize: "14px", fontWeight: "500", pl: 1 }}
                      >
                        {e.Title}
                      </Typography>
                    </Box>
                  ))
                )}
            </Box>
          </Box>
        </Box>
      )}
      {/* {Data.type === 1 &&
                <Box sx={{ py: 3 }}>
                    <Box sx={{ boxSizing: 'border-box', overflow: 'hidden', py: 1 }}>
                        <Box sx={{
                            display: 'flex', alignItems: 'center', width: '80%', justifyContent: 'space-between', animation: 'marquee 10s linear infinite',
                            '@keyframes marquee': {
                                '0%': { transform: 'translateX(-50%)' },
                                '100%': { transform: 'translateX(50%)' },
                            },
                            '&:hover': { animationPlayState: 'paused' }
                        }}>
                            {Array(8).fill(0).map((_, repeatIndex) => (
                                Data.FirstCarousel.map((e, i) => (
                                    {
                                        e.bg &&
                                            <Box key={i} sx={{ display: 'flex', alignItems: 'center', borderRadius: '8px', width: 'max-content', px: '12px', mx: '5px', py: '5px', background: '#3A3A3A', whiteSpace: 'nowrap', }}>
                                                <Avatar sx={{ bgcolor: `${e.bg}` }} src={e.img} variant="rounded" />
                                                <Typography sx={{ fontSize: '14px', fontWeight: '500', pl: 1 }}>{e.Title}</Typography>
                                            </Box>
                                    }
                                    {!e.bg &&
                                <Box key={i} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '12px', height: '80px', width: '80px', px: '12px', mx: '5px', py: '5px', background: '#414249', whiteSpace: 'nowrap', }}>
                                    <Avatar sx={{ bgcolor: `${e.bg}` }} src={e.img} variant="rounded" />
                                    <Typography sx={{ fontSize: '14px', fontWeight: '500', pl: 1 }}>{e.Title}</Typography>
                                </Box>
                                    }
                            ))
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{ boxSizing: 'border-box', overflow: 'hidden', py: 1 }}>
                        <Box sx={{
                            display: 'flex', alignItems: 'center', width: '80%', justifyContent: 'space-between', animation: 'marquee 10s linear infinite',
                            '@keyframes marquee': {
                                '0%': { transform: 'translateX(0%)' },
                                '100%': { transform: 'translateX(-50%)' },
                            },
                            '&:hover': { animationPlayState: 'paused' }
                        }}>
                            {Array(8).fill(0).map((_, repeatIndex) => (
                                Data.SecondCarousel.map((e, i) => (
                                    {
                                        e.bg &&
                                            <Box key={i} sx={{ display: 'flex', alignItems: 'center', borderRadius: '8px', width: 'max-content', px: '12px', mx: '5px', py: '5px', background: '#3A3A3A', whiteSpace: 'nowrap', }}>
                                                <Avatar sx={{ bgcolor: `${e.bg}` }} src={e.img} variant="rounded" />
                                                <Typography sx={{ fontSize: '14px', fontWeight: '500', pl: 1 }}>{e.Title}</Typography>
                                            </Box>
                                    }
                                    {!e.bg &&
                                <Box key={i} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '12px', height: '80px', width: '80px', px: '12px', mx: '5px', py: '5px', background: '#414249', whiteSpace: 'nowrap', }}>
                                    <Avatar sx={{ bgcolor: `${e.bg}` }} src={e.img} variant="rounded" />
                                    <Typography sx={{ fontSize: '14px', fontWeight: '500', pl: 1 }}>{e.Title}</Typography>
                                </Box>
                                    }
                                ))
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{ boxSizing: 'border-box', overflow: 'hidden', py: 1 }}>
                        <Box sx={{
                            display: 'flex', alignItems: 'center', width: '80%', justifyContent: 'space-between', animation: 'marquee 10s linear infinite',
                            '@keyframes marquee': {
                                '0%': { transform: 'translateX(-50%)' },
                                '100%': { transform: 'translateX(50%)' },
                            },
                            '&:hover': { animationPlayState: 'paused' }
                        }}>
                            {Array(8).fill(0).map((_, repeatIndex) => (
                                Data.ThirdCarousel.map((e, i) => (
                                    {
                                        e.bg &&
                                            <Box key={i} sx={{ display: 'flex', alignItems: 'center', borderRadius: '8px', width: 'max-content', px: '12px', mx: '5px', py: '5px', background: '#3A3A3A', whiteSpace: 'nowrap', }}>
                                                <Avatar sx={{ bgcolor: `${e.bg}` }} src={e.img} variant="rounded" />
                                                <Typography sx={{ fontSize: '14px', fontWeight: '500', pl: 1 }}>{e.Title}</Typography>
                                            </Box>
                                    }
                                    {!e.bg &&
                                <Box key={i} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '12px', height: '80px', width: '80px', px: '12px', mx: '5px', py: '5px', background: '#414249', whiteSpace: 'nowrap', }}>
                                    <Avatar sx={{ bgcolor: `${e.bg}` }} src={e.img} variant="rounded" />
                                    <Typography sx={{ fontSize: '14px', fontWeight: '500', pl: 1 }}>{e.Title}</Typography>
                                </Box>
                                    }
                                ))
                            ))}
                        </Box>
                    </Box>
                </Box>
            } */}
    </Box>
  );
};

const DisplayCard_02 = ({ Data }) => {
  return (
    <Box>
      <Box
        sx={{
          background:
            "radial-gradient(circle 50vh at 50% 100, #0e0e7e, #444486)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "32px", fontWeight: "600", py: 2 }}>
          {Data.Title}
        </Typography>
        <Typography sx={{ fontSize: "20px", fontWeight: "400", py: 2 }}>
          {Data.Text}
        </Typography>
        <Button sx={{ background: "#fff", borderRadius: "40px" }}>
          <Box sx={{ display: "flex", alignItems: "center", py: 2 }}>
            <Typography
              sx={{
                color: "#000000",
                fontWeight: "600",
                fontSize: "16px",
                pr: "7px",
              }}
            >
              {Data.Btn}
            </Typography>
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3_9270)">
                <rect x="0.5" width="20" height="20" rx="10" fill="#8A18D0" />
                <path
                  d="M10.91 14.645L9.884 13.63L12.951 10.562H5.5V9.082H12.952L9.885 6.019L10.909 5L15.731 9.822L10.91 14.645Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_3_9270">
                  <rect x="0.5" width="20" height="20" rx="10" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Box>
        </Button>
      </Box>
      {Data.type === 0 && (
        <Box>
          {Data.FirstCarousel.map((e, i) => (
            <Box key={i} sx={{ boxSizing: "border-box", overflow: "hidden" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "80%",
                  justifyContent: "space-between",
                  animation: "marquee 10s linear infinite",
                  "@keyframes marquee": {
                    "0%": { transform: "translateX(0)" },
                    "100%": { transform: "translateX(-50%)" },
                  },
                  "&:hover": { animationPlayState: "paused" },
                }}
              >
                <Box
                  sx={{
                    borderRadius: "8px",
                    px: "7px",
                    py: "5px",
                    background: "#3A3A3A",
                  }}
                >
                  <e.img />
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    {e.Title}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
          {Data.SecondCarousel.map((e, i) => (
            <Box key={i} sx={{ boxSizing: "border-box", overflow: "hidden" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "80%",
                  justifyContent: "space-between",
                  animation: "marquee 10s linear infinite",
                  "@keyframes marquee": {
                    "0%": { transform: "translateX(0)" },
                    "100%": { transform: "translateX(-50%)" },
                  },
                  "&:hover": { animationPlayState: "paused" },
                }}
              >
                <Box
                  sx={{
                    borderRadius: "8px",
                    px: "7px",
                    py: "5px",
                    background: "#3A3A3A",
                  }}
                >
                  <e.img />
                  <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                    {e.Title}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      {Data.type === 1 && (
        <Box>
          {Data.FirstCarousel.map((e, i) => (
            <Box key={i} sx={{ boxSizing: "border-box", overflow: "hidden" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "80%",
                  justifyContent: "space-between",
                  animation: "marquee 10s linear infinite",
                  "@keyframes marquee": {
                    "0%": { transform: "translateX(0)" },
                    "100%": { transform: "translateX(-50%)" },
                  },
                  "&:hover": { animationPlayState: "paused" },
                }}
              >
                <Box
                  sx={{
                    borderRadius: "12px",
                    height: "80px",
                    width: "80px",
                    background: "#414249",
                  }}
                >
                  <e.img />
                  {/* <Typography sx={{}}>{e.Title}</Typography> */}
                </Box>
              </Box>
            </Box>
          ))}
          {Data.SecondCarousel.map((e, i) => (
            <Box key={i} sx={{ boxSizing: "border-box", overflow: "hidden" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "80%",
                  justifyContent: "space-between",
                  animation: "marquee 10s linear infinite",
                  "@keyframes marquee": {
                    "0%": { transform: "translateX(0)" },
                    "100%": { transform: "translateX(-50%)" },
                  },
                  "&:hover": { animationPlayState: "paused" },
                }}
              >
                <Box
                  sx={{
                    borderRadius: "12px",
                    height: "80px",
                    width: "80px",
                    background: "#414249",
                  }}
                >
                  <e.img />
                  {/* <Typography sx={{}}>{e.Title}</Typography> */}
                </Box>
              </Box>
            </Box>
          ))}
          {Data.ThirdCarousel.map((e, i) => (
            <Box key={i} sx={{ boxSizing: "border-box", overflow: "hidden" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "80%",
                  justifyContent: "space-between",
                  animation: "marquee 10s linear infinite",
                  "@keyframes marquee": {
                    "0%": { transform: "translateX(0)" },
                    "100%": { transform: "translateX(-50%)" },
                  },
                  "&:hover": { animationPlayState: "paused" },
                }}
              >
                <Box
                  sx={{
                    borderRadius: "12px",
                    height: "80px",
                    width: "80px",
                    background: "#414249",
                  }}
                >
                  <e.img />
                  {/* <Typography sx={{}}>{e.Title}</Typography> */}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
