export const jsonData = [
  {
    name: 'Bosch',
    details: [
      { platform: 'Amazon', minPrice: 250, maxPrice: 20219, sentiment: 'Good', avgRating: 4.2, noOfSellers: 33, noOfProducts: 18 },
      { platform: 'Flipkart', minPrice: 215, maxPrice: 89999, sentiment: 'Very Good', avgRating: 4.3, noOfSellers: 57, noOfProducts: 199 },
      { platform: 'eBay', minPrice: 20, maxPrice: 219.99, sentiment: 'NA', avgRating: 'NA', noOfSellers: 47, noOfProducts: 59 },
      { platform: 'Indiamart', minPrice: 1000, maxPrice: 99080, sentiment: 'NA', avgRating: 'NA', noOfSellers: 9, noOfProducts: 29 }
    ]
  },
  {
    name: 'Makita',
    details: [
      { platform: 'Amazon', minPrice: 1258, maxPrice: 15400, sentiment: 'Good', avgRating: 4.2, noOfSellers: 39, noOfProducts: 27 },
      { platform: 'Flipkart', minPrice: 2200, maxPrice: 61390, sentiment: 'Good', avgRating: 4.0, noOfSellers: 56, noOfProducts: 40 },
      { platform: 'eBay', minPrice: 'NA', maxPrice: 'NA', sentiment: 'NA', avgRating: 'NA', noOfSellers: 47, noOfProducts: 59 },
      { platform: 'Indiamart', minPrice: 6.69, maxPrice: 891.41, sentiment: 'NA', avgRating: 'NA', noOfSellers: 'NA', noOfProducts: 59 }
    ]
  },
  {
    name: 'Dewalt',
    details: [
      { platform: 'Amazon', minPrice: 250, maxPrice: 20219, sentiment: 'Good', avgRating: 4.2, noOfSellers: 33, noOfProducts: 10 },
      { platform: 'Flipkart', minPrice: 215, maxPrice: 89999, sentiment: 'Very Good', avgRating: 4.3, noOfSellers: 54, noOfProducts: 40 },
      { platform: 'eBay', minPrice: 20, maxPrice: 600, sentiment: 'NA', avgRating: 'NA', noOfSellers: 47, noOfProducts: 59 },
      { platform: 'Indiamart', minPrice: 430, maxPrice: 49500, sentiment: 'NA', avgRating: 'NA', noOfSellers: 14, noOfProducts: 50 }
    ]
  }
];


export const tableData = [
  {
    brand: 'Bosch',
    platforms: [
      {
        name: 'Amazon',
        minPrice: 250,
        maxPrice: 20219,
        sentiment: 'Good',
        avgRating: 4.2,
        numOfSellers: 33,
        numOfProducts: 18,
      },
      {
        name: 'Flipkart',
        minPrice: 215,
        maxPrice: 89999,
        sentiment: 'Very Good',
        avgRating: 4.3,
        numOfSellers: 57,
        numOfProducts: 199,
      },
      {
        name: 'eBay',
        minPrice: 20,
        maxPrice: 219.99,
        sentiment: 'NA',
        avgRating: 'NA',
        numOfSellers: 47,
        numOfProducts: 59,
      },
      {
        name: 'Indiamart',
        minPrice: 1000,
        maxPrice: 99080,
        sentiment: 'NA',
        avgRating: 'NA',
        numOfSellers: 9,
        numOfProducts: 29,
      },
    ],
  },
  {
    brand: 'Makita',
    platforms: [
      {
        name: 'Amazon',
        minPrice: 1258,
        maxPrice: 15400,
        sentiment: 'Good',
        avgRating: 4.2,
        numOfSellers: 39,
        numOfProducts: 27,
      },
      {
        name: 'Flipkart',
        minPrice: 2200,
        maxPrice: 61390,
        sentiment: 'Good',
        avgRating: 4.0,
        numOfSellers: 56,
        numOfProducts: 40,
      },
      {
        name: 'eBay',
        minPrice: 'NA',
        maxPrice: 'NA',
        sentiment: 'NA',
        avgRating: 'NA',
        numOfSellers: 47,
        numOfProducts: 59,
      },
      {
        name: 'Indiamart',
        minPrice: 6.69,
        maxPrice: 891.41,
        sentiment: 'NA',
        avgRating: 'NA',
        numOfSellers: 'NA',
        numOfProducts: 59,
      },
    ],
  },
  {
    brand: 'Dewalt',
    platforms: [
      {
        name: 'Amazon',
        minPrice: 250,
        maxPrice: 20219,
        sentiment: 'Good',
        avgRating: 4.2,
        numOfSellers: 33,
        numOfProducts: 10,
      },
      {
        name: 'Flipkart',
        minPrice: 215,
        maxPrice: 89999,
        sentiment: 'Very Good',
        avgRating: 4.3,
        numOfSellers: 54,
        numOfProducts: 40,
      },
      {
        name: 'eBay',
        minPrice: 20,
        maxPrice: 600,
        sentiment: 'NA',
        avgRating: 'NA',
        numOfSellers: 47,
        numOfProducts: 59,
      },
      {
        name: 'Indiamart',
        minPrice: 430,
        maxPrice: 49500,
        sentiment: 'NA',
        avgRating: 'NA',
        numOfSellers: 14,
        numOfProducts: 50,
      },
    ],
  },
];

export const tableData2 = [
  {
    "brand": "Bosch",
    "platforms": [
      {
        "name": "Amazon",
        "categories": {
          "minPrice": 250,
          "maxPrice": 20219,
          "sentiment": "Good",
          "avgRating": 4.2,
          "numOfSellers": 33,
          "numOfProducts": 18
        }
      },
      {
        "name": "Flipkart",
        "categories": {
          "minPrice": 215,
          "maxPrice": 89999,
          "sentiment": "Very Good",
          "avgRating": 4.3,
          "numOfSellers": 57,
          "numOfProducts": 199
        }
      },
      {
        "name": "eBay",
        "categories": {
          "minPrice": 20,
          "maxPrice": 219.99,
          "sentiment": "NA",
          "avgRating": "NA",
          "numOfSellers": 47,
          "numOfProducts": 59
        }
      },
      {
        "name": "Indiamart",
        "categories": {
          "minPrice": 1000,
          "maxPrice": 99080,
          "sentiment": "NA",
          "avgRating": "NA",
          "numOfSellers": 9,
          "numOfProducts": 29
        }
      }
    ]
  },
  {
    "brand": "Makita",
    "platforms": [
      {
        "name": "Amazon",
        "categories": {
          "minPrice": 1258,
          "maxPrice": 15400,
          "sentiment": "Good",
          "avgRating": 4.2,
          "numOfSellers": 39,
          "numOfProducts": 27
        }
      },
      {
        "name": "Flipkart",
        "categories": {
          "minPrice": 2200,
          "maxPrice": 61390,
          "sentiment": "Good",
          "avgRating": 4.0,
          "numOfSellers": 56,
          "numOfProducts": 40
        }
      },
      {
        "name": "eBay",
        "categories": {
          "minPrice": "NA",
          "maxPrice": "NA",
          "sentiment": "NA",
          "avgRating": "NA",
          "numOfSellers": 47,
          "numOfProducts": 59
        }
      },
      {
        "name": "Indiamart",
        "categories": {
          "minPrice": 6.69,
          "maxPrice": 891.41,
          "sentiment": "NA",
          "avgRating": "NA",
          "numOfSellers": "NA",
          "numOfProducts": 59
        }
      }
    ]
  },
  {
    "brand": "Dewalt",
    "platforms": [
      {
        "name": "Amazon",
        "categories": {
          "minPrice": 250,
          "maxPrice": 20219,
          "sentiment": "Good",
          "avgRating": 4.2,
          "numOfSellers": 33,
          "numOfProducts": 10
        }
      },
      {
        "name": "Flipkart",
        "categories": {
          "minPrice": 215,
          "maxPrice": 89999,
          "sentiment": "Very Good",
          "avgRating": 4.3,
          "numOfSellers": 54,
          "numOfProducts": 40
        }
      },
      {
        "name": "eBay",
        "categories": {
          "minPrice": 20,
          "maxPrice": 600,
          "sentiment": "NA",
          "avgRating": "NA",
          "numOfSellers": 47,
          "numOfProducts": 59
        }
      },
      {
        "name": "Indiamart",
        "categories": {
          "minPrice": 430,
          "maxPrice": 49500,
          "sentiment": "NA",
          "avgRating": "NA",
          "numOfSellers": 14,
          "numOfProducts": 50
        }
      }
    ]
  }
];

export const categories = [
  'minPrice',
  'maxPrice',
  'sentiment',
  'avgRating',
  'numOfSellers',
  'numOfProducts',
];

export const seller_1 = [
  {
    "sellerName": "Clicktech Retail",
    "sellerRatings": 5,
    "sellerPositivity": "5 out of 5 stars, 99% positive in the last 12 months (14505 ratings)",
    "aboutSeller": "Clicktech Retail is committed to providing each customer with the highest standard of customer service.",
    "sellerReviewsRatings": 4.8,
    "sellerTotalRatings": "14505 total ratings, 14377 with feedback for 12 months"
  },
  {
    "sellerName": "Toolsmyne",
    "sellerRatings": 4.5,
    "sellerPositivity": "4.5 out of 5 stars, 95% positive in the last 12 months (7502 ratings)",
    "aboutSeller": "Toolsmyne is dedicated to offering top-quality tools and equipment to its customers.",
    "sellerReviewsRatings": 4.5,
    "sellerTotalRatings": "7502 total ratings, 7127 with feedback for 12 months"
  },
  {
    "sellerName": "Diplomat",
    "sellerRatings": 4.9,
    "sellerPositivity": "4.9 out of 5 stars, 97% positive in the last 12 months (10234 ratings)",
    "aboutSeller": "Diplomat specializes in providing reliable and efficient power tools to its customers.",
    "sellerReviewsRatings": 4.9,
    "sellerTotalRatings": "10234 total ratings, 9923 with feedback for 12 months"
  },
  {
    "sellerName": "N N POWER TOOLS....",
    "sellerRatings": 5,
    "sellerPositivity": "5 out of 5 stars, 100% positive in the last 12 months (5843 ratings)",
    "aboutSeller": "N N POWER TOOLS.... is your trusted source for high-quality power tools and accessories.",
    "sellerReviewsRatings": 5,
    "sellerTotalRatings": "5843 total ratings, 5843 with feedback for 12 months"
  },
  {
    "sellerName": "Vivek Electronics...",
    "sellerRatings": 5,
    "sellerPositivity": "5 out of 5 stars, 100% positive in the last 12 months (8690 ratings)",
    "aboutSeller": "Vivek Electronics... offers a wide range of electronics and power tools with exceptional service.",
    "sellerReviewsRatings": 5,
    "sellerTotalRatings": "8690 total ratings, 8690 with feedback for 12 months"
  },
  {
    "sellerName": "AL HUSSAINI MACHINERY COMPANY PRIVATE LIMITED",
    "sellerRatings": 4.3,
    "sellerPositivity": "4.3 out of 5 stars, 90% positive in the last 12 months (3876 ratings)",
    "aboutSeller": "AL HUSSAINI MACHINERY COMPANY PRIVATE LIMITED is a leading provider of machinery and tools.",
    "sellerReviewsRatings": 4.3,
    "sellerTotalRatings": "3876 total ratings, 3490 with feedback for 12 months"
  },
  {
    "sellerName": "SUWALKA POWER TOOLS & SPARE PARTS",
    "sellerRatings": 5,
    "sellerPositivity": "5 out of 5 stars, 98% positive in the last 12 months (6234 ratings)",
    "aboutSeller": "SUWALKA POWER TOOLS & SPARE PARTS offers top-quality power tools and spare parts to meet all your needs.",
    "sellerReviewsRatings": 5,
    "sellerTotalRatings": "6234 total ratings, 6120 with feedback for 12 months"
  },
  {
    "sellerName": "KROST®-Tools Centre",
    "sellerRatings": 5,
    "sellerPositivity": "5 out of 5 stars, 100% positive in the last 12 months (4987 ratings)",
    "aboutSeller": "KROST®-Tools Centre is your one-stop destination for premium quality tools and equipment.",
    "sellerReviewsRatings": 5,
    "sellerTotalRatings": "4987 total ratings, 4987 with feedback for 12 months"
  },
  {
    "sellerName": "ARPANA ENTERPRISES..",
    "sellerRatings": 5,
    "sellerPositivity": "5 out of 5 stars, 99% positive in the last 12 months (5500 ratings)",
    "aboutSeller": "ARPANA ENTERPRISES.. is committed to delivering exceptional products and services to its customers.",
    "sellerReviewsRatings": 5,
    "sellerTotalRatings": "5500 total ratings, 5450 with feedback for 12 months"
  },
  {
    "sellerName": "Lord Shiva Services",
    "sellerRatings": 5,
    "sellerPositivity": "5 out of 5 stars, 100% positive in the last 12 months (7890 ratings)",
    "aboutSeller": "Lord Shiva Services offers a wide range of services and products to meet your needs.",
    "sellerReviewsRatings": 5,
    "sellerTotalRatings": "7890 total ratings, 7890 with feedback for 12 months"
  },
  {
    "sellerName": "S P KOTHARI & CO",
    "sellerRatings": 5,
    "sellerPositivity": "5 out of 5 stars, 100% positive in the last 12 months (6754 ratings)",
    "aboutSeller": "S P KOTHARI & CO is dedicated to providing the highest quality products and services to its customers.",
    "sellerReviewsRatings": 5,
    "sellerTotalRatings": "6754 total ratings, 6754 with feedback for 12 months"
  },
  {
    "sellerName": "Dynamic ECommerce",
    "sellerRatings": 5,
    "sellerPositivity": "5 out of 5 stars, 100% positive in the last 12 months (9432 ratings)",
    "aboutSeller": "Dynamic ECommerce offers a wide range of products with fast and reliable service.",
    "sellerReviewsRatings": 5,
    "sellerTotalRatings": "9432 total ratings, 9432 with feedback for 12 months"
  },
  {
    "sellerName": "Burhani Iron &Tools Centre",
    "sellerRatings": 5,
    "sellerPositivity": "5 out of 5 stars, 100% positive in the last 12 months (4210 ratings)",
    "aboutSeller": "Burhani Iron &Tools Centre provides high-quality tools and equipment for all your needs.",
    "sellerReviewsRatings": 5,
    "sellerTotalRatings": "4210 total ratings, 4210 with feedback for 12 months"
  },
  {
    "sellerName": "Industrial_Solutions",
    "sellerRatings": 5,
    "sellerPositivity": "5 out of 5 stars, 100% positive in the last 12 months (5620 ratings)",
    "aboutSeller": "Industrial_Solutions is your reliable partner for industrial-grade tools and solutions.",
    "sellerReviewsRatings": 5,
    "sellerTotalRatings": "5620 total ratings, 5620 with feedback for 12 months"
  }
];


export const seller_2 = []
export const seller_3 = []
export const seller_4 = []
export const seller_5 = []
export const seller_6 = []
export const seller_7 = []
export const seller_8 = []
export const seller_9 = []
export const seller_11 = []
export const seller_12 = []