

export const dateClnse = (date) => {
    if (!date) return ''; // or some default value

    const dateObj = new Date(date);
    if (!(dateObj instanceof Date) || isNaN(dateObj.getTime())) {
        return 'Invalid date'; // or some default value
    }

    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('en-US', { month: 'long' });
    const year = dateObj.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
}


export const dateClnse2 = (date) => {
    if (!date) return ''; // or some default value

    const dateObj = new Date(date);
    if (!(dateObj instanceof Date) || isNaN(dateObj.getTime())) {
        return 'Invalid date'; // or some default value
    }

    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();

    const formattedDate = `${year}-${month}-${day}`;
    // const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
}


export const dateClnse3 = (date) => {
    if (!date) return ''; // or some default value

    const dateObj = new Date(date);
    console.log('raw dat', dateObj)
    if (!(dateObj instanceof Date) || isNaN(dateObj.getTime())) {
        return 'Invalid date'; // or some default value
    }

    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
}



export const RawDate = (date) => {
    if (!date) return ''; // or some default value

    const dateObj = new Date(date);
    return dateObj;
}

