import React from "react";

export const CustomMinusIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width="24"
      height="24"
      rx="12"
      fill="black" // Background color
    />
    <path
      d="M16 13H8v-2h8v2z"
      fill="white" // Icon color
    />
  </svg>
);

export const PlusIcon = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 0C20.7435 0 24.8131 1.68571 27.8137 4.68629C30.8143 7.68687 32.5 11.7565 32.5 16C32.5 20.2435 30.8143 24.3131 27.8137 27.3137C24.8131 30.3143 20.7435 32 16.5 32C12.2565 32 8.18687 30.3143 5.18629 27.3137C2.18571 24.3131 0.5 20.2435 0.5 16C0.5 11.7565 2.18571 7.68687 5.18629 4.68629C8.18687 1.68571 12.2565 0 16.5 0ZM18.624 8.624C18.624 8.09357 18.4133 7.58486 18.0382 7.20979C17.6631 6.83471 17.1544 6.624 16.624 6.624C16.0936 6.624 15.5849 6.83471 15.2098 7.20979C14.8347 7.58486 14.624 8.09357 14.624 8.624V14.124H9.124C8.59357 14.124 8.08486 14.3347 7.70979 14.7098C7.33471 15.0849 7.124 15.5936 7.124 16.124C7.124 16.6544 7.33471 17.1631 7.70979 17.5382C8.08486 17.9133 8.59357 18.124 9.124 18.124H14.624V23.624C14.624 24.1544 14.8347 24.6631 15.2098 25.0382C15.5849 25.4133 16.0936 25.624 16.624 25.624C17.1544 25.624 17.6631 25.4133 18.0382 25.0382C18.4133 24.6631 18.624 24.1544 18.624 23.624V18.124H24.124C24.6544 18.124 25.1631 17.9133 25.5382 17.5382C25.9133 17.1631 26.124 16.6544 26.124 16.124C26.124 15.5936 25.9133 15.0849 25.5382 14.7098C25.1631 14.3347 24.6544 14.124 24.124 14.124H18.624V8.624Z"
      fill="black"
    />
  </svg>
);
