import React, { useEffect, useState } from "react"
import WordCloud from "react-d3-cloud"

export function WordCloudChart(props) {
    const [data, setData] = useState(undefined)
    const [max, setMax] = useState(100)
    useEffect(() => {
        const values = props.data.map(r => {
            return r.value
        })
        console.log("props.data", props.data)
        setMax(Math.max(...values))
        setData(props.data)
    }, [props.data])

    // @ts-ignore
    const fontSize = word => (100 * word.value) / max
    // @ts-ignore
    const rotate = () => 0 // word.value % 90;

    if (data === undefined) {
        return <div>Loading..</div>
    }

    return (
        <WordCloud
            width={1000}
            height={400}
            data={data}
            fontSize={fontSize}
            rotate={rotate}
            padding={3}
            spiral="rectangular"
            random={Math.random}
        />
    )
}
