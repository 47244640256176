import { createSlice } from '@reduxjs/toolkit';

export const bundleSlice = createSlice({
    name: 'bundle',
    initialState: {
        bundle: {}, // Initial state as an object
    },
    reducers: {
        updateBundle: (state, action) => {
            console.log('Redux updateBundle action:', state, action);
            state.bundle = action.payload; // Store the new bundle data
        },
        clearBundle: (state) => {
            state.bundle = {}; // Clear the bundle data
        },
    },
});

// Export the actions
export const { updateBundle, clearBundle } = bundleSlice.actions;

// Export the reducer
export default bundleSlice.reducer;
