import { Avatar, Box, Button, IconButton, Paper, Rating, Typography } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Link } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useContext, useState } from "react";
import CountryContext from "../../components/context/CountryContext";
import { ProductTableData } from "../../utils/data/ProductTableData";
import { getParsedRating } from "../../components/table/type - 2";
import PowerTool from '../../assets/powertools.png'
import { TableComp3 } from "../../components/table/type - 3";
import { WordCloudChart } from "../../components/word-cloud/WordCloudChart";
import Discount from '../../assets/discount.png'
import Trend from '../../assets/New_Trend.png'
import P_Up from '../../assets/Price Rocked.png'
import P_Drop from '../../assets/Price Drop.png'

export const Random = () => {
    return Math.floor(Math.random() * 900) + 1000;
}

function generateModelNumber() {
    const randomNumber1 = Math.floor(Math.random() * 21) + 30;
    const randomNumber2 = Math.floor(Math.random() * 3) + 3;
    const randomLetter = ['PSA', 'PSB', 'PSC', 'PSD', 'PSE'][Math.floor(Math.random() * 5)];
    const randomLetter2 = ['PSA', 'PSB', 'PSC', 'PSD', 'PSE'][Math.floor(Math.random() * 5)];
    const randomLetter3 = ['A', 'B', 'G', 'I', 'K'][Math.floor(Math.random() * 5)];
    return `${randomLetter2}${randomNumber1}${randomLetter3}${randomNumber2}${randomLetter}`;
}

function formatNumberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const data = [
    // { text: 'hello', value: 12 },
    // { text: 'world', value: 20 },
    // { text: 'react', value: 30 },
    // { text: 'javascript', value: 40 },
    // { text: 'cloud', value: 50 },
];

const productReviews = [
    {
        text: 'Great product, very durable and long-lasting',
        words: ['great', 'product', 'durable', 'long-lasting'],
    },
    {
        text: 'Easy to use and very powerful',
        words: ['easy', 'use', 'powerful'],
    },
    {
        text: 'Very versatile and can handle tough jobs',
        words: ['versatile', 'tough', 'jobs'],
    },
    {
        text: 'Comes with a lot of useful accessories',
        words: ['accessories'],
    },
    {
        text: 'The price is a bit high, but it is worth it',
        words: ['price', 'high', 'worth'],
    },
    {
        text: 'I would definitely recommend this product',
        words: ['recommend', 'product'],
    },
    {
        text: 'The customer service was excellent',
        words: ['customer', 'service'],
    },
    {
        text: 'The product arrived in perfect condition',
        words: ['perfect', 'condition'],
    },
    {
        text: 'I had some issues with the product, but the company quickly resolved them',
        words: ['issues', 'resolved'],
    },
    {
        text: 'The product is a bit heavy, but it is easy to handle',
        words: ['heavy', 'easy', 'handle'],
    },
    {
        text: 'The product is very loud, but it is not a big issue',
        words: ['loud', 'issue'],
    },
    {
        text: 'The product is very easy to assemble',
        words: ['easy', 'assemble'],
    },
    {
        text: 'The product is very well-built and sturdy',
        words: ['well-built', 'sturdy'],
    },
    {
        text: 'The product is very easy to use and maintain',
        words: ['easy', 'use', 'maintain'],
    },
    {
        text: 'The product is very efficient and effective',
        words: ['efficient', 'effective'],
    },
    {
        text: 'The product is very easy to clean and store',
        words: ['easy', 'clean', 'store'],
    },
    {
        text: 'The product is very versatile and can be used for a variety of tasks',
        words: ['versatile', 'variety', 'tasks'],
    },
    {
        text: 'The product is very easy to adjust and customize',
        words: ['easy', 'adjust', 'customize'],
    },
    {
        text: 'The product is very easy to transport and move around',
        words: ['easy', 'transport', 'move'],
    },
    {
        text: 'The product is very easy to operate and control',
        words: ['easy', 'operate', 'control'],
    },
    {
        text: 'The product is very easy to read and understand the instructions',
        words: ['easy', 'read', 'understand'],
    },
    {
        text: 'The product is very easy to use and operate',
        words: ['easy', 'use', 'operate'],
    },
    {
        text: 'The product is very easy to set up and use',
        words: ['easy', 'set', 'use'],
    },
    {
        text: 'The product is very easy to use and handle',
        words: ['easy', 'use', 'handle'],
    },
    {
        text: 'The product is very easy to use and operate',
        words: ['easy', 'use', 'operate'],
    },
    {
        text: 'The product is very easy to use and adjust',
        words: ['easy', 'use', 'adjust'],
    },
    {
        text: 'The product is very easy to use and maintain',
        words: ['easy', 'use', 'maintain'],
    },
    {
        text: 'The product is very easy to use and customize',
        words: ['easy', 'use', 'customize'],
    },
    {
        text: 'The product is very easy to use and transport',
        words: ['easy', 'use', 'transport'],
    },
    {
        text: 'The product is very easy to use and store',
        words: ['easy', 'use', 'store'],
    },
    {
        text: 'The product is very easy to use and control',
        words: ['easy', 'use', 'control'],
    },
    {
        text: 'The product is very easy to use and read',
        words: ['easy', 'use', 'read'],
    },
    {
        text: 'The product is very easy to use and set up',
        words: ['easy', 'use', 'set'],
    },
    {
        text: 'The product is very easy to use and handle',
        words: ['easy', 'use', 'handle'],
    },
    {
        text: 'The product is very easy to use and operate',
        words: ['easy', 'use', 'operate'],
    },
    {
        text: 'The product is very easy to use and adjust',
        words: ['easy', 'use', 'adjust'],
    },
    {
        text: 'The product is very easy to use and maintain',
        words: ['easy', 'use', 'maintain'],
    },
    {
        text: 'The product is very easy to use and customize',
        words: ['easy', 'use', 'customize'],
    },
    {
        text: 'The product is very easy to use and transport',
        words: ['easy', 'use', 'transport'],
    },
    {
        text: 'The product is very easy to use and store',
        words: ['easy', 'use', 'store'],
    },
    {
        text: 'The product is very easy to use and control',
        words: ['easy', 'use', 'control'],
    },
    {
        text: 'The product is very easy to use and read',
        words: ['easy', 'use', 'read'],
    },
    {
        text: 'The product is very easy to use and set up',
        words: ['easy', 'use', 'set'],
    },
    {
        text: 'The product is very easy to use and handle',
        words: ['easy', 'use', 'handle'],
    },
    {
        text: 'The product is very easy to use and operate',
        words: ['easy', 'use', 'operate'],
    },
    {
        text: 'The product is very easy to use and adjust',
        words: ['easy', 'use', 'adjust'],
    },
    {
        text: 'The product is very easy to use and maintain',
        words: ['easy', 'use', 'maintain'],
    },
    {
        text: 'The product is very easy to use and customize',
        words: ['easy', 'use', 'customize'],
    },
    {
        text: 'The product is very easy to use and transport',
        words: ['easy', 'use', 'transport'],
    },
    {
        text: 'The product is very easy to use and store',
        words: ['easy', 'use', 'store'],
    },
    {
        text: 'The product is very easy to use and control',
        words: ['easy', 'use', 'control'],
    },
    {
        text: 'The product is very easy to use and read',
        words: ['easy', 'use', 'read'],
    },
    {
        text: 'The product is very easy to use and set up',
        words: ['easy', 'use', 'set'],
    },
    {
        text: 'The product is very easy to use and handle',
        words: ['easy', 'use', 'handle'],
    },
    {
        text: 'The product is very easy to use and operate',
        words: ['easy', 'use', 'operate'],
    },
    {
        text: 'The product is very easy to use and adjust',
        words: ['easy', 'use', 'adjust'],
    },
    {
        text: 'The product is very easy to use and maintain',
        words: ['easy', 'use', 'maintain'],
    },
    {
        text: 'The product is very easy to use and customize',
        words: ['easy', 'use', 'customize'],
    },
    {
        text: 'The product is very easy to use and transport',
        words: ['easy', 'use', 'transport'],
    },
    {
        text: 'The product is very easy to use and store',
        words: ['easy', 'use', 'store'],
    },
    {
        text: 'The product is very easy to use and control',
        words: ['easy', 'use', 'control'],
    },
    {
        text: 'The product is very easy to use and read',
        words: ['easy', 'use', 'read'],
    },
    {
        text: 'The product is very easy to use and set up',
        words: ['easy', 'use', 'set'],
    },
    {
        text: 'The product is very easy to use and handle',
        words: ['easy', 'use', 'handle'],
    },
    {
        text: 'The product is very easy to use and operate',
        words: ['easy', 'use', 'operate'],
    },
    {
        text: 'The product is very easy to use and adjust',
        words: ['easy', 'use', 'adjust'],
    },
    {
        text: 'The product is very easy to use and maintain',
        words: ['easy', 'use', 'maintain'],
    },
    {
        text: 'The product is very easy to use and customize',
        words: ['easy', 'use', 'customize'],
    },
    {
        text: 'The product is very easy to use and transport',
        words: ['easy', 'use', 'transport'],
    },
    {
        text: 'The product is very easy to use and store',
        words: ['easy', 'use', 'store'],
    },
    {
        text: 'The product is very easy to use and control',
        words: ['easy', 'use', 'control'],
    },
    {
        text: 'The product is very easy to use and read',
        words: ['easy', 'use', 'read'],
    },
    {
        text: 'The product is very easy to use and set up',
        words: ['easy', 'use', 'set'],
    },
    {
        text: 'The product is very easy to use and handle',
        words: ['easy', 'use', 'handle'],
    },
    {
        text: 'The product is very easy to use and operate',
        words: ['easy', 'use', 'operate'],
    },
    {
        text: 'The product is very easy to use and adjust',
        words: ['easy', 'use', 'adjust'],
    },
    {
        text: 'The product is very easy to use and maintain',
        words: ['easy', 'use', 'maintain'],
    },
    {
        text: 'The product is very easy to use and customize',
        words: ['easy', 'use', 'customize'],
    },
    {
        text: 'The product is very easy to use and transport',
        words: ['easy', 'use', 'transport'],
    },
    {
        text: 'The product is very easy to use and store',
        words: ['easy', 'use', 'store'],
    },
    {
        text: 'The product is very easy to use and control',
        words: ['easy', 'use', 'control'],
    },
    {
        text: 'The product is very easy to use and read',
        words: ['easy', 'use', 'read'],
    },
    {
        text: 'The product is very easy to use and set up',
        words: ['easy', 'use', 'set'],
    },
    {
        text: 'The product is very easy to use and handle',
        words: ['easy', 'use', 'handle'],
    },
    {
        text: 'The product is very easy to use and operate',
        words: ['easy', 'use', 'operate'],
    },
];


productReviews.forEach((review) => {
    review.words.forEach((word) => {
        if (!data[word]) {
            data[word] = 20;
        } else {
            data[word]++;
        }
    });
});

const dataArray = Object.entries(data).map(([text, value]) => ({ text, value }));

// console.log(dataArray);

const ProductDescription = () => {
    const [btn, setBtn] = useState(0);
    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', color: '#2B2B2BE5', borderBottom: '1px solid #E1E1E1', pb: '15px' }}>
                <Button sx={{
                    fontSize: '20px',
                    fontWeight: '600',
                    lineHeight: '24.2px',
                    color: 'inherit',
                    borderBottom: btn === 0 ? '4px solid #1DA1F2' : '',
                    '&:hover': {
                        borderBottom: btn === 0 ? '' : '4px solid #9bcceb'
                    }
                }}
                    onClick={() => setBtn(0)}>Description</Button>
                <Button sx={{
                    fontSize: '20px',
                    fontWeight: '600',
                    lineHeight: '24.2px',
                    color: 'inherit',
                    borderBottom: btn === 1 ? '4px solid #1DA1F2' : '',
                    '&:hover': {
                        borderBottom: btn === 1 ? '' : '4px solid #9bcceb'
                    }
                }}
                    onClick={() => setBtn(1)}>Sellers</Button>
                <Button sx={{
                    fontSize: '20px',
                    fontWeight: '600',
                    lineHeight: '24.2px',
                    color: 'inherit',
                    borderBottom: btn === 2 ? '4px solid #1DA1F2' : '',
                    '&:hover': {
                        borderBottom: btn === 2 ? '' : '4px solid #9bcceb'
                    }
                }}
                    onClick={() => setBtn(2)}>Reviews</Button>
                <Button sx={{
                    fontSize: '20px',
                    fontWeight: '600',
                    lineHeight: '24.2px',
                    color: 'inherit',
                    borderBottom: btn === 3 ? '4px solid #1DA1F2' : '',
                    '&:hover': {
                        borderBottom: btn === 3 ? '' : '4px solid #9bcceb'
                    }
                }}
                    onClick={() => setBtn(3)}>Notifications</Button>
            </Box>
            <Box>
                {btn === 0 && (
                    <Box sx={{ p: '12px', pt: '20px' }}>
                        <Typography>About this item High-Powered 2-In-1 For less: Power and durability within reach for screw-Driving and drilling. Designed for wood and metal work. Comes with led light for a clear and brighter view Max. Drilling diameter in wood - 20 mm; In steel - 10 mm; Max. Screw diameter - 8 mm. Easy and precise control of rpm thanks to variable speed, no-Load speed (1st/2nd gear) - 0-400 / 0-1500 Rpm. High performance: Torque (soft/hard/max.) - 13/30/- Nm.<br /><br />

                            +20% Soft torque improvements. With torque settings at 20+1, 2-Speed gearbox enables high productivity and excellent torque Autolock chuck - Chuck capacity, min/max. – 1.5/10 Mm, tool holder - Three-Jaw chuck. Battery voltage and type- 12V lithium-Ion, electronic cell protection for longer battery life and more durability. Weight excl. Battery - 0.8 Kg Warranty – 1 year from the date of invoice (provide invoice copy to claim warranty);<br /><br />

                            For any queries please contact Bosch power tools India customer service number (toll free).</Typography>
                    </Box>
                )}
                {btn === 1 && (
                    <Box sx={{ p: '12px', pt: '20px' }}>
                        <TableComp3 />
                    </Box>
                )}
                {btn === 2 && (
                    <Box sx={{ p: '12px', pt: '20px' }}>
                        <WordCloudChart data={dataArray} />
                    </Box>
                )}
                {btn === 3 && (
                    <Box sx={{ p: '12px', px: '0', pt: '20px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        {NotificationData.map((e, i) => <NotificationCard data={e} />)}
                    </Box>
                )}
            </Box>
        </Box>
    );
}

const NotificationCard = ({ data }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: '', border: '1px solid #0000001A', borderRadius: '10px', my: '5px', p: '10px' }}>
            <Box sx={{ display: 'flex' }}>
                {data.img && <Avatar src={data.img} alt="" sx={{ borderRadius: 0, height: '40px', pr: '15px', width: 'auto' }} />}
                <Box>
                    <Typography sx={{ color: '#191919', fontWeight: '600', fontSize: '15px' }}>{data.title}</Typography>
                    <Typography sx={{ color: '#000000', fontWeight: '400', fontSize: '13px' }}>{data.description}</Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button
                    sx={{
                        fontWeight: "500",
                        fontSize: "12px",
                        color: "#1DA1F2",
                        px: "17px",
                        py: 0,
                        height: "25px",
                        width: "min-content",
                        display: "flex",
                        alignItems: "center",
                        ml: "5px",
                        background: "#E8F6FE",
                        borderRadius: "16px",
                    }}
                >
                    Details
                </Button>
                <Typography sx={{ color: '#0085D7', fontWeight: '400', fontSize: '12px' }}>{data.date}</Typography>
            </Box>
        </Box>
    );
}

const NotificationData = [
    {
        img: Discount,
        title: 'Discount and Offers',
        description: 'Found new offers and discounts in your interests.',
        date: '15-05-24'
    },
    {
        img: P_Drop,
        title: 'Price Drop ',
        description: '50% Price drop on your subscribed product. Grab it now!',
        date: '15-05-24'
    },
    {
        img: P_Up,
        title: 'Price Rocketed ...🚀',
        description: 'The price has been increased 20%.',
        date: '15-05-24'
    },
    {
        img: P_Drop,
        title: 'Price Drop ',
        description: '50% Price drop on your subscribed product. Grab it now!',
        date: '15-05-24'
    },
    {
        img: Trend,
        title: 'New Trend Analysis Available',
        description: 'Check out the new trend market.',
        date: '15-05-24'
    },
];

export const Product = () => {
    const { country, setCountry, brand, setBrand, subBrand, setSubBrand, seller, setSeller, product, setProduct, DashboardTableData } = useContext(CountryContext)
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: '70px', p: 3 }}>
            <Link to={'/Brands'}>
                <Box sx={{ display: 'flex', alignItems: 'center', my: '10px' }}>
                    {/* <Avatar src={ArrowBackIosIcon} alt="" sx={{ height: 25, width: 'auto', mr: '5px', borderRadius: '5px' }} /> */}
                    <IconButton aria-label="Example" sx={{ display: 'flex', justifyContent: 'center', mr: '5px', pl: '0' }}>
                        <ArrowBackIosIcon sx={{ height: '14px', width: 'auto' }} color="#2B2B2B" />
                    </IconButton>
                    {/* <ArrowBackIosIcon /> */}
                    <Typography sx={{ color: '#2B2B2B', fontWeight: '600', fontSize: '16px' }}>Product Details</Typography>
                </Box>
            </Link>
            <Box>
                <Typography>{ProductTableData[brand].tableDatas[product].product}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Rating
                        name="rating"
                        value={getParsedRating(ProductTableData[brand].tableDatas[product].rating)}
                        precision={0.1}
                        readOnly
                        emptyIcon={<StarBorderIcon fontSize="small" style={{ color: 'gray' }} />}
                        icon={<StarIcon fontSize="small" style={{ color: '#fdd835' }} />}
                    />
                    <Typography sx={{ color: '#2B2B2B', fontWeight: '400', fontSize: '12px', ml: '5px' }}>{ProductTableData[brand].tableDatas[product].reviews} reviews {Random()} Feedbacks</Typography>
                </Box>
            </Box>
            <Paper elevation={2} sx={{ my: '15px', display: 'flex' }}>
                <Box sx={{ width: '40%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', p: 2 }}>
                    <Box sx={{ p: 2, border: '1px solid #EAECF0', display: 'flex', height: '70%', justifyContent: 'center' }}>
                        <Avatar src={PowerTool} alt="" sx={{ borderRadius: 0, height: 'auto', width: '356px' }} />
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ color: '#191919', fontWeight: '500', fontSize: '16px' }}>Brand:</Typography>
                                    <Typography sx={{ color: '#191919', fontWeight: '400', fontSize: '16px' }}>{ProductTableData[brand].brand}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ color: '#191919', fontWeight: '500', fontSize: '16px' }}>Model:</Typography>
                                    <Typography sx={{ color: '#191919', fontWeight: '400', fontSize: '16px' }}>{generateModelNumber()}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography sx={{ color: '#191919', fontWeight: '400', fontSize: '16px' }}>MRP</Typography>
                                <Typography sx={{ color: '#191919', fontWeight: '400', fontSize: '24px' }}>₹{formatNumberWithCommas(ProductTableData[brand].tableDatas[subBrand].mrp)}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: '5px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography sx={{ border: '1px solid #0000001A', borderRadius: '5px', px: '12px', py: '8px' }}>₹{formatNumberWithCommas(ProductTableData[brand].tableDatas[subBrand].min_p)}</Typography>
                                <Typography sx={{ color: '#47474799', fontWeight: '400', fontSize: '14px' }}>Minimum Price</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography sx={{ border: '1px solid #0000001A', borderRadius: '5px', px: '12px', py: '8px' }}>₹{formatNumberWithCommas(ProductTableData[brand].tableDatas[subBrand].avg_p)}</Typography>
                                <Typography sx={{ color: '#47474799', fontWeight: '400', fontSize: '14px' }}>Average Price</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography sx={{ border: '1px solid #0000001A', borderRadius: '5px', px: '12px', py: '8px' }}>₹{formatNumberWithCommas(ProductTableData[brand].tableDatas[subBrand].max_p)}</Typography>
                                <Typography sx={{ color: '#47474799', fontWeight: '400', fontSize: '14px' }}>Maximum Price</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ width: '50%', p: 2 }}>
                    <ProductDescription />
                </Box>
            </Paper>
        </Box>
    );
}