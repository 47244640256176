import React from 'react';
import { Box, Typography, Card } from '@mui/material';

const DataGrid = ({ title, subtitle, data }) => {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        padding: '0',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: '90%',
          maxWidth: '1200px',
          backgroundColor: '#fff',
          borderRadius: '12px',
          pt: 8,
          pb: 4,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mb: 0,
            color: '#000000',
            textAlign: 'left',
            fontWeight: '600',
            fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem', lg: '2.25rem', xl: '2.5rem' }, 
            ml: { xs: 2, sm: 6, md: 5, lg: 4, xl: -5 }
          }}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="h6"
            sx={{
              mb: 5,
              color: '#474747',
              textAlign: 'left',
              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem', lg: '1.35rem', xl: '1.5rem' }, 
              ml: { xs: 2, sm: 6, md: 5, lg: 4, xl: -5 }
            }}
          >
            {subtitle}
          </Typography>
        )}
        <Box
          sx={{
            display: 'grid',
            gap: {
              xs: '20px', 
              sm: '20px', 
              md: '40px', 
            },
            gridTemplateColumns: {
              xs: 'repeat(2, 0.4fr)', 
              sm: 'repeat(4, 0.21fr)', 
              md: 'repeat(4, 0.2fr)',   
              lg: 'repeat(5, 1fr)', 
              xl: 'repeat(7, 1fr)', 
            },
            justifyContent: 'center',
            width: '100%',
            '@media (max-width: 400px)': {
              justifyItems: 'center', 
            },
          }}
        >
          {data.map((item, index) => (
            <Card
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#FAFAFA',
                borderRadius: '8px',
                height: { xs: '100px', md: '100px', lg: '120px' },
                width: { xs: '120px', md: '180px', lg: '150px' }, 
                border: '1px solid',
                borderColor: '#EAECF0',
                boxShadow: 'none',
                margin: '0 auto', 
                textAlign: 'center',
              }}
            >
              {item.icon}
              <Typography
                variant="body1"
                sx={{
                  fontSize: '16px',
                  mt: 1,
                  fontWeight: '500',
                  textAlign: 'center',
                }}
              >
                {item.text}
              </Typography>
            </Card>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DataGrid;
