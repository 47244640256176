import {
  Alert,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
// import { CreditsLeftIcon, CreditsSpentIcon, BundleRunsIcon, BundleCreatedIcon } from "../IndividualUserLandingPage";
import {
  SearchIcon,
  modifyDataIDP,
  Model_1,
} from "../IndividualUserLandingPage";
import { useEffect, useState } from "react";
import http from "../../utils/http-common";
import {
  dateClnse3,
  RawDate,
} from "../../components/Public/DateClnse";
import { Link, useNavigate } from "react-router-dom";
import { downloadCSV } from "../../components/Public/DownloadCSV";
import { useDispatch, useSelector } from "react-redux";
import { Bundle } from "../../components/@extended/Bundle";
import { updateBundle } from "../../slice/bundle";
import { updateCredit } from "../../slice/credits";
import { Card_3 } from "../../components/card/Bundle-Card";

export const Bundle_Log = () => {
  const [respData, setRespData] = useState([]);
  const [bundleRuns, setBundleRuns] = useState("");
  const [BundleCreated, setBundleCreated] = useState("");
  const [modelData, setModelData] = useState({
    title: "Welcome to Brand Aura",
    emoji: "🎉",
    txt: [
      "Thank you for registering into our product.",
      "If you have any questions, feel free to reach out to our support team.",
      "Lets get started!!!",
    ],
    cl: "",
    btn_1: "Add Credits",
    btn_1_Link: "/Dashboard/Individual-Dashboard/Recharge",
    btn_2: "Select Data",
    btn_2_Link: "/Dashboard/Individual-Dashboard/User-Wizard",
  });
  // const [cred, setCred] = useState([0, 0])

  const [open, setOpen] = useState(false);
  const [openS, setOpenS] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [searchTerm, setSearchTerm] = useState("");
  const credi = useSelector((state) => state.credit.credit);

  const handleClick = () => {
    setOpenS(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenS(false);
  };

  const Data2 = [
    {
      icon: CreditsLeftIcon,
      title: "Credits Left",
      value: credi.available_credit,
      // value: 0
    },
    {
      icon: CreditsSpentIcon,
      title: "Over all credits spent",
      value: credi.used_credit,
      // value: 0
    },
    {
      icon: BundleRunsIcon,
      title: "Total Bundle Runs",
      value: bundleRuns,
    },
    {
      icon: BundleCreatedIcon,
      title: "Total Bundles Created",
      value: BundleCreated,
    },
  ];

  // const ModelData = {
  //     title: 'Run Bundle',
  //     emoji: '',
  //     txt: ['Running the bundle costs credits.', 'You need 20 Credits to run this bundle.'],
  //     cl: '40',
  //     btn_1: 'Cancel',
  //     btn_1_cancel: true,
  //     // btn_1_Link: '',
  //     btn_2: 'Proceed',
  //     btn_2_cancel: 'true',
  //     // btn_2_Link: '/Dashboard/Individual-Dashboard',
  //     // btn_2_fn: Bundle_Creation
  // }
  const filterBundles = respData.filter((bundle) =>
    bundle.ind_bundle_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const dispatch = useDispatch();
  const navi = useNavigate();
  const bundle = useSelector((state) => state.bundles.bundle);
  console.log("bundle data", bundle);

  const EditBundle = async () => {
    try {
      const bundle_id = localStorage.getItem("bundle_id");
      const filteredData = respData.filter(
        (data) => data.ind_bundle_id === bundle_id
      );
      const selectedData2 = filteredData[0];
      // const dr = { startDate: selectedData2.ind_bundle_start_date, endDate: selectedData2.ind_bundle_start_date }
      const dr = {
        startDate: RawDate(selectedData2.ind_bundle_start_date),
        endDate: RawDate(selectedData2.ind_bundle_end_date),
      };
      console.log("respData", dr, respData, filteredData, filteredData[0]);
      const set = {
        SelectData: {
          category: selectedData2.table.Category,
          category_id: selectedData2.ind_bundle_category_id,
          country: selectedData2.table.Country,
          country_id: selectedData2.ind_bundle_country_id,
          ecommerceSites: selectedData2.table["E-Commerce Site"],
          ecommerceSites_id: selectedData2.ind_bundle_e_commerce_id,
          brand: selectedData2.table.Brand,
          brand_id: selectedData2.ind_bundle_brand_id,
        },
        bundleName: selectedData2.ind_bundle_name,
        ind_bundle_id: selectedData2.ind_bundle_id,
        daterange: dr,
        state: 0,
      };
      console.log("set", set);
      await dispatch(updateBundle(set));
      console.log("bundle data", bundle);
      navi("/Dashboard/Individual-Dashboard/User-Wizard");
      // localStorage.setItem('DataSelectionEdit', JSON.stringify(set))
      // localStorage.removeItem('bundle_id')
      // window.location.href = '/Dashboard/Individual-Dashboard/User-Wizard'
    } catch (error) {
      console.log("Edit bundle error", error);
      setMessage(
        error.response
          ? error.response.data.message
          : "Unable to Set the Edit Data."
      );
      setSeverity("error");
      handleClick();
      localStorage.removeItem("bundle_id");
    }
  };
  const DeleteBundle = async () => {
    try {
      const bundle_id = localStorage.getItem("bundle_id");
      const response = await http.delete(
        `/master_service/bundle/delete/${localStorage.getItem(
          "user_id"
        )}/${bundle_id}`
      );
      await FetchDatas();
      console.log("respon", response);

      setMessage(response.data.message);
      setSeverity("success");
      handleClick();
      setModelData({
        title: "Welcome to Brand Aura",
        emoji: "🎉",
        txt: [
          "Thank you for registering into our product.",
          "If you have any questions, feel free to reach out to our support team.",
          "Lets get started!!!",
        ],
        cl: "",
        btn_1: "Add Credits",
        btn_1_Link: "/Dashboard/Individual-Dashboard/Recharge",
        btn_2: "Select Data",
        btn_2_Link: "/Dashboard/Individual-Dashboard/User-Wizard",
      });
      localStorage.removeItem("bundle_id");
    } catch (error) {
      console.log("Delete bundle error", error);
      setMessage(
        error.response ? error.response.data.message : "Unable to Delete Data."
      );
      setSeverity("error");
      handleClick();
      localStorage.removeItem("bundle_id");
    }
  };
  const CopyBundle = async () => {
    try {
      const bundle_id = localStorage.getItem("bundle_id");
      const response = await http.post(
        `/master_service/duplicate_bundle`,
        JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          ind_bundle_id: bundle_id,
        })
      );
      await FetchDatas();
      console.log("respon", response);
      setMessage(response.data.message);
      setSeverity("success");
      handleClick();
      localStorage.removeItem("bundle_id");
    } catch (error) {
      console.log("Delete bundle error", error);
      setMessage(
        error.response ? error.response.data.message : "Unable to copy Data."
      );
      setSeverity("error");
      handleClick();
      localStorage.removeItem("bundle_id");
    }
  };
  const DownloadBundle = async (bundle_id) => {
    try {
      const payload = {
        ind_bundle_id: bundle_id,
        user_id: localStorage.getItem("user_id"),
      };
      const response = await http.post(
        "master_service/download_bundle",
        payload
      );
      console.log("resp Dnw", response);
      const filteredData = respData.filter(
        (data) => data.ind_bundle_id === bundle_id
      );
      const selectedData2 = filteredData[0];
      const time = new Date().toLocaleTimeString();
      console.log("tm", time);
      downloadCSV(
        response.data.data,
        `${selectedData2.ind_bundle_name}_${dateClnse3(new Date())}_${time}`
      );
      await FetchDatas();

      setMessage(response.data.message);
      setSeverity("success");
      handleClick();
    } catch (error) {
      console.error("Error with RunBundle:", error);
      setMessage(
        error.response ? error.response.data.error : "Unable to Run the Data."
      );
      setSeverity("error");
      handleClick();
    }
  };
  const ChangeModelData = (itm, rb) => {
    console.log("clk");
    if (itm === 0) {
      if (!rb) {
        // setModelData({
        //     title: 'Edit Brand',
        //     // emoji: '🎉',
        //     txt: rb ? ['You cannot directly change the datas of the created bundle.', 'Duplicate the bundle to change the datas.'] : ['Are you sure you want to edit the bundle??'],
        //     cl: '',
        //     btn_1: 'Cancel',
        //     btn_1_cancel: true,
        //     // btn_1_Link: '/Dashboard/Individual-Dashboard/Recharge',
        //     btn_2: 'Select Data',
        //     btn_2_fn: EditBundle,
        //     // btn_2_Link: '/Dashboard/Individual-Dashboard/User-Wizard'
        // })
        // setOpen(true)
        EditBundle();
      } else {
        CopyBundle();
      }
    } else if (itm === 1) {
      setModelData({
        title: "Delete Bundle",
        // emoji: '🎉',
        txt: ["Are you sure you want to delete the bundle??"],
        cl: "",
        btn_1: "Cancel",
        btn_1_cancel: true,
        // btn_1_Link: '/Dashboard/Individual-Dashboard/Recharge',
        btn_2: "Delete",
        btn_2_fn: DeleteBundle,
        btn_2_cancel: true,
        // btn_2_Link: '/Dashboard/Individual-Dashboard/User-Wizard'
      });
      setOpen(true);
    }
    // else if (itm === 2) {
    //   setModelData({
    //     title: 'Change Date',
    //     // emoji: '🎉',
    //     txt: ['You cannot directly change the dates of the created bundle.', 'Duplicate the bundle to change the dates.'],
    //     cl: '',
    //     btn_1: 'Cancel',
    //     btn_1_cancel: true,
    //     // btn_1_Link: '/Dashboard/Individual-Dashboard/Recharge',
    //     btn_2: 'Duplicate',
    //     btn_2_fn: ChangeDate,
    //     // btn_2_Link: '/Dashboard/Individual-Dashboard/User-Wizard'
    //   })
    //   setOpen(true)
    // }
  };

  const FetchDatas = async () => {
    try {
      const response = await http.post(
        "/master_service/bundle/log/list",
        JSON.stringify({ user_id: localStorage.getItem("user_id") })
      );
      const modifiedData = modifyDataIDP(response.data.data);
      setRespData(modifiedData);
      setBundleCreated(response.data.Bundle_Created);
      setBundleRuns(response.data.Bundle_Runs);
      await Credit();
      console.log("respon", response, response.data.data, modifiedData);

      //   setMessage(response.data.message);
      //   setSeverity("success");
      //   handleClick();
    } catch (error) {
      console.log("fetching error", error);
      setMessage(
        error.response
          ? error.response.data.message
          : "Unable to Fetch the Data."
      );
      setSeverity("error");
      handleClick();
    }
  };

  const Credit = async () => {
    try {
      const res = await http.post(
        "/master_service/credit",
        JSON.stringify({ user_id: localStorage.getItem("user_id") })
      );
      console.log("resp", res, res.data);
      // setCred([res.data.data.used_credit, res.data.data.balance_credit])
      dispatch(
        updateCredit({
          available_credit: res.data.data.available_credit,
          used_credit: res.data.data.used_credit,
        })
      );
    } catch (error) {
      console.log("credits err", error);
    }
  };

  const RunBundle = async (bundle_id) => {
    try {
      const payload = {
        ind_bundle_id: bundle_id,
        user_id: localStorage.getItem("user_id"),
      };
      const response = await http.post("master_service/run/bundle", payload);
      const filteredData = respData.filter(
        (data) => data.ind_bundle_id === bundle_id
      );
      const selectedData2 = filteredData[0];
      const time = new Date().toLocaleTimeString();
      console.log("tm", time);
      downloadCSV(
        response.data.data,
        `${selectedData2.ind_bundle_name}_${dateClnse3(new Date())}_${time}`
      );
      await FetchDatas();

      setMessage("Bundle run sucessfully");
      setSeverity("success");
      handleClick();
    } catch (error) {
      console.error("Error with RunBundle:", error);
      setMessage(
        error.response ? error.response.data.message : "Unable to Run the Data."
      );
      setSeverity("error");
      handleClick();
    }
  };

  useEffect(() => {
    FetchDatas();
  }, []);
  const runbndl = {
    title: "Run Bundle",
    // emoji: "🎉",
    cl: credi.available_credit,
    btn_1: "Cancel",
    btn_1_cancel: true,
    // btn_1_Link: "/Dashboard/Individual-Dashboard/Recharge",
    btn_2: "Proceed",
    btn_2_cancel: true,
    // btn_2_Link: "/Dashboard/Individual-Dashboard/User-Wizard",
    btn_2_fn: RunBundle,
    Credits: true,
    CreditsLnk: "/Dashboard/Individual-Dashboard/Recharge",
    // btn_2_fn_props: {},
  };
  const dnbndl = {
    title: "Download Bundle",
    // emoji: "🎉",
    txt: [
      "You have used the free downloads.",
      [{ text: 'You need ' }, { text: `${2} Credits`, isBold: true }, { text: ' to download this bundle.' }],
      // "You need 2 Credits to download this bundle.",
    ],
    cl: credi.available_credit,
    btn_1: "Cancel",
    btn_1_cancel: true,
    // btn_1_Link: "/Dashboard/Individual-Dashboard/Recharge",
    btn_2: "Proceed",
    btn_2_cancel: true,
    Credits: true,
    CreditsLnk: "/Dashboard/Individual-Dashboard/Recharge",
    // btn_2_Link: "/Dashboard/Individual-Dashboard/User-Wizard",
    btn_2_fn: DownloadBundle,
  };
  const BndlePops = ({ x, ec, id }) => {
    console.log("BndlePops", x, id);
    if (x === 0) {
      setModelData({
        ...runbndl,
        txt: [
          "Running the bundle costs credits.",
          [{ text: 'You need ' }, { text: `${ec} Credits`, isBold: true }, { text: ' to run this bundle.' }],
          // `You need ${ec} Credits to run this bundle.`,
        ],
        btn_2_fn_props: id,
      });
      setOpen(true);
    } else if (x === 1) {
      setModelData({ ...dnbndl, btn_2_fn_props: id });
      setOpen(true);
    } else if (x === 2) {
      DownloadBundle(id);
    }
  };
  return (
    <Box sx={{ height: "100%", width: "100%", overflowY: "auto" }}>
      <Model_1 open={open} setOpen={setOpen} data={modelData} />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
          borderRight: "0.8px solid #797F8F80",
          display: "flex",
          flexDirection: "column",
          py: { xs: 1, md: 3 },
          px: { xs: 1, md: 5 },
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: "center",
            justifyContent: "space-between",
            py: { xs: 2, md: 0 },
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "20px",
              py: { xs: 1, md: 3 },
              background:
                "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Bundle Summary
          </Typography>
          <Button
            sx={{
              background: "#8A18D0",
              px: 2,
              color: "#fff",
              textTransform: "capitalize",
              "&:hover": { background: "#B37FFC" },
            }}
          >
            <Link to={"/Dashboard/Individual-Dashboard/User-Wizard"}>
              <Typography sx={{ ml: 2, fontWeight: "600", fontSize: "10px" }}>
                New Bundle
              </Typography>
            </Link>
          </Button>
        </Box>
        <Box sx={{ display: "flex", width: '100%', justifyContent: 'space-between', flexWrap: 'wrap', }}>
          {Data2.map((f, j) => (
            <Card_3
              key={j}
              data={{
                icon: f.icon,
                title: f.title,
                value: f.value,
                wd: `${(100 / 4) - 0.5}%`,
                bg: "#F3E7FA",
                bd: "",
              }}
            />
          ))}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              py: 3,
              color: "#000000",
            }}
          >
            My Bundles
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #797F8F",
              borderRadius: "4px",
              padding: "0.5rem 1rem",
              // backgroundColor: '#f9f9f9',
              width: "100%",
              height: "35px",
              maxWidth: "350px",
            }}
          >
            <SearchIcon sx={{ color: "#757575", marginRight: "0.5rem" }} />
            <TextField
              sx={{
                flex: 1,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                    height: "35px",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
              placeholder="Search bundle..."
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Box>
        </Box>
        <Box>
          {filterBundles.map((e, i) => (
            <Bundle
              key={i}
              e={e}
              ChangeModelData={ChangeModelData}
              BndlePops={BndlePops}
            />
          ))}
        </Box>
      </Box>
      <Snackbar
        open={openS}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ position: "absolute", mt: "38px" }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box >
  );
};

export const Bundle_Log_2 = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "20px",
              pb: 3,
              background:
                "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            My Bundles
          </Typography>
        </Box>
      </Box>
      <Box>
        {Data.map((e, i) => (
          <Card e={e} key={i} />
        ))}
      </Box>
    </Box>
  );
};

const SmallCard = ({ e }) => {
  return (
    <Box
      sx={{
        border: "1px solid #EAECF0",
        background: e.bg,
        mx: 2,
        borderRadius: "2px",
        height: "52px",
        width: "80px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography
          sx={{ fontWeight: "600", fontSize: "10px", color: "#000000" }}
        >
          {e.title}
        </Typography>
        <Typography
          sx={{ fontWeight: "500", fontSize: "12px", color: "#474747" }}
        >
          {e.text}
        </Typography>
      </Box>
    </Box>
  );
};

const Card = ({ e }) => {
  return (
    <Box
      sx={{
        borderRadius: "6px",
        border: "1px solid #BCBFC7",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 2,
        my: 2,
      }}
    >
      <Box
        sx={{
          height: "52px",
          width: "57px",
          color: "#fff",
          background: "#000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "600",
          mx: 2,
          fontSize: "24px",
        }}
      >
        {e.bundle_id}
      </Box>
      <Box sx={{ mx: 2 }}>
        <Typography>{e.title}</Typography>
        <Box sx={{ display: "flex", mt: 1 }}>
          <Typography
            sx={{
              p: 1,
              border: "0.8px solid #474747CC",
              borderRadius: "20px",
              color: "#474747CC",
              mr: 1,
            }}
          >
            Data From : {e.date_from}
          </Typography>
          <Typography
            sx={{
              p: 1,
              border: "0.8px solid #474747CC",
              color: "#474747CC",
              borderRadius: "20px",
              ml: 1,
            }}
          >
            Data Till : {e.date_till}
          </Typography>
        </Box>
      </Box>
      <SmallCard
        e={{ bg: "#1DA1F21A", title: "Categories", text: e.Categories }}
      />
      <SmallCard e={{ bg: "#FFE2E5", title: "Countries", text: e.Countries }} />
      <SmallCard e={{ bg: "#DCFCE7", title: "Platforms", text: e.Platforms }} />
      <SmallCard e={{ bg: "#FFF4DE", title: "Brands", text: e.Brands }} />
      <SmallCard
        e={{ bg: "#F3E7FA", title: "Bundle Cost", text: e.Bundle_Cost }}
      />
      <Button disableRipple>
        <EditSvg />
      </Button>
      <Button disableRipple>
        <DeleteSvg />
      </Button>
      <Button disableRipple>
        <DuplicateSvg />
      </Button>
      <Button disableRipple>
        <PlaySvg />
      </Button>
      <Button disableRipple>
        <NextSvg />
      </Button>
    </Box>
  );
};

export const EditSvg = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.25 5.25H4.5C4.10218 5.25 3.72064 5.40804 3.43934 5.68934C3.15804 5.97064 3 6.35218 3 6.75V13.5C3 13.8978 3.15804 14.2794 3.43934 14.5607C3.72064 14.842 4.10218 15 4.5 15H11.25C11.6478 15 12.0294 14.842 12.3107 14.5607C12.592 14.2794 12.75 13.8978 12.75 13.5V12.75"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 3.75008L14.25 6.00008M15.2887 4.93883C15.5841 4.64345 15.7501 4.24282 15.7501 3.82508C15.7501 3.40734 15.5841 3.00672 15.2887 2.71133C14.9934 2.41595 14.5927 2.25 14.175 2.25C13.7573 2.25 13.3566 2.41595 13.0613 2.71133L6.75 9.00008V11.2501H9L15.2887 4.93883Z"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const DeleteSvg = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 3H11.625L10.875 2.25H7.125L6.375 3H3.75V4.5H14.25M4.5 14.25C4.5 14.6478 4.65804 15.0294 4.93934 15.3107C5.22064 15.592 5.60218 15.75 6 15.75H12C12.3978 15.75 12.7794 15.592 13.0607 15.3107C13.342 15.0294 13.5 14.6478 13.5 14.25V5.25H4.5V14.25Z"
      fill="black"
    />
  </svg>
);

const DuplicateSvg = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3438 3.9375H6.46875C5.79742 3.9375 5.15359 4.20418 4.67889 4.67889C4.20418 5.15359 3.9375 5.79742 3.9375 6.46875V14.3438C3.9375 15.0151 4.20418 15.6589 4.67889 16.1336C5.15359 16.6083 5.79742 16.875 6.46875 16.875H14.3438C15.0151 16.875 15.6589 16.6083 16.1336 16.1336C16.6083 15.6589 16.875 15.0151 16.875 14.3438V6.46875C16.875 5.79742 16.6083 5.15359 16.1336 4.67889C15.6589 4.20418 15.0151 3.9375 14.3438 3.9375ZM13.2029 10.9688H10.9688V13.2029C10.9688 13.5056 10.736 13.7654 10.4333 13.7805C10.3573 13.7842 10.2813 13.7724 10.2099 13.7458C10.1385 13.7192 10.0733 13.6784 10.0182 13.6259C9.96309 13.5734 9.9192 13.5102 9.88921 13.4402C9.85922 13.3702 9.84376 13.2949 9.84375 13.2188V10.9688H7.60957C7.30687 10.9688 7.04707 10.736 7.03195 10.4333C7.02829 10.3573 7.0401 10.2813 7.06668 10.2099C7.09326 10.1385 7.13405 10.0733 7.18658 10.0182C7.23911 9.96309 7.30228 9.9192 7.37227 9.88921C7.44226 9.85922 7.51761 9.84376 7.59375 9.84375H9.84375V7.60957C9.84375 7.30687 10.0765 7.04707 10.3792 7.03195C10.4552 7.02829 10.5312 7.0401 10.6026 7.06668C10.674 7.09326 10.7392 7.13405 10.7943 7.18658C10.8494 7.23911 10.8933 7.30228 10.9233 7.37227C10.9533 7.44226 10.9687 7.51761 10.9688 7.59375V9.84375H13.2188C13.295 9.84366 13.3704 9.85906 13.4405 9.889C13.5105 9.91895 13.5738 9.96282 13.6264 10.018C13.679 10.0731 13.7198 10.1383 13.7465 10.2097C13.7731 10.2811 13.7849 10.3572 13.7812 10.4333C13.7665 10.736 13.5056 10.9688 13.2029 10.9688Z"
      fill="black"
    />
    <path
      d="M13.9177 2.8125C13.7426 2.31934 13.4193 1.89242 12.9921 1.59029C12.5648 1.28816 12.0545 1.12563 11.5312 1.125H3.65625C2.98492 1.125 2.34109 1.39168 1.86639 1.86639C1.39168 2.34109 1.125 2.98492 1.125 3.65625V11.5312C1.12563 12.0545 1.28816 12.5648 1.59029 12.9921C1.89242 13.4193 2.31934 13.7426 2.8125 13.9177V5.625C2.8125 4.87908 3.10882 4.16371 3.63626 3.63626C4.16371 3.10882 4.87908 2.8125 5.625 2.8125H13.9177Z"
      fill="black"
    />
  </svg>
);

const PlaySvg = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0568 7.01498C16.417 7.20654 16.7183 7.4925 16.9284 7.84222C17.1385 8.19194 17.2495 8.59224 17.2495 9.00023C17.2495 9.40822 17.1385 9.80852 16.9284 10.1582C16.7183 10.508 16.417 10.7939 16.0568 10.9855L6.44775 16.2107C4.9005 17.053 3 15.958 3 14.2262V3.77498C3 2.04248 4.9005 0.948231 6.44775 1.78898L16.0568 7.01498Z"
      fill="black"
    />
  </svg>
);

const NextSvg = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_430_5824)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.1783 18.822C26.4908 19.1345 26.6663 19.5584 26.6663 20.0003C26.6663 20.4422 26.4908 20.8661 26.1783 21.1786L16.75 30.607C16.5963 30.7662 16.4123 30.8931 16.209 30.9805C16.0057 31.0678 15.787 31.1138 15.5657 31.1157C15.3444 31.1176 15.1249 31.0755 14.9201 30.9917C14.7153 30.9079 14.5292 30.7841 14.3727 30.6276C14.2162 30.4711 14.0924 30.2851 14.0086 30.0802C13.9248 29.8754 13.8827 29.6559 13.8846 29.4346C13.8865 29.2133 13.9325 28.9946 14.0198 28.7913C14.1072 28.588 14.2342 28.404 14.3933 28.2503L22.6433 20.0003L14.3933 11.7503C14.0897 11.436 13.9217 11.015 13.9255 10.578C13.9293 10.141 14.1046 9.72295 14.4136 9.41394C14.7227 9.10492 15.1407 8.92964 15.5777 8.92584C16.0147 8.92205 16.4357 9.09004 16.75 9.39363L26.1783 18.822Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_430_5824">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CreditsLeftIcon = () => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20.5" cy="20.5" r="20" fill="#8A18D0" />
    <path
      d="M25.4 17.4002H14.15V16.9502L24.05 16.1582V16.9502H25.4V15.6002C25.4 14.6102 24.5981 13.9154 23.6189 14.0549L14.582 15.3455C13.6019 15.4859 12.8 16.4102 12.8 17.4002V26.4002C12.8 26.8776 12.9896 27.3355 13.3272 27.673C13.6648 28.0106 14.1226 28.2002 14.6 28.2002H25.4C25.8774 28.2002 26.3352 28.0106 26.6728 27.673C27.0103 27.3355 27.2 26.8776 27.2 26.4002V19.2002C27.2 18.7228 27.0103 18.265 26.6728 17.9274C26.3352 17.5899 25.8774 17.4002 25.4 17.4002ZM24.05 23.7056C23.8726 23.7056 23.697 23.6706 23.5332 23.6027C23.3694 23.5347 23.2206 23.4352 23.0952 23.3098C22.9698 23.1843 22.8704 23.0354 22.8026 22.8716C22.7348 22.7077 22.6999 22.5321 22.7 22.3547C22.7 22.1774 22.735 22.0018 22.803 21.838C22.8709 21.6741 22.9704 21.5253 23.0958 21.4C23.2213 21.2746 23.3702 21.1752 23.5341 21.1074C23.6979 21.0395 23.8735 21.0047 24.0509 21.0047C24.409 21.0049 24.7525 21.1472 25.0057 21.4006C25.2588 21.6539 25.401 21.9975 25.4009 22.3556C25.4008 22.7138 25.2584 23.0572 25.005 23.3104C24.7517 23.5636 24.4081 23.7058 24.05 23.7056Z"
      fill="white"
    />
  </svg>
);

export const CreditsSpentIcon = () => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20.5" cy="20.5" r="20" fill="#8A18D0" />
    <g clip-path="url(#clip0_580_9063)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.826 14.0199C26.543 13.7619 27.236 14.4549 26.978 15.1712L22.448 27.7539C22.1668 28.5362 21.0665 28.5512 20.7635 27.7764L18.6418 22.3554L13.2208 20.2344C12.4468 19.9314 12.4618 18.8312 13.2433 18.5492L25.826 14.0192V14.0199Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_580_9063">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(11 12)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const BundleRunsIcon = () => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20.5" cy="20.5" r="20" fill="#8A18D0" />
    <g clip-path="url(#clip0_580_9078)">
      <path
        d="M20 13.5C24.1423 13.5 27.5 16.8578 27.5 21C27.5 25.1423 24.1423 28.5 20 28.5C15.8578 28.5 12.5 25.1423 12.5 21C12.5 16.8578 15.8578 13.5 20 13.5ZM17.723 18.4875L17.6833 18.8625L17.6607 19.1122L17.6292 19.5623L17.6112 19.9073L17.5977 20.286L17.5903 20.6978V21.1313L17.5977 21.543L17.6112 21.9225L17.6292 22.2675L17.6607 22.7175L17.714 23.265L17.7238 23.3415C17.7403 23.4793 17.7888 23.6113 17.8652 23.7271C17.9416 23.8429 18.0439 23.9393 18.1641 24.0087C18.2842 24.0781 18.4188 24.1186 18.5573 24.127C18.6958 24.1354 18.8344 24.1114 18.962 24.057L19.304 23.9055L19.7922 23.6737L20.0848 23.5268L20.4073 23.3588L20.7568 23.169L21.1302 22.9575L21.5 22.74L21.6733 22.635L21.9958 22.4325L22.2867 22.245L22.5433 22.0732L23.033 21.7282L23.1658 21.63C23.2769 21.5467 23.367 21.4386 23.4291 21.3144C23.4912 21.1902 23.5234 21.0532 23.5234 20.9143C23.5233 20.7754 23.4909 20.6385 23.4287 20.5143C23.3665 20.3902 23.2762 20.2822 23.165 20.199L22.8605 19.9763L22.6542 19.8315L22.4142 19.668L21.9905 19.392L21.6695 19.191L21.317 18.9795L20.9427 18.7635L20.5843 18.5648L20.249 18.3863L19.9415 18.2288L19.5365 18.0315L19.2072 17.88L19.0347 17.8035C19.011 17.7934 18.9873 17.7834 18.9635 17.7735C18.8359 17.7187 18.6972 17.6943 18.5585 17.7024C18.4198 17.7105 18.285 17.7507 18.1646 17.82C18.0442 17.8893 17.9416 17.9857 17.8649 18.1016C17.7883 18.2175 17.7397 18.3496 17.723 18.4875Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_580_9078">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(11 12)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const BundleCreatedIcon = () => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20.5" cy="20.5" r="20" fill="#8A18D0" />
    <g clip-path="url(#clip0_580_9091)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.0625 13.925C19.3476 13.7605 19.6709 13.6738 20 13.6738C20.3292 13.6738 20.6525 13.7605 20.9375 13.925L25.658 16.6505C25.9431 16.8151 26.1798 17.0518 26.3443 17.3368C26.5089 17.6218 26.5955 17.9452 26.5955 18.2743V23.7253C26.5955 24.0544 26.5089 24.3777 26.3443 24.6628C26.1798 24.9478 25.9431 25.1845 25.658 25.349L20.9375 28.0745C20.6525 28.2391 20.3292 28.3257 20 28.3257C19.6709 28.3257 19.3476 28.2391 19.0625 28.0745L14.342 25.349C14.057 25.1845 13.8203 24.9478 13.6558 24.6628C13.4912 24.3777 13.4045 24.0544 13.4045 23.7253V18.2743C13.4045 17.9452 13.4912 17.6218 13.6558 17.3368C13.8203 17.0518 14.057 16.8151 14.342 16.6505L19.0625 13.925ZM17.0143 18.41L21.3628 15.9028L22.8635 16.769L18.515 19.2763L17.0143 18.41Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_580_9091">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(11 12)"
        />
      </clipPath>
    </defs>
  </svg>
);

const Data = [
  {
    bundle_id: 1,
    title: "Test_Bundle_01",
    date_from: "01 Jun 2024",
    date_till: "01 Feb 2024",
    Categories: "1",
    Countries: "1",
    Platforms: "3",
    Brands: "3",
    Bundle_Cost: "20 Credits",
  },
  {
    bundle_id: 2,
    title: "Power Tools",
    date_from: "01 Jun 2024",
    date_till: "01 Feb 2024",
    Categories: "1",
    Countries: "1",
    Platforms: "2",
    Brands: "8",
    Bundle_Cost: "50 Credits",
  },
  {
    bundle_id: 3,
    title: "Test_Bundle_01",
    date_from: "01 Jun 2024",
    date_till: "01 Feb 2024",
    Categories: "1",
    Countries: "1",
    Platforms: "5",
    Brands: "3",
    Bundle_Cost: "80 Credits",
  },
  {
    bundle_id: 4,
    title: "Test_Bundle_01",
    date_from: "01 Jun 2024",
    date_till: "01 Feb 2024",
    Categories: "1",
    Countries: "1",
    Platforms: "2",
    Brands: "2",
    Bundle_Cost: "20 Credits",
  },
];
