
export const ProductTableData = [
    {
        brand: "Bosch",
        tableDatas: [
            {
                product: "Bosch Professional GSR 120-LI Cordless Drill Driver",
                min_p: 7350,
                max_p: 7500,
                avg_p: 7349.67,
                mrp: 30000,
                discount: 10,
                rating: 4.5,
                reviews: 100,
                stock: true,
                delivery_time: "2-3 days",
                seller: "Dynamic ECommerce",
                warranty: "1 year"
            },
            {
                product: "Bosch GRO 12V-35 Heavy Duty Cordless Rotary Tool",
                min_p: 7279,
                max_p: 7275,
                avg_p: 7281.33,
                mrp: 7279,
                discount: 12,
                rating: 3,
                reviews: 500,
                stock: true,
                delivery_time: "3-4 days",
                seller: "ARPANA ENTERPRISES",
                warranty: "2 years"
            },
            {
                product: "Bosch GBL 620-Watt Air Blower (Blue)",
                min_p: 3117,
                max_p: 3119,
                avg_p: 3118,
                mrp: 3118,
                discount: 10,
                rating: 5,
                reviews: 1000,
                stock: true,
                delivery_time: "2-3 days",
                seller: "S P KOTHARI & CO",
                warranty: "1 year"
            },
            {
                product: "Bosch GEX 125-1 AE Heavy Duty Electric Random Orbit Sander",
                min_p: 7150,
                max_p: 6999,
                avg_p: 7085.67,
                mrp: 6829,
                discount: 20,
                rating: 5,
                reviews: 300,
                stock: true,
                delivery_time: "4-5 days",
                seller: "Clicktech Retail",
                warranty: "1 year"
            },
            {
                product: "BOSCH GSB 10 RE Kit Power & Hand Tool Kit (100 Tools)",
                min_p: 2687,
                max_p: 3081,
                avg_p: 3245,
                mrp: 2687,
                discount: 10,
                rating: 4.4,
                reviews: 1050,
                stock: true,
                delivery_time: "2-3 days",
                seller: "INDITOOLS",
                warranty: "1/2 year"
            },
            {
                product: "BOSCH GBL620 Air Blower (Corded)",
                min_p: 3479,
                max_p: 4099,
                avg_p: 3545.86,
                mrp: 3103,
                discount: 10,
                rating: 4.8,
                reviews: 800,
                stock: true,
                delivery_time: "5-6 days",
                seller: "HomeIndustrySolutions",
                warranty: "1 year"
            },
            {
                product: "BOSCH GHO 6500 Corded Planer (0-9mm mm)",
                min_p: 9799,
                max_p: 7895,
                avg_p: 8736.6,
                mrp: 7540,
                discount: 15,
                rating: 3.8,
                reviews: 1000,
                stock: true,
                delivery_time: "2-3 days",
                seller: "DDE",
                warranty: "1 year"
            },
            // add more products for Flipkart
        ]
    },
    {
        brand: "Makita",
        tableDatas: [
            {
                product: "Makita 10mm Rotary Drill (M0600B 350W)",
                min_p: 2400,
                max_p: 2400,
                avg_p: 2441.3,
                mrp: 2524,
                discount: 15,
                rating: 5,
                reviews: 500,
                stock: true,
                delivery_time: "3-4 days",
                seller: "J J GALLERIA",
                warranty: "2 years"
            },
            {
                product: "Generic Makita 12 volts max Li-ion Cordless Driver Drill DF333DWYE",
                min_p: 11200,
                max_p: 11500,
                avg_p: 11350,
                mrp: 11167,
                discount: 10,
                rating: 4,
                reviews: 1000,
                stock: true,
                delivery_time: "2-3 days",
                seller: "KROST®-Tools Centre",
                warranty: "1 year"
            },
            {
                product: "Makita HS7600 Powerful 1",
                min_p: 11007,
                max_p: 11007,
                avg_p: 11007,
                mrp: 10750,
                discount: 20,
                rating: 5,
                reviews: 300,
                stock: true,
                delivery_time: "4-5 days",
                seller: "Clicktech Retail",
                warranty: "1 year"
            },
            {
                product: "Makita HR2230 22mm Rotary Hammer",
                min_p: 11555,
                max_p: 11888,
                avg_p: 11571,
                mrp: 10543,
                discount: 10,
                rating: 5,
                reviews: 1000,
                stock: true,
                delivery_time: "2-3 days",
                seller: "Clicktech Retail",
                warranty: "1 year"
            },
            {
                product: "Makita M0910B Angle Grinder 100mm",
                min_p: 3450,
                max_p: 3490,
                avg_p: 3646.33,
                mrp: 3450,
                discount: 10,
                rating: 4.3,
                reviews: 800,
                stock: true,
                delivery_time: "5-6 days",
                seller: "Mgmachine",
                warranty: "1/2 year"
            },
            {
                product: "MAKITA M0600B MO600B Pistol Grip Drill (10 mm Chuck Size)",
                min_p: 2190,
                max_p: 2243,
                avg_p: 2551,
                mrp: 2190,
                discount: 10,
                rating: 4.5,
                reviews: 1000,
                stock: true,
                delivery_time: "2-3 days",
                seller: "MACHINERYSOLUTION",
                warranty: "1 year"
            },
            {
                product: "MAKITA M0600B MO600B Pistol Grip Drill (10 mm Chuck Size)",
                min_p: 3808,
                max_p: 3808,
                avg_p: 3808,
                mrp: 3808,
                discount: 10,
                rating: 4.8,
                reviews: 1000,
                stock: true,
                delivery_time: "2-3 days",
                seller: "DDE",
                warranty: "1 year"
            },
            // add more products for Amazon
        ]
    },
    {
        brand: "Dewalt",
        tableDatas: [
            {
                product: "DEWALT D25893K-IN L-Shaped SDS-Max 10Kg Demolition Hammer",
                min_p: 34110,
                max_p: 36164,
                avg_p: 35137,
                mrp: 33500,
                discount: 20,
                rating: 3.5,
                reviews: 300,
                stock: true,
                delivery_time: "4-5 days",
                seller: "EToolsmyne",
                warranty: "1/2 year"
            },
            {
                product: "DEWALT DW47401M-IN 4'/100 mm Continuous Diamond Marble Cutting Blade",
                min_p: 237,
                max_p: 237,
                avg_p: 237,
                mrp: 237,
                discount: 10,
                rating: 3.5,
                reviews: 1000,
                stock: true,
                delivery_time: "2-3 days",
                seller: "AlifTradingCompany",
                warranty: "1 year"
            },
            {
                product: "DEWALT DW801-IN01 Heavy Duty Small Angle Grinder Engineered For Heavy Duty Applications",
                min_p: 3200,
                max_p: 3451,
                avg_p: 3383.33,
                mrp: 2849,
                discount: 10,
                rating: 4.3,
                reviews: 800,
                stock: true,
                delivery_time: "5-6 days",
                seller: "N N POWER TOOLS",
                warranty: "1 year"
            },
            {
                product: "DEWALT DW810-IN Angle Grinder (100 mm Wheel Diameter)",
                min_p: 3899,
                max_p: 4800,
                avg_p: 3870,
                mrp: 3105,
                discount: 10,
                rating: 4.8,
                reviews: 1000,
                stock: true,
                delivery_time: "2-3 days",
                seller: "RetailNet",
                warranty: "1 year"
            },
            {
                product: "DEWALT DCV100-XJ Forward Curved Hi-Powered Blower (Cordless)",
                min_p: 6363,
                max_p: 6363,
                avg_p: 6363,
                mrp: 6363,
                discount: 15,
                rating: 4.8,
                reviews: 500,
                stock: true,
                delivery_time: "3-4 days",
                seller: "RetailNet",
                warranty: "2 years"
            },
            {
                product: "DEWALT DCF850N-XJ Cordless Impact Wrench (1/4 inch)",
                min_p: 3620,
                max_p: 3500,
                avg_p: 3560,
                mrp: 3620,
                discount: 10,
                rating: 3.9,
                reviews: 1000,
                stock: true,
                delivery_time: "2-3 days",
                seller: "Unicorn Engineering",
                warranty: "1 year"
            },
            {
                product: "D28870 DEWALT CHOP SAW",
                min_p: 11500,
                max_p: 11500,
                avg_p: 11500,
                mrp: 11500,
                discount: 20,
                rating: 4.0,
                reviews: 300,
                stock: true,
                delivery_time: "4-5 days",
                seller: "Indiamart",
                warranty: "1/2 year"
            },
            {
                product: "DEWALT 12' MITER SAW DWS780",
                min_p: 49500,
                max_p: 49500,
                avg_p: 49500,
                mrp: 49500,
                discount: 10,
                rating: 4.5,
                reviews: 1000,
                stock: true,
                delivery_time: "2-3 days",
                seller: "Indiamart",
                warranty: "1 year"
            },
            {
                product: "DRILL D25033K DEWALT POWER TOOLS",
                min_p: 9490,
                max_p: 9490,
                avg_p: 9490,
                mrp: 9490,
                discount: 10,
                rating: 4.3,
                reviews: 800,
                stock: true,
                delivery_time: "5-6 days",
                seller: "Indiamart",
                warranty: "1 year"
            },
            {
                product: "DEWALT 5 AH BLACK AND YELLOW CORDLESS IMPACT DRIVER (BARE TOOL)",
                min_p: 11800,
                max_p: 11800,
                avg_p: 11800,
                mrp: 11800,
                discount: 10,
                rating: 4.5,
                reviews: 1000,
                stock: true,
                delivery_time: "2-3 days",
                seller: "Indiamart",
                warranty: "1 year"
            },
            // add more products for Ebay
        ]
    },
];
