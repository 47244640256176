import { Box } from '@mui/material';
import { GridPagination } from '@mui/x-data-grid';
import React from 'react';

function DataGridPagination() {
  return (
    <Box>
      <GridPagination />
    </Box>
  );
}

export default DataGridPagination;
