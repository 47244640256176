import { Box } from "@mui/material"
import OurPlans from "../home/private-components/screens/OurPlans"
import ComparePlans from "../home/private-components/screens/ComparePlans"
import { Logo } from "../../assets/logo"
import { Link } from "react-router-dom"


export const Pricing = () => {
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ py: 6, width: { xs: '95%', md: '80%' } }}>
                    <Link to={'/'}>
                        <Logo />
                    </Link>
                </Box>
            </Box>
            <OurPlans />
            <ComparePlans />
        </Box>
    )
}