import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import TabComponent from "../components/TabComponent";



const FourthPage = () => {
    return (
        <Box sx={{ pt: { xs: 6, sm: 9 }, pb: 6, display: 'flex', flexDirection: 'column', alignItems: { xs: 'center' } }}>
            <Box sx={{ textAlign: 'center', width: { xs: '95%', sm: '100%' }, pb: 10 }}>
                <Typography sx={{ fontSize: { xs: '28px', sm: '32px' }, fontWeight: '600', py: 2 }}>Work with data you can trust</Typography>
                {/* <Typography sx={{ fontSize: '20px', fontWeight: '400', py: 2 }}>Assign roles and responsibilities</Typography>
                <Button sx={{ background: '#fff', borderRadius: '40px', textTransform: 'capitalize', height: '40px', py: 1, mb: 4, px: 3, '&:hover': { background: '#fff' } }}>
                    <Link to={'/contact'}>
                        <Box sx={{ display: 'flex', alignItems: 'center', py: 2 }}>
                            <Typography sx={{ color: '#000000', fontWeight: '600', fontSize: '16px', pr: '7px' }}>Discover all Roles</Typography>
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_3_9270)">
                                    <rect x="0.5" width="20" height="20" rx="10" fill="#8A18D0" />
                                    <path d="M10.91 14.645L9.884 13.63L12.951 10.562H5.5V9.082H12.952L9.885 6.019L10.909 5L15.731 9.822L10.91 14.645Z" fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_3_9270">
                                        <rect x="0.5" width="20" height="20" rx="10" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </Box>
                    </Link>

                </Button> */}
            </Box>
            <TabComponent />
        </Box>
    );
}


export default FourthPage;