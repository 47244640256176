import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

export const ThirdPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: 0,
        paddingRight: isTablet ? '20px' : '21px',
        paddingLeft: isTablet ? '20px' : '5px', 
        maxWidth: '100%',
        textAlign: 'center',
        fontSize: '1rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: isTablet ? '50px' : isMobile ? '30px' : '100px',
          width: '100%',
          maxWidth: '1200px', 
        }}
      >
        <Box
          sx={{
            width: '100%',
            borderRadius: '12px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '20px',
            paddingX: '5px',
            paddingBottom: '20px',
            backgroundImage: "url('/public/frame-1000004617@3x.png')",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
            maxWidth: '100%',
            color: theme.palette.background.primary,
            fontSize: '1.5rem',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'center',
              justifyContent: 'space-around', 
              gap: isTablet ? '50px' : isMobile ? '30px' : '190px',
              maxWidth: '100%',
              flexWrap: isMobile ? 'nowrap' : 'wrap', 
            }}
          >
            <Box
              sx={{
                width: isMobile ? '100%' : isTablet ? '45%' : '150px', 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '12px',
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 600,
                  fontSize: isMobile ? '2rem' : isTablet ? '2.5rem' : '3rem',
                  lineHeight: 1.5,
                  textAlign: 'center',
                }}
              >
                10+
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 500,
                  fontSize: isMobile ? '1.5rem' : isTablet ? '1.75rem' : '2rem',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                Countries
              </Typography>
            </Box>

            <Box
              sx={{
                width: isMobile ? '100%' : isTablet ? '45%' : '150px', 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '12px',
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 600,
                  fontSize: isMobile ? '2rem' : isTablet ? '2.5rem' : '3rem',
                  lineHeight: 1.5,
                  textAlign: 'center',
                }}
              >
                15+
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 500,
                  fontSize: isMobile ? '1.5rem' : isTablet ? '1.75rem' : '2rem',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                Categories
              </Typography>
            </Box>

            <Box
              sx={{
                width: isMobile ? '100%' : isTablet ? '45%' : '150px', 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '12px',
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 600,
                  fontSize: isMobile ? '2rem' : isTablet ? '2.5rem' : '3rem',
                  lineHeight: 1.5,
                  textAlign: 'center',
                }}
              >
                20+
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 500,
                  fontSize: isMobile ? '1.5rem' : isTablet ? '1.75rem' : '2rem',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                E-Commerce Sites
              </Typography>
            </Box>

            <Box
              sx={{
                width: isMobile ? '100%' : isTablet ? '45%' : '150px', 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '12px',
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 600,
                  fontSize: isMobile ? '2rem' : isTablet ? '2.5rem' : '3rem',
                  lineHeight: 1.5,
                  textAlign: 'center',
                }}
              >
                80+
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 500,
                  fontSize: isMobile ? '1.5rem' : isTablet ? '1.75rem' : '2rem',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                Brands
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};