import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { LayoutWrapper } from './LayoutWrapper';

const brands = [
    "Activa", "Agaro", "Ambrane", "Anker", "AO Smith", "Apple", "Asus", 
    "Bajaj", "Boat", "Boldfit", "Bosch", "Crompton", "Dell", "Dewalt", 
    "Duracell", "Elca", "Eureka Forbes", "Faber", 
    "Fastrack", "Honey Well", "HP", "Ibell", "IFB", "JBL", "Karcher", "Lenovo", 
    "LG", "Lifelong", "Makita", "MI", "Noise", "OnePlus", "Philips", 
    "Photronics", "Redmi", "Samsung", "Sharp", "Sony", "Titan", "V-Guard", 
    "Venom", "Whirlpool"
];

export default function Brands() {
    return (
      <LayoutWrapper
        title="Available Brands"
        subtitle="Explore and select from a wide range of brands to tailor your data extraction."
        data={brands}
        renderItem={(brand) => (
          <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 500, fontSize: '16px' }}>
            {brand}
          </Typography>
        )}
      />
    );
  }
