import { Box, Button, Typography } from "@mui/material";
import { dateClnse } from "../Public/DateClnse";
import { ConfigStyleBar } from "../../utils/data/IndividualDashboard/Data";
import { generateTableData } from "../../pages/DataSelection/Private";
import { TableComp6 } from "../table/type - 6";
import { PlaySvg2 } from "../../utils/icon/IndividualPages/Icon";
import { Card_2 } from "../card/Bundle-Card";


export const Bundle = ({ e, ChangeModelData, BndlePops }) => {
    return (
        <Box sx={{ mb: 10 }}>
            <Box
                sx={{
                    width: "100%",
                    my: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ display: "flex", flexDirection: { xs: 'column', md: 'row' }, alignItems: "center" }}>
                    <Typography
                        sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            mr: 3,
                            color: "#000",
                        }}
                    >
                        {e.ind_bundle_name}
                    </Typography>
                    <Box sx={{ display: { xs: 'none', md: "flex" }, flexDirection: { xs: 'column', sm: 'row' } }}>
                        <Typography
                            sx={{
                                p: 1,
                                border: "0.8px solid #474747CC",
                                borderRadius: "20px",
                                px: 1,
                                color: "#474747CC",
                                minWidth: "200px",
                                mr: { xs: 0, sm: 1 },
                                my: { xs: 1, sm: 0 },
                            }}
                        >
                            Data From : {dateClnse(e.ind_bundle_start_date)}
                        </Typography>
                        <Typography
                            sx={{
                                p: 1,
                                border: "0.8px solid #474747CC",
                                color: "#474747CC",
                                borderRadius: "20px",
                                minWidth: "200px",
                                ml: { xs: 0, sm: 1 },
                                my: { xs: 1, sm: 0 },
                            }}
                        >
                            Data Till : {dateClnse(e.ind_bundle_end_date)}
                        </Typography>
                    </Box>
                </Box>
                {!e.ind_bundle_run ? (
                    <Button
                        sx={{
                            background: "#8A18D0",
                            px: 2,
                            color: "#fff",
                            textTransform: "capitalize",
                            "&:hover": { background: "#B37FFC" },
                        }}
                        // onClick={() => RunBundle(e.ind_bundle_id)}
                        onClick={() => { BndlePops({ x: 0, ec: e.card_datas[0].value, id: e.ind_bundle_id }) }}
                    >
                        <PlaySvg2 />
                        <Typography
                            sx={{ ml: 2, fontWeight: "600", fontSize: "10px" }}
                        >
                            Run Bundle
                        </Typography>
                    </Button>
                ) : (
                    <Button
                        sx={{
                            background: "#8A18D0",
                            px: 2,
                            color: "#fff",
                            textTransform: "capitalize",
                            "&:hover": { background: "#B37FFC" },
                        }}
                        onClick={() => { if (e.ind_bundle_download_limit === "0") { BndlePops({ x: 1, ec: 0, id: e.ind_bundle_id }) } else { console.log('dl', e.ind_bundle_download_limit); BndlePops({ x: 2, ec: 0, id: e.ind_bundle_id }) } }}
                    >
                        <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.5 10L4.375 6.875L5.25 5.96875L6.875 7.59375V2.5H8.125V7.59375L9.75 5.96875L10.625 6.875L7.5 10ZM3.75 12.5C3.40625 12.5 3.11208 12.3777 2.8675 12.1331C2.62292 11.8885 2.50042 11.5942 2.5 11.25V9.375H3.75V11.25H11.25V9.375H12.5V11.25C12.5 11.5938 12.3777 11.8881 12.1331 12.1331C11.8885 12.3781 11.5942 12.5004 11.25 12.5H3.75Z"
                                fill="white"
                            />
                        </svg>
                        <Typography
                            sx={{ ml: 2, fontWeight: "600", fontSize: "10px" }}
                        // onClick={() => DownloadBundle(e.ind_bundle_id)}
                        >
                            Download
                        </Typography>
                    </Button>
                )}
            </Box>
            <Typography sx={{ fontSize: '14px', fontWeight: '500', my: 1, color: '#474747CC' }}>{e.ind_bundle_run ? `Note: You have ${e.ind_bundle_download_limit} free downloads left` : 'Please Run the Bundle'}</Typography>
            <Box sx={{ display: "flex", justifyContent: { xs: 'center', md: 'space-between' }, width: '100%', flexWrap: { xs: 'wrap', md: 'nowrap' }, my: 2 }}>
                {e.card_datas.map((f, j) => (
                    <Card_2
                        key={j}
                        data={{
                            icon: f.icon,
                            title: f.title,
                            value: f.value,
                            // wd: ``,
                            wd: `${(100 / e.card_datas.length) - 0.8}%`,
                        }}
                    />
                ))}
            </Box>
            <TableComp6
                Data={generateTableData(
                    e.table.Category,
                    e.table.Country,
                    e.table["E-Commerce Site"],
                    e.table.Brand,
                    e.table["Configured Date"]
                )}
                Style={ConfigStyleBar}
                bundle_id={e.ind_bundle_id}
                rb={e.ind_bundle_run}
                ChangeModelData={ChangeModelData}
            />
        </Box>
    );
}