import { Box, Button, Pagination, TextField, Typography } from "@mui/material";
import { TableComp5 } from "../../components/table/type - 5";
import { RechargeCreditsStyle } from "../RechargeCredits";
import { useEffect, useState } from "react";
import EastIcon from "@mui/icons-material/East";
import { SearchIcon } from "../IndividualUserLandingPage";
import http from "../../utils/http-common";
import { BundleRunLogStyle } from "../../utils/data/IndividualDashboard/Data";

export const Bundle_Run_Log = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setTableData] = useState([]);

  const FetchDatas = async () => {
    try {
      const response = await http.post(
        "/master_service/bundle/run/log",
        JSON.stringify({ user_id: localStorage.getItem("user_id") })
      );
      setTableData(response.data.data);
    } catch (error) {
      console.log("fetching error", error);
    }
  };

  useEffect(() => {
    FetchDatas();
  }, []);

  while (tableData.length < 7) {
    tableData.push({
      "Bundle Run Log Id": "",
      "Bundle Name": "",
      "Bundle Date": "",
      "Bundle Date": "",
      "Bundle Time": "",
      "Credit Cost": "",
      "Ind Bundle Id": "",
    });
  }

  const totalRows = tableData.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, totalRows);

  // Slice data for current page
  const paginatedData = tableData.slice(startIndex, endIndex);
  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <Box sx={{ px: { xs: 1, md: 4 }, py: { xs: 1, md: 2 }, height: "calc(100vh-64px)" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "20px",
            py: { xs: 1, md: 3 },
            background: "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Bundle Run Log
        </Typography>
        {/* <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid #797F8F',
                        borderRadius: '4px',
                        padding: '0.5rem 1rem',
                        // backgroundColor: '#f9f9f9',
                        width: '100%',
                        height: '35px',
                        maxWidth: '350px'
                    }}
                >
                    <SearchIcon sx={{ color: '#757575', marginRight: '0.5rem' }} />
                    <TextField
                        sx={{
                            flex: 1,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: 'none',
                                    height: '35px'
                                },
                                '&:hover fieldset': {
                                    border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                            },
                        }}
                        placeholder="Search bundle..."
                        variant="outlined"
                    // value={searchTerm}
                    // onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </Box> */}
      </Box>
      <Box sx={{ overflow: "auto", borderTopLeftRadius: '15px', border: "1px solid #E1E1E1", borderTopRightRadius: '15px', width: '100%' }}>
        <TableComp5 Data={paginatedData} Style={BundleRunLogStyle} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          mt: 2,
          // border: "1px solid #DADADA",
          marginTop: "0px",
          p: "10px",
          borderBottom: "1px solid #DADADA",
          borderRight: "1px solid #DADADA",
          borderLeft: "1px solid #DADADA",
          borderBottomRightRadius: "15px",
          borderBottomLeftRadius: "15px",
        }}
      >
        <Button
          variant="outlined"
          onClick={handlePreviousPage}
          disabled={page === 1}
          sx={{
            height: "30px",
            color: "#474747",
            display: { xs: 'none', sm: "flex" },
            px: 4,
            alignItems: "center",
            borderColor: "#DADADA",
            justifyContent: "space-evenly",
            textTransform: "capitalize",
            "&:hover": { borderColor: "#DADADA" },
            mr: 2,
          }}
        >
          <EastIcon sx={{ transform: "rotate(180deg)" }} />
          Previous
        </Button>
        <Pagination
          count={Math.ceil(tableData.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          siblingCount={1}
          size="small"
          hidePrevButton
          hideNextButton
          shape="rounded"
        />
        <Button
          variant="outlined"
          onClick={() => setPage((x) => x + 1)}
          disabled={page >= totalPages}
          sx={{
            height: "30px",
            color: "#474747",
            display: { xs: 'none', sm: "flex" },
            px: 4,
            alignItems: "center",
            borderColor: "#DADADA",
            justifyContent: "space-evenly",
            textTransform: "capitalize",
            "&:hover": { borderColor: "#DADADA" },
            ml: 2,
          }}
        >
          Next
          <EastIcon sx={{ ml: 1 }} />
        </Button>
      </Box>
    </Box>
  );
};

const data = [
  {
    S_No: 30,
    Bundle_Name: "Power Tools",
    Date: "21 Jul 2024",
    Time: "09:05:45 AM",
    Credit_Cost: "No Credits",
  },
  {
    S_No: 29,
    Bundle_Name: "Power Tools",
    Date: "21 Jul 2024",
    Time: "09:05:45 AM",
    Credit_Cost: "No Credits",
  },
  {
    S_No: 28,
    Bundle_Name: "Power Tools",
    Date: "21 Jul 2024",
    Time: "09:05:45 AM",
    Credit_Cost: "No Credits",
  },
  {
    S_No: 27,
    Bundle_Name: "Power Tools",
    Date: "21 Jul 2024",
    Time: "09:05:45 AM",
    Credit_Cost: "No Credits",
  },
  {
    S_No: 26,
    Bundle_Name: "Power Tools",
    Date: "21 Jul 2024",
    Time: "09:05:45 AM",
    Credit_Cost: "No Credits",
  },
  {
    S_No: 25,
    Bundle_Name: "Power Tools",
    Date: "21 Jul 2024",
    Time: "09:05:45 AM",
    Credit_Cost: "No Credits",
  },
  {
    S_No: 24,
    Bundle_Name: "Power Tools",
    Date: "21 Jul 2024",
    Time: "09:05:45 AM",
    Credit_Cost: "No Credits",
  },
  {
    S_No: 23,
    Bundle_Name: "Power Tools",
    Date: "21 Jul 2024",
    Time: "09:05:45 AM",
    Credit_Cost: "No Credits",
  },
  {
    S_No: 22,
    Bundle_Name: "Power Tools",
    Date: "21 Jul 2024",
    Time: "09:05:45 AM",
    Credit_Cost: "No Credits",
  },
];
