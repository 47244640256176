import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Pagination,
  Snackbar,
  Alert,
} from "@mui/material";
import { TableComp5 } from "../../components/table/type - 5/index.jsx";
import { CreditRequestStyle, Data } from "../../utils/data/AdminPage/Data.js";
import React, { useEffect, useState } from "react";
import EastIcon from "@mui/icons-material/East";
import http from "../../utils/http-common";

export const CreditRequest = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [tbl, setTbl] = useState([]);
  const [tblData, setTblData] = useState([]);
  const userId = localStorage.getItem("user_id");
  const Fetch_TableData = async () => {
    try {
      const response = await http.post("/master_service/credit_request");
      const Data2 = response.data.data;
      // Get the keys (labels) from the first entry in Data2
      const labels = Data2.length > 0 ? Object.keys(Data2[0]) : [];

      // Create a template object with empty values for each label
      const emptyRow = labels.reduce((acc, label) => {
        acc[label] = "";
        return acc;
      }, {});

      // Push empty rows until Data2 has at least 7 entries
      while (Data2.length < 7) {
        Data2.push({ ...emptyRow });
      }

      setTbl(response.data.data);
      setTblData(Data2);
      console.log(response.data);

      setMessage(response.data.message);
      setSeverity("success");
      handleClick();
    } catch (error) {
      console.error("Error fetching data:", error);
      setMessage(
        error.response
          ? error.response.data.message
          : "Unable to Fetch the table data."
      );
      setSeverity("error");
      handleClick();
    }
  };

  const MakePayment = async () => {
    try {
      const response = await http.post(
        "/master_service/payment",
        JSON.stringify({
          userid: userId,
          request_amt: price,
          applicable_credit: credits,
        })
      );

      console.log(response.data);

      setMessage(response.data.message);
      setSeverity("success");
      handleClick();
    } catch (error) {
      console.error("Error fetching data:", error);
      setMessage(
        error.response
          ? error.response.data.message
          : "Unable to Fetch the table data."
      );
      setSeverity("error");
      handleClick();
    }
  };

  useEffect(() => {
    Fetch_TableData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  while (Data.length < 10) {
    Data.push({
      Request_id: "",
      "Request Date": "",
      "User Id": "",
      "User Name": "",
      Amount: "",
      Credit: "",
      "Payment Type": "",
      "Transaction Number": "",
      "Payment Date": "",
      "Mail Sent": "",
      "Payment Status": "",
      "Grand Credit": "",
      "Ref document": "",
    });
  }

  const totalRows = tblData.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, totalRows);

  // Slice data for current page
  const paginatedData = tblData.slice(startIndex, endIndex);
  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <Box sx={{ p: 4, height: "100vh", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{ fontSize: "20px", fontWeight: "600", color: "#000000" }}
          >
            Credit Request
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              width: "160px",
              display: "flex",
              flexDirection: "column",
              my: "20px",
            }}
          >
            <FormControl sx={{ width: "100%" }}>
              <InputLabel
                sx={{
                  color: "#000000",
                  my: -1,
                  fontWeight: "400",
                  fontSize: "12px",
                }}
              >
                Payment Status
              </InputLabel>
              <Select
                label="Payment Status"
                sx={{
                  width: "100%",
                  height: "33px",
                  fontWeight: "400",
                  fontSize: "12px",
                }}
              >
                <MenuItem value={"paid"}>Paid</MenuItem>
                <MenuItem value={"notpaid"}>Not Paid</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box sx={{ overflow: "auto" }}>
        <TableComp5 Data={paginatedData} Style={CreditRequestStyle} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          mt: 2,
          // border: "1px solid #DADADA",
          marginTop: "0px",
          p: "10px",
          borderBottom: "1px solid #DADADA",
          borderRight: "1px solid #DADADA",
          borderLeft: "1px solid #DADADA",
          borderBottomRightRadius: "15px",
          borderBottomLeftRadius: "15px",
        }}
      >
        <Button
          variant="outlined"
          onClick={handlePreviousPage}
          disabled={page === 1}
          sx={{
            height: "30px",
            color: "#474747",
            display: "flex",
            px: 4,
            alignItems: "center",
            borderColor: "#DADADA",
            justifyContent: "space-evenly",
            textTransform: "capitalize",
            "&:hover": { borderColor: "#DADADA" },
            mr: 2,
          }}
        >
          <EastIcon sx={{ transform: "rotate(180deg)" }} />
          Previous
        </Button>
        <Pagination
          count={Math.ceil(tblData.length / rowsPerPage)}
          // count={Math.ceil(Data.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          hidePrevButton
          hideNextButton
          shape="rounded"
        />
        <Button
          variant="outlined"
          onClick={() => setPage((x) => x + 1)}
          disabled={page >= totalPages}
          sx={{
            height: "30px",
            color: "#474747",
            display: "flex",
            px: 4,
            alignItems: "center",
            borderColor: "#DADADA",
            justifyContent: "space-evenly",
            textTransform: "capitalize",
            "&:hover": { borderColor: "#DADADA" },
            ml: 2,
          }}
        >
          Next
          <EastIcon sx={{ ml: 1 }} />
        </Button>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ position: "absolute", mt: "38px" }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
