import { Box } from "@mui/material";
import { TopNav_2 } from "../../components/nav/top_nav_2";
import { Outlet } from 'react-router-dom';
import { useState } from "react";
import { SideNav_3 } from "../../components/nav/side_nav_3";
import { adminNavItms, adminRoutesMap, adminSideNav } from "../../utils/data/IndividualDashboard/Data";


export const Main_3 = () => {
    const [nav, setNav] = useState(0);
    const [subNav, setSubNav] = useState(-1);
    return (
        <Box sx={{ display: 'flex', height: '100vh', width: '100%' }}>
            <SideNav_3 nav={nav} subNav={subNav} setSubNav={setSubNav} setNav={setNav} Data={adminSideNav} routesMap={adminRoutesMap} commonPath={'/Admin/Dashboard'} />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <TopNav_2 setNav={setNav} Data={adminNavItms} />
                <Box sx={{ height: '100%', overflowY: 'auto' }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}