export const RefIcon = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3205_22013)">
      <path d="M2 1.16699H10.276L14 4.89099V8.50033H12.6667V6.50033H8.66667V2.50033H3.33333V14.5003H8V15.8337H2V1.16699ZM10 2.77633V5.16699H12.3907L10 2.77633ZM9.33333 10.8337C9.33333 10.3916 9.50893 9.96771 9.82149 9.65515C10.134 9.34259 10.558 9.16699 11 9.16699C11.442 9.16699 11.866 9.34259 12.1785 9.65515C12.4911 9.96771 12.6667 10.3916 12.6667 10.8337V13.8337H11.3333V10.8337C11.3333 10.7453 11.2982 10.6605 11.2357 10.598C11.1732 10.5354 11.0884 10.5003 11 10.5003C10.9116 10.5003 10.8268 10.5354 10.7643 10.598C10.7018 10.6605 10.6667 10.7453 10.6667 10.8337V13.8337C10.6667 14.1873 10.8071 14.5264 11.0572 14.7765C11.3072 15.0265 11.6464 15.167 12 15.167C12.3536 15.167 12.6928 15.0265 12.9428 14.7765C13.1929 14.5264 13.3333 14.1873 13.3333 13.8337V11.167H14.6667V13.8337C14.6667 14.5409 14.3857 15.2192 13.8856 15.7193C13.3855 16.2194 12.7072 16.5003 12 16.5003C11.2928 16.5003 10.6145 16.2194 10.1144 15.7193C9.61428 15.2192 9.33333 14.5409 9.33333 13.8337V10.8337Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_3205_22013">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>

);
export const LetterIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66668 13.8337C2.30001 13.8337 1.98623 13.7032 1.72534 13.4423C1.46445 13.1814 1.33379 12.8674 1.33334 12.5003V4.50033C1.33334 4.13366 1.46401 3.81988 1.72534 3.55899C1.98668 3.2981 2.30045 3.16744 2.66668 3.16699H13.3333C13.7 3.16699 14.014 3.29766 14.2753 3.55899C14.5367 3.82033 14.6671 4.1341 14.6667 4.50033V12.5003C14.6667 12.867 14.5362 13.181 14.2753 13.4423C14.0145 13.7037 13.7005 13.8341 13.3333 13.8337H2.66668ZM8.00001 9.16699L13.3333 5.83366V4.50033L8.00001 7.83366L2.66668 4.50033V5.83366L8.00001 9.16699Z"
      fill="#727272"
    />
  </svg>
);
