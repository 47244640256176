import {
  Box,
  Button,
  FormLabel,
  TextField,
  Typography,
  List,
  ListItem,
} from "@mui/material";
// import Textarea from '@mui/joy/Textarea'
import { useState } from "react";
import http from "../../utils/http-common";
import { Model_1 } from "../IndividualUserLandingPage";
import { Textarea } from "../../components/@extended/Textarea";
import { I_Icon, I2_Icon } from "../../utils/icon/IndividualPages/Icon";

export const Custom_Bundle = () => {
  const [open, setOpen] = useState(false);
  const [request_category, setRequest_Category] = useState("");
  const [request_country, setRequest_Country] = useState("");
  const [request_e_commerce, setRequest_E_commerce] = useState("");
  const [request_brand, setRequest_Brand] = useState("");
  const [user_description, setUser_Description] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTill, setDateTill] = useState("");
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;

    // Email validation
    if (!user_description) {
      tempErrors.user_description = "User Description is required.";
      isValid = false;
    } else if (user_description.length < 50) {
      tempErrors.user_description =
        "Description must be at least 50 characters long.";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const Clear = () => {
    setRequest_Category("");
    setRequest_Country("");
    setRequest_E_commerce("");
    setRequest_Brand("");
    setUser_Description("");
    setDateFrom("");
    setDateTill("");
  };
  const Req_Custom_Bundle = async () => {
    try {
      const response = await http.post(
        "/master_service/custom/bundle",
        JSON.stringify({
          user_id: localStorage.getItem("user_id") || "",
          request_category: request_category,
          request_country: request_country,
          request_e_commerce: request_e_commerce,
          request_brand: request_brand,
          request_message: user_description,
        })
      );
      console.log("res", response);
      setOpen(true);
    } catch (error) {
      console.error("Error creating contact us entry", error);
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      Req_Custom_Bundle();
    }
  };
  const ModelData = {
    title: "Custom Bundle",
    emoji: "",
    txt: [
      "We have received your Request.",
      "Our team will review and mail you with in 48 hours.",
    ],
    cl: "",
    // btn_1: 'Cancel',
    // btn_1_cancel: true,
    // btn_1_Link: '',
    btn_2: "Dashboard",
    btn_2_cancel: "",
    btn_2_Link: "/Dashboard/Individual-Dashboard",
    // btn_2_fn: Bundle_Creation
  };
  return (
    <Box
      sx={{
        background: "#fff",
        color: "#797F8F",
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        // minHeight: "550px",
        // width: "685px",
        // py: "116px",
        margin: "auto",
      }}
    >
      <Model_1 open={open} setOpen={setOpen} data={ModelData} />
      <Box sx={{ display: "flex", justifyContent: "center", width: { xs: '90%', md: '100%' }, margin: "auto" }}>
        <Box
          sx={{
            py: 4,
            borderTop: "1px solid #E4E4E4",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            borderBottom: "1px solid #E4E4E4",
            borderRight: "1px solid #E4E4E4",
            borderLeft: "1px solid #E4E4E4",
            borderTopLeftRadius: "12px",
            borderBottomLeftRadius: "12px",
            px: { xs: 2, md: 4 },
            borderTopRightRadius: "12px",
            borderBottomRightRadius: "12px",
            my: "20px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "20px",
              justifyContent: "center",
              color: "#8A17D0",
              pt: '4',
              my: "5px",
            }}
          >
            Custom Bundle
          </Typography>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "16px",
              justifyContent: "center",
              color: "#474747",
            }}
          >
            Please enter your configuration!!!
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: 'column', md: "row" },
              gap: { xs: '10px', md: "40px" },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", my: "10px" }}>
              <Box sx={{ display: "flex", alignItems: "center", mb: "5px" }}>
                <FormLabel sx={{ flexGrow: 1, color: "#000000" }}>
                  Category
                </FormLabel>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    "&:hover .model-1": {
                      display: "block",
                    },
                  }}
                >
                  <I_Icon />
                  <Box
                    sx={{
                      display: "none",
                      position: "absolute",
                      right: 0,
                      top: "100%",
                      zIndex: 1,
                    }}
                    className="model-1"
                  >
                    <Category_Model_1 />
                  </Box>
                </Box>
              </Box>
              <TextField
                placeholder="Enter Categories"
                value={request_category}
                onChange={(e) => setRequest_Category(e.target.value)}
                type="text"
                size="small"
                required
                sx={{
                  color: "#000000",
                  height: "32px",
                  width: { xs: '100%', md: '290px' },
                  borderRadius: "3px",
                  border: "0.8px",
                  my: "5px",
                  display: "flex",
                }}
              ></TextField>
            </Box>
            <Box
              sx={{
                height: "55px",
                width: { xs: '100%', md: '290px' },
                display: "flex",
                flexDirection: "column",
                my: "15px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: "5px" }}>
                <FormLabel
                  sx={{
                    flexGrow: 1,
                    color: "#000000",
                  }}
                >
                  Country
                </FormLabel>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",

                    "&:hover .model-1": {
                      display: "block",
                    },
                  }}
                >
                  <I_Icon />
                  <Box
                    sx={{
                      display: "none",
                      position: "absolute",
                      right: 0,
                      top: "100%",
                      zIndex: 1,
                    }}
                    className="model-1"
                  >
                    <Country_Model_1 />
                  </Box>
                </Box>
              </Box>
              <TextField
                placeholder="Enter Country"
                value={request_country}
                onChange={(e) => setRequest_Country(e.target.value)}
                type="text"
                size="small"
                required
                sx={{
                  color: "#000000",
                  height: "32px",
                  width: { xs: '100%', md: '290px' },
                  borderRadius: "3px",
                  border: "0.8px",
                  my: "1px",
                  display: "flex",
                }}
              ></TextField>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: 'column', md: "row" },
              my: "10px",
              gap: { xs: '10px', md: "40px" },
            }}
          >
            <Box
              sx={{
                height: "55px",
                width: { xs: '100%', md: '290px' },
                display: "flex",
                flexDirection: "column",
                my: "10px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: "5px" }}>
                <FormLabel
                  sx={{
                    flexGrow: 1,
                    color: "#000000",
                  }}
                >
                  E-Commerce Platform
                </FormLabel>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",

                    "&:hover .model-1": {
                      display: "block",
                    },
                  }}
                >
                  <I_Icon />
                  <Box
                    sx={{
                      display: "none",
                      position: "absolute",
                      right: 0,
                      top: "100%",
                      zIndex: 1,
                    }}
                    className="model-1"
                  >
                    <E_Commerce_Model_1 />
                  </Box>
                </Box>
              </Box>
              <TextField
                placeholder="Enter E-commerce platforms"
                value={request_e_commerce}
                onChange={(e) => setRequest_E_commerce(e.target.value)}
                type="text"
                size="small"
                required
                sx={{
                  color: "#000000",
                  height: "32px",
                  width: { xs: '100%', md: '290px' },
                  borderRadius: "3px",
                  border: "0.8px",
                  my: "0px",
                  display: "flex",
                }}
              ></TextField>
            </Box>
            <Box
              sx={{
                height: "55px",
                width: { xs: '100%', md: '290px' },
                display: "flex",
                flexDirection: "column",
                my: "10px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: "5px" }}>
                <FormLabel
                  sx={{
                    flexGrow: 1,
                    color: "#000000",
                  }}
                >
                  Brand
                </FormLabel>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",

                    "&:hover .model-1": {
                      display: "block",
                    },
                  }}
                >
                  <I_Icon />
                  <Box
                    sx={{
                      display: "none",
                      position: "absolute",
                      right: 0,
                      top: "100%",
                      zIndex: 1,
                    }}
                    className="model-1"
                  >
                    <Brand_Model_1 />
                  </Box>
                </Box>
              </Box>
              <TextField
                placeholder="Enter Brand"
                type="text"
                value={request_brand}
                onChange={(e) => setRequest_Brand(e.target.value)}
                size="small"
                required
                sx={{
                  color: "#000000",
                  height: "32px",
                  width: { xs: '100%', md: '290px' },
                  borderRadius: "3px",
                  border: "0.8px",
                  my: "0px",
                  display: "flex",
                }}
              ></TextField>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: 'column', md: "row" },
              my: "10px",
              gap: { xs: '10px', md: "40px" },
            }}
          >
            <Box
              sx={{
                height: "55px",
                width: { xs: '100%', md: '290px' },
                display: "flex",
                flexDirection: "column",
                my: "10px",
              }}
            >
              <FormLabel sx={{ color: "#000000" }}>Select Date Range</FormLabel>
              <TextField
                placeholder="Select Date"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
                type="date"
                size="small"
                required
                sx={{
                  color: "#000000",
                  height: "32px",
                  width: { xs: '100%', md: '290px' },
                  borderRadius: "3px",
                  border: "0.8px",
                  my: "5px",
                  display: "flex",
                }}
              ></TextField>
            </Box>
            <Box
              sx={{
                height: "55px",
                width: { xs: '100%', md: '290px' },
                display: "flex",
                flexDirection: "column",
                my: "10px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: "5px" }}>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    position: "relative",

                    "&:hover .model-1": {
                      display: "block",
                    },
                  }}
                >
                  <I_Icon />
                  <Box
                    sx={{
                      display: "none",
                      position: "absolute",
                      right: 0,
                      top: "100%",
                      zIndex: 1,
                    }}
                    className="model-1"
                  >
                    <Date_Model_1 />
                  </Box>
                </Box>
              </Box>
              <TextField
                placeholder="Select Date"
                type="date"
                value={dateTill}
                onChange={(e) => setDateTill(e.target.value)}
                size="small"
                required
                sx={{
                  color: "#000000",
                  height: "32px",
                  width: { xs: '100%', md: '290px' },
                  borderRadius: "3px",
                  border: "0.8px",
                  my: "10px",
                  display: "flex",
                }}
              ></TextField>
            </Box>
          </Box>
          {/* <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', my: '5px' }}>
                        <Box sx={{ height: '55px', width: '290px', display: 'flex', flexDirection: 'column', my: '10px' }}>
                            <FormLabel sx={{ color: '#000000' }}>Brand*</FormLabel>
                            <TextField placeholder="Enter Brand" type="text" size="small" required sx={{ color: '#000000', height: '32px', width: '290px', borderRadius: '3px', border: '0.8px', my: '10px', display: 'flex' }}></TextField>
                        </Box>
                    </Box> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              my: "10px",
            }}
          >
            <Box
              sx={{
                height: "190px",
                width: { xs: '100%', md: '620px' },
                display: "flex",
                flexDirection: "column",
                my: "10px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: "5px" }}>
                <FormLabel
                  sx={{
                    flexGrow: 1,
                    color: "#000000",
                  }}
                >
                  Describe Bundle*
                  <span style={{ color: "#797F8F", fontSize: "15px" }}>
                    (min 50 character)
                  </span>
                </FormLabel>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",

                    "&:hover .model-1": {
                      display: "block",
                    },
                  }}
                >
                  <I_Icon />
                  <Box
                    sx={{
                      display: "none",
                      position: "absolute",
                      right: 0,
                      top: "100%",
                      zIndex: 1,
                    }}
                    className="model-1"
                  >
                    <Description_Model_1 />
                  </Box>
                </Box>
              </Box>
              {/* <textarea placeholder="Describe Bundle here" value={user_description} onChange={(e) => setUser_Description(e.target.value)} style={{ width: '620px', height: '130px', borderRadius: '3px', border: '0.8px solid #797F8F80', color: '#BCBFC7', my: '10px' }} /> */}
              <Textarea
                Placeholder={"Describe Bundle here"}
                Value={user_description}
                required
                onChange={setUser_Description}
                error={!!errors.user_description}
                helperText={errors.user_description}
                Style={{ width: "100%", height: "130px" }}
              />
            </Box>
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", flexDirection: { xs: 'column', md: "row" }, gap: "12px" }}
          >
            <Button
              sx={{
                color: "#8A18D0",
                width: { xs: '100%', md: "120px" },
                height: "32px",
                border: "1px solid #8A18D0",
                textTransform: "capitalize",
              }}
              onClick={Clear}
            >
              Clear
            </Button>
            <Button
              sx={{
                background: "#8A18D0",
                color: "#ffffff",
                px: 4,
                textWrap: "nowrap",
                textTransform: "capitalize",
                height: "32px",
                "&:hover": { background: "#B37FFC" },
              }}
              onClick={() => handleSubmit()}
            >
              Send Request
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export const Category_Model_1 = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "9vh",
        width: "35vh",
        border: "1px solid #EAECF0",
        background: "#FFF6E5",
        borderRadius: "4px",
        p: "10px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "flex-start", mb: "8px" }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mr: "8px" }}>
          <I2_Icon />
        </Box>
        <Typography sx={{ color: "#000000", fontSize: "15px" }}>
          Note:
          <span style={{ color: "#797F8F", fontSize: "13px" }}>
            {" "}
            Enter required Categories Like Smart Phone, Watches, etc...
          </span>
        </Typography>
      </Box>
    </Box>
  );
};
export const Country_Model_1 = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "9vh",
        width: "35vh",
        border: "1px solid #EAECF0",
        background: "#FFF6E5",
        borderRadius: "4px",
        p: "10px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "flex-start", mb: "8px" }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mr: "8px" }}>
          <I2_Icon />
        </Box>
        <Typography sx={{ color: "#000000", fontSize: "15px" }}>
          Note:
          <span style={{ color: "#797F8F", fontSize: "13px" }}>
            {" "}
            Enter required Countries Like India, United States, etc...
          </span>
        </Typography>
      </Box>
    </Box>
  );
};
export const E_Commerce_Model_1 = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "9vh",
        width: "35vh",
        border: "1px solid #EAECF0",
        background: "#FFF6E5",
        borderRadius: "4px",
        p: "10px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "flex-start", mb: "8px" }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mr: "8px" }}>
          <I2_Icon />
        </Box>
        <Typography sx={{ color: "#000000", fontSize: "15px" }}>
          Note:
          <span style={{ color: "#797F8F", fontSize: "13px" }}>
            {" "}
            Enter required E-commerce sites Like Amazon, Flipkart, etc...
          </span>
        </Typography>
      </Box>
    </Box>
  );
};
export const Brand_Model_1 = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "9vh",
        width: "35vh",
        border: "1px solid #EAECF0",
        background: "#FFF6E5",
        borderRadius: "4px",
        p: "10px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "flex-start", mb: "8px" }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mr: "8px" }}>
          <I2_Icon />
        </Box>
        <Typography sx={{ color: "#000000", fontSize: "15px" }}>
          Note:
          <span style={{ color: "#797F8F", fontSize: "13px" }}>
            {" "}
            Enter required Brand Like Samsung, Apple, etc...
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export const Date_Model_1 = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "9vh",
        width: "48vh",
        border: "1px solid #EAECF0",
        background: "#FFF6E5",
        borderRadius: "4px",
        p: "10px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "flex-start", mb: "8px" }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mr: "8px" }}>
          <I2_Icon />
        </Box>
        <Typography sx={{ color: "#000000", fontSize: "15px" }}>
          Note:
          <span style={{ color: "#797F8F", fontSize: "13px" }}>
            {" "}
            Please choose a start and end date to get the data within that
            period.
          </span>
        </Typography>
      </Box>
    </Box>
  );
};
export const Description_Model_1 = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "9vh",
        width: "48vh",
        border: "1px solid #EAECF0",
        background: "#FFF6E5",
        borderRadius: "4px",
        p: "10px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "flex-start", mb: "8px" }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mr: "8px" }}>
          <I2_Icon />
        </Box>
        <Typography sx={{ color: "#000000", fontSize: "15px" }}>
          Example:
          <span style={{ color: "#797F8F", fontSize: "13px" }}>
            {" "}
            I need data from Amazon and Flipkart in Germany location
          </span>
        </Typography>
      </Box>
    </Box>
  );
};
