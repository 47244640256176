import { Box, Button, IconButton, Typography } from "@mui/material";
import { data } from "../../../utils/data/IndividualDashboard/Data";
import { DataComponent } from ".";
import SettingsIcon from '@mui/icons-material/Settings';

export const ConfigBar = ({ selectedData, minimize, setMinimize, }) => {
  return (
    <Box
      sx={{ height: "100%", width: "100%", p: 2, pr: 4, borderRadius: 'inherit', background: "#fff" }}
    >
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          {/* <Button disableRipple sx={{ textTransform: 'capitalize' }}> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ fontWeight: "600", fontSize: "14px", color: "#000" }}
            >
              Your Configurations
            </Typography>
            <Typography
              sx={{ fontWeight: "400", fontSize: "12px", color: "#797F8F" }}
            >
              You can view all your configurations here
            </Typography>
          </Box>
          {/* </Button> */}
          {/* <Button sx={{ background: '#8A18D0', color: '#fff', px: 4, '&:hover': { background: '#B37FFC' } }} onClick={() => setState(1)}>Bundle</Button> */}
          <IconButton sx={{ display: { xs: 'flex', md: 'none' } }} onClick={() => { setMinimize(!minimize) }}>
            <SettingsIcon />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ p: 2, border: "1px solid #BCBFC7", borderRadius: "4px" }}>
        <Typography
          sx={{
            pl: 1,
            fontWeight: "500",
            fontSize: "12px",
            py: 1,
            color: "#000",
          }}
        >
          Category Selected
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {selectedData[data[0].arrayName] ? (
              <Box
                sx={{
                  background: "#F3E7FA",
                  px: 2,
                  py: 1,
                  minWidth: "110px",
                  minHeight: "28px",
                  fontWeight: "500",
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textWrap: "nowrap",
                  mx: 1,
                  my: 1,
                }}
              >
                {selectedData[data[0].arrayName]}
              </Box>
            ) : (
              <Box
                sx={{
                  background: "#F3E7FA",
                  px: 2,
                  py: 1,
                  minWidth: "110px",
                  minHeight: "28px",
                  fontWeight: "500",
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textWrap: "nowrap",
                  mx: 1,
                  my: 1,
                }}
              >
                -
              </Box>
            )}
          </Box>
        </Box>
        <Typography
          sx={{
            pl: 1,
            fontWeight: "500",
            fontSize: "12px",
            py: 1,
            color: "#000",
          }}
        >
          Country Selected
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {selectedData[data[1].arrayName].length > 0 ? (
              <Box
                sx={{
                  background: "#F3E7FA",
                  px: 2,
                  py: 1,
                  minWidth: "110px",
                  minHeight: "28px",
                  fontWeight: "500",
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textWrap: "nowrap",
                  mx: 1,
                  my: 1,
                }}
              >
                {selectedData[data[1].arrayName]}
              </Box>
            ) : (
              <Box
                sx={{
                  background: "#F3E7FA",
                  px: 2,
                  py: 1,
                  minWidth: "110px",
                  minHeight: "28px",
                  fontWeight: "500",
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textWrap: "nowrap",
                  mx: 1,
                  my: 1,
                }}
              >
                -
              </Box>
            )}
          </Box>
        </Box>
        <Typography
          sx={{
            pl: 1,
            fontWeight: "500",
            fontSize: "12px",
            py: 1,
            color: "#000",
          }}
        >
          Site Selected
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {selectedData[data[2].arrayName].length > 0 ? (
              selectedData[data[2].arrayName].map((e, i) => (
                <Box
                  key={i}
                  sx={{
                    background: "#F3E7FA",
                    px: 2,
                    py: 1,
                    minWidth: "110px",
                    minHeight: "28px",
                    fontWeight: "500",
                    fontSize: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textWrap: "nowrap",
                    mx: 1,
                    my: 1,
                  }}
                >
                  {e}
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  background: "#F3E7FA",
                  px: 2,
                  py: 1,
                  minWidth: "110px",
                  minHeight: "28px",
                  fontWeight: "500",
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textWrap: "nowrap",
                  mx: 1,
                  my: 1,
                }}
              >
                -
              </Box>
            )}
          </Box>
        </Box>
        <Typography
          sx={{
            pl: 1,
            fontWeight: "500",
            fontSize: "12px",
            py: 1,
            color: "#000",
          }}
        >
          Brand Selected
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {selectedData[data[3].arrayName].length > 0 ? (
              selectedData[data[3].arrayName].map((e, i) => (
                <Box
                  key={i}
                  sx={{
                    background: "#F3E7FA",
                    px: 2,
                    py: 1,
                    minWidth: "110px",
                    minHeight: "28px",
                    fontWeight: "500",
                    fontSize: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textWrap: "nowrap",
                    mx: 1,
                    my: 1,
                  }}
                >
                  {e}
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  background: "#F3E7FA",
                  px: 2,
                  py: 1,
                  minWidth: "110px",
                  minHeight: "28px",
                  fontWeight: "500",
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textWrap: "nowrap",
                  mx: 1,
                  my: 1,
                }}
              >
                -
              </Box>
            )}
          </Box>
        </Box>
        {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button disableRipple sx={{ textTransform: "capitalize" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{ fontWeight: "600", fontSize: "14px", color: "#000" }}
              >
                Bundle Preview
              </Typography>
            </Box>
          </Button>
          <Button sx={{ background: '#8A18D0', color: '#fff', px: 4, '&:hover': { background: '#B37FFC' } }} onClick={() => setState(0)}>Configuration</Button>
        </Box> */}
        <Typography
          sx={{
            pl: 1,
            fontWeight: "500",
            fontSize: "12px",
            py: 1,
            color: "#000",
          }}
        >
          Bundle Preview
        </Typography>
        <Box>
          {/* {selectedData.map((data, index) => (
                        <Box key={index}>
                            <TableComp5
                                Data={generateData(data)}
                                Style={Style}
                            />
                        </Box>
                    ))} */}
          <DataComponent selectedData={selectedData} data={data} />
        </Box>
      </Box>
    </Box>
  );
};
