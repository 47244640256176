import { Button } from "@mui/material";
import { RefIcon, LetterIcon } from "../../icon/AdminPage/Icon.jsx";

export const CreditRequestStyle = {
  table: {
    borderRadius: "8px",
    maxWidth: "100%",
    my: 2,
    height: "100%",
    marginBottom: "0px",
  },
  head: {
    background: "#8A18D01A",
    borderRadius: "8px",
  },
  headRow: {},
  headCell: {
    fontWeight: "600",
    fontSize: "12px",
    color: "#2B2B2B",
  },
  body: {
    overflowY: "auto",
  },
  row: {
    height: "50px",
  },
  cell: {
    fontWeight: "500",
    fontSize: "11px",
    color: "#2B2B2B",
    // textAlign: 'center',
  },
  threeBtns: true,
  textFields: ["payment_type", "transaction_no", "payment_date"],
  sendCredit: {
    borderRadius: "2px",
    px: "12px",
    height: "20px",
    textWrap: "nowrap",
    minWidth: "56px",
    color: "#fff",
    textTransform: "capitalize",
    t: {
      background: "#8A18D0",
      "&:hover": { background: "#8a18d099" },
    },
    f: {
      background: "#727272",
    },
  },
  oddEvenColor: true,
};
export const Data = [
  {
    Request_id: "001",
    "Request Date": "16 Aug 2024",
    "User Id": "User_01",
    "User Name": "Srikanth",
    Amount: "20 dollars",
    Credit: "30",
    "Payment Type": "offline",
    "Transaction Number": "073512003212",
    "Payment Date": "16 aug 2024",
    "Mail Sent": "yes",
    "Payment Status": "Not Paid",
    // "Grand Credit": "Send Credit",
    // "Ref document": <Button disableRipple><RefIcon /></Button>,
    // "": <Button disableRipple><LetterIcon /></Button>,
  },
  {
    Request_id: "001",
    "Request Date": "16 Aug 2024",
    "User Id": "User_01",
    "User Name": "Srikanth",
    Amount: "20 dollars",
    Credit: "30",
    "Payment Type": "offline",
    "Transaction Number": "073512003212",
    "Payment Date": "16 aug 2024",
    "Mail Sent": "yes",
    "Payment Status": "Not Paid",
    // "Grand Credit": "Send Credit",
    // "Ref document": <Button disableRipple><RefIcon /></Button>,
    // "": <Button disableRipple><LetterIcon /></Button>,
  },
];
