import { alpha, Box, Button, InputBase, styled, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    height: '30px',
    marginLeft: 0,
    border: '1px solid #D0D5DD',
    width: '100%',
    display: 'flex', // Add this to make the input and icon align properly
    alignItems: 'center', // Add this to make the input and icon align properly
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0, // Add this to position the icon correctly
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        height: '100%',
        // [theme.breakpoints.up('md')]: {
        //     width: '20ch',
        // },
    },
}));


export const Filter = () => {
    return (
        <Box sx={{ display: 'flex', width: '50%' }}>
            <Box sx={{ width: '80%' }}>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </Search>
            </Box>
            <Button variant="outlined" sx={{ height: '30px', textTransform: 'none', display: 'flex', alignItems: 'center', borderColor: '#D0D5DD', color: '#344054' }}>
                <FilterListIcon />
                <Typography sx={{ pl: '7px' }}>Filter</Typography>
            </Button>
        </Box>
    );
}