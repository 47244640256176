import { createTheme } from '@mui/material/styles';
export const theme = createTheme({
  typography: {
    fontFamily: 'Inter Variable',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700
  }
})
export default theme;
