
export const Textarea = ({ Placeholder, Value, onChange, Style, error, helperText, }) => {
    return (
        <>
            <textarea placeholder={Placeholder} value={Value} onChange={(e) => onChange(e.target.value)} style={{
                borderRadius: '8px',
                border: `1px solid ${error ? 'red' : '#ccc'}`,
                padding: '10px',
                fontSize: '16px',
                lineHeight: '1.5',
                color: '#333',
                backgroundColor: '#fff',
                // boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
                transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
                outline: 'none',
                // resize: 'none',
                ...(Style ? { ...Style } : {})
            }} onFocus={(e) => {
                // e.target.style.borderColor = '#8A18D0';
                // e.target.style.boxShadow = '0 0 0 2px rgba(138, 24, 208, 0.25)';
            }} onBlur={(e) => {
                e.target.style.borderColor = '#ccc';
                e.target.style.boxShadow = 'inset 0 2px 4px rgba(0, 0, 0, 0.1)';
            }}></textarea>
            {error && (
                <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                    {helperText}
                </div>
            )}
        </>
    );
}