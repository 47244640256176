// import React from 'react';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import { Container } from '@mui/material';

// const colors = ['#1976d2', '#dc004e', '#388e3c']; // Define colors for each brand

// const BarChartComponent = ({ data }) => {
//     return (
//         <Container maxWidth="sm">
//             <ResponsiveContainer width="100%" height={300}>
//                 <BarChart
//                     data={data}
//                     margin={{ left: 40, top: 40, right: 40, bottom: 40 }} // Set margins
//                 >
//                     <CartesianGrid strokeDasharray="3 3" />
//                     <XAxis dataKey="brand" />
//                     <YAxis />
//                     <Tooltip />
//                     <Legend />

//                     {/* Bars for each brand */}
//                     {['Amazon', 'Flipkart', 'eBay', 'Indiamart'].map((brand, index) => (
//                         <Bar
//                             key={brand}
//                             dataKey={`total${brand}`} // Use dynamic dataKey based on brand
//                             fill={colors[index % 3]} // Use color from the defined color array
//                         />
//                     ))}
//                 </BarChart>
//             </ResponsiveContainer>
//         </Container>
//     );
// };

// const transformDataForChart = (data) => {
//     return data.map((brandData) => {
//         const brandObj = { brand: brandData.brand };
//         brandData.platforms.forEach((platform) => {
//             brandObj[`total${platform.name}`] = platform.numOfSellers;
//         });
//         return brandObj;
//     });
// };

// const BarChartWrapper = ({ data }) => {
//     const transformedData = transformDataForChart(data);

//     return <BarChartComponent data={transformedData} />;
// };

// export default BarChartWrapper;

// ---------------------------------------------------------------------------------------------------------------------------

// import React from 'react';
// import { BarChart, BarSeries, XAxis, YAxis, Grid, Tooltip, Legend } from '@mui/x-charts';
// import { Container } from '@mui/material';

// const colors = ['#1976d2', '#dc004e', '#388e3c']; // Define colors for each brand

// const BarChartComponent = ({ data }) => {
//     return (
//         <Container maxWidth="sm">
//             <BarChart
//                 data={data}
//                 height={300} // Set the height of the chart
//                 width={300} // Set the width of the chart
//                 margin={{ left: 40, top: 40, right: 40, bottom: 40 }} // Set margins
//             >
//                 <XAxis />
//                 <YAxis />
//                 <Grid />
//                 <Tooltip />
//                 <Legend />

//                 {/* Bar series for each brand */}
//                 {['Amazon', 'Flipkart', 'eBay', 'Indiamart'].map((brand, index) => (
//                     <BarSeries
//                         key={brand}
//                         name={brand}
//                         valueField={`total${brand}`} // Use dynamic value field based on brand
//                         argumentField="brand"
//                         color={colors[index % 3]} // Use color from the defined color array
//                     />
//                 ))}
//             </BarChart>
//         </Container>
//     );
// };

// const transformDataForChart = (data) => {
//     const transformedData = [];

//     data.forEach((brandData) => {
//         const brandObj = { brand: brandData.brand };
//         const totalAmazon = brandData.platforms.find((platform) => platform.name === 'Amazon').numOfSellers;
//         const totalFlipkart = brandData.platforms.find((platform) => platform.name === 'Flipkart').numOfSellers;
//         const totalEbay = brandData.platforms.find((platform) => platform.name === 'eBay').numOfSellers;
//         const totalIndiamart = brandData.platforms.find((platform) => platform.name === 'Indiamart').numOfSellers;

//         brandObj.totalAmazon = totalAmazon;
//         brandObj.totalFlipkart = totalFlipkart;
//         brandObj.totalEbay = totalEbay;
//         brandObj.totalIndiamart = totalIndiamart;

//         transformedData.push(brandObj);
//     });

//     return transformedData;
// };

// const BarChartWrapper = ({ data }) => {
//     const transformedData = transformDataForChart(data);

//     return <BarChartComponent data={transformedData} />;
// };

// export default BarChartWrapper;

import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Container } from '@mui/material';

const brandColors = {
    Bosch: '#1976d2',
    Dewalt: '#dc004e',
    Makita: '#388e3c',
};

const BarChartComponent = ({ data }) => {
    return (
        <Container maxWidth="sm">
            <ResponsiveContainer width="100%" height={500}>
                <BarChart
                    data={data}
                    margin={{ left: 40, top: 40, right: 40, bottom: 40 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="platform" />
                    <YAxis />
                    <Tooltip
                        formatter={(value, name, props) => [value, props.payload[`${name}-${props.dataKey}`]]}
                    />
                    <Legend />

                    {/* Bars for each brand */}
                    {['Bosch', 'Dewalt', 'Makita'].map((brand) => (
                        <Bar
                            key={brand}
                            dataKey={brand}
                            stackId="a"
                            fill={brandColors[brand]}
                            barSize={30} // Adjust the width of each bar
                        />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </Container>
    );
};

const transformDataForChart = (data) => {
    const transformedData = [];

    // Iterate over each platform
    ['Amazon', 'Flipkart', 'eBay', 'Indiamart'].forEach((platformName) => {
        // Initialize variables to store the total number of sellers for each brand
        let totalBosch = 0;
        let totalDewalt = 0;
        let totalMakita = 0;

        // Iterate over each brand's data and accumulate the number of sellers for the current platform
        data.forEach((brandData) => {
            const platform = brandData.platforms.find((platform) => platform.name === platformName);
            if (platform) {
                switch (brandData.brand) {
                    case 'Bosch':
                        totalBosch += platform.numOfSellers;
                        break;
                    case 'Makita':
                        totalMakita += platform.numOfSellers;
                        break;
                    case 'Dewalt':
                        totalDewalt += platform.numOfSellers;
                        break;
                    default:
                        break;
                }
            }
        });

        // Create an object for the current platform and add it to the transformed data array
        transformedData.push({
            platform: platformName,
            Bosch: totalBosch,
            Dewalt: totalDewalt,
            Makita: totalMakita,
        });
    });
    // console.log(transformedData)

    return transformedData;
};


const BarChartWrapper = ({ data }) => {
    const transformedData = transformDataForChart(data);

    return <BarChartComponent data={transformedData} />;
};

export default BarChartWrapper;
