import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { LayoutWrapper } from './LayoutWrapper';

const ecommerce_sites = [
    "Amazon", "Bigbasket", "Chennai Mobiles", "Costco", "Croma", "Currys", "Ebay", 
    "Flipkart", "India Mart", "Jio Mart", "Lulu Hypermarket", "Reliance Digital", 
    "Target", "The Home Depot", "Walmart"
];

export default function Ecommerce() {
    return (
      <LayoutWrapper
        title="Available Ecommerce Sites"
        subtitle="Choose from a range of popular e-commerce sites to tailor your data collection."
        data={ecommerce_sites}
        renderItem={(site) => (
          <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 500, fontSize: '16px' }}>
            {site}
          </Typography>
        )}
      />
    );
  }
