import {
    Alert,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Snackbar,
    TextField,
    Typography,
    Checkbox,
    List,
    ListItem,
} from "@mui/material";
import { Logo } from "../../../assets/logo";
import Carousel from "react-material-ui-carousel";
import { useEffect, useState } from "react";
import { CheckBox, Label } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from 'react-redux';
import http from "../../../utils/http-common";
import { Carousel_OG } from "../Components/Carousel";




export const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const navigate = useNavigate();

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };
    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const validateForm = () => {
        let tempErrors = {};
        let isValid = true;

        // Email validation
        if (!email) {
            tempErrors.email = "Email is required.";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            tempErrors.email = "Email address is invalid.";
            isValid = false;
        }

        // Password validation
        if (
            password.length < 8 ||
            !/[A-Z]/.test(password) ||
            !/[a-z]/.test(password) ||
            !/[0-9]/.test(password) ||
            !/[!@#$%^&*]/.test(password)
        ) {
            tempErrors.password = "Enter valid password";
            isValid = false;
        }
        setErrors(tempErrors);
        return isValid;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            Login();
        }
        //  else {
        //   setMessage("Please correct the highlighted errors.");
        //   setSeverity("error");
        //   setOpen(true);
        // }
    };

    const Login = async () => {
        try {
            const response = await http.post(
                "/auth_service/Login",
                JSON.stringify({
                    user_email: email,
                    user_password: password,
                })
            );

            console.log("res", response);

            if (response.status === 200) {
                setEmail("");
                setPassword("");
                setMessage(response.data.message);
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("user_id", response.data.user.user_id);
                localStorage.setItem("user_name", response.data.user.user_first_name);
                localStorage.setItem("email", response.data.user.user_email);
                setSeverity("success");
                handleClick();
                // navigate('/Dashboard/Individual-Dashboard');
                // window.location.reload();
                window.location.href = "/Dashboard/Individual-Dashboard";
            } else if (response.status === 400) {
                const t = true;
                localStorage.setItem("email", email);
                localStorage.setItem("otp", t);
                console.log("entered 400");
                setEmail("");
                setPassword("");
                setMessage(response.data.message);
                setSeverity("error");
                handleClick();
                navigate("/Otp");
            }
        } catch (error) {
            const errorMessage = error.response
                ? error.response.data.message
                : "Registration failed. Please try again.";
            if (error.response && error.response.status === 400) {
                localStorage.setItem("email", email);
                console.log("entered 400");
                setEmail("");
                setPassword("");
                setMessage(error.response.data.message);
                setSeverity("error");
                handleClick();
                navigate("/Otp");
            } else {
                console.error("There was an error registering the user!", error);
                setMessage(errorMessage);
                setSeverity("error");
                handleClick();
            }
        }
    };
    useEffect(() => {
        const tk = localStorage.getItem("token");
        if (tk !== null) {
            console.log("flikk", tk);
            navigate("/Dashboard/Individual-Dashboard");
        }
    }, []);

    return (
        <Box sx={{ display: "flex" }}>
            <Box
                sx={{
                    width: "50%",
                    height: "100vh",
                    display: { xs: "none", sm: "flex" },
                    flexDirection: "column",
                    background: "#000000",
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                    color: "#fff",
                    py: 4,
                    px: 5,
                }}
            >
                <Link to={"/"}>
                    <Logo />
                </Link>
                <Carousel_OG />
            </Box>
            <Box
                sx={{
                    width: { xs: "100%", sm: "50%" },
                    height: "90vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#ffffff",
                    color: "#474747",
                    py: 10,
                    px: 5,
                }}
            >
                <FormGroup sx={{ width: { xs: "100%", sm: "75%" } }}>
                    <Typography
                        sx={{
                            fontWeight: "700",
                            fontSize: "32px",
                            display: "flex",
                            my: "15px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: "600",
                                fontSize: "32px",
                                background:
                                    "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                            }}
                        >
                            Welcome Back
                        </Typography>{" "}
                        ✨
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: "#000000",
                            mb: "15px",
                        }}
                    >
                        Enter your login credentials here!!!
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "column", my: "20px" }}>
                        <FormLabel
                            sx={{ fontWeight: "500", fontSize: "14px", color: "#474747" }}
                        >
                            Email ID*
                        </FormLabel>
                        <TextField
                            size="small"
                            placeholder="Enter Email ID"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            type="email"
                            sx={{ color: "#AFAFAF", height: "30px" }}
                            error={!!errors.email}
                            helperText={errors.email}
                        ></TextField>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", my: "15px" }}>
                        <FormLabel
                            sx={{ fontWeight: "500", fontSize: "14px", color: "#474747" }}
                        >
                            Password*
                        </FormLabel>
                        <TextField
                            size="small"
                            placeholder="Enter password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            sx={{ color: "#AFAFAF", height: "30px" }}
                            error={!!errors.password}
                            helperText={errors.password || ""}
                        ></TextField>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            my: { xs: "30px", sm: "25px" },
                            mt: { xs: "30px", sm: "25px" },
                            // mt: !!errors.password
                            //   ? { xs: "30px", sm: "70px" }
                            //   : { xs: "30px", sm: "10px" },
                        }}
                    >
                        <FormControlLabel
                            sx={{ fontWeight: "400", fontSize: "12px", color: "#0F1419" }}
                            control={<Checkbox checked={checked} onChange={handleChange} />}
                            label="Remember Me"
                        />
                        <Link
                            to={"/Forgot-Password"}
                            sx={{
                                textDecoration: "none",
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "#0F1419",
                            }}
                        >
                            Forgot Password?
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            position: "relative",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Button
                            sx={{
                                color: "#fff",
                                background: "#8A18D0",
                                textTransform: "capitalize",
                                height: "43px",
                                my: "15px",
                                "&:hover": { background: "#B37FFC" },
                            }}
                            onClick={handleSubmit}
                        >
                            Login
                        </Button>
                        <Snackbar
                            open={open}
                            autoHideDuration={2000}
                            onClose={handleClose}
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            sx={{ position: "absolute", mt: "78px", width: "100%" }}
                        >
                            <Alert
                                onClose={handleClose}
                                severity={severity}
                                variant="filled"
                                sx={{ width: "100%" }}
                            >
                                {message}
                            </Alert>
                        </Snackbar>
                    </Box>
                    <Typography
                        sx={{
                            color: "#0F1419",
                            textAlign: "center",
                            fontWeight: "400",
                            fontSize: "12px",
                        }}
                    >
                        Don’t have an account?{" "}
                        <Link
                            to={"/Reg"}
                            style={{
                                fontWeight: "600",
                                fontSize: "12px",
                                position: "relative",
                                color: "#000000",
                            }}
                        >
                            Create free account
                            <span
                                style={{
                                    position: "absolute",
                                    left: 0,
                                    bottom: -1,
                                    width: "100%",
                                    height: "1px",
                                    backgroundColor: "#909090",
                                }}
                            ></span>
                        </Link>
                    </Typography>
                </FormGroup>
            </Box>
        </Box>
    );
};
