import { Box, Grid } from "@mui/material";
import {
  card1,
  card2,
  card3,
  card4,
} from "../../../../utils/data/LandingPage/Data";
import DisplayCard_01 from "../components/DisplayCard_01";

const ThirdPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: { md: "15px" },
        width: "100%",
      }}
    >
      <Box sx={{ width: { xs: "80%", md: "100%" } }}>
        <DisplayCard_01 Data={card1} mh="212px" />
        {/* <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: { xs: 'center', md: 'space-between' },
                        py: 3,
                        gap: { xs: 3, md: 0 },
                        // height: '558px'
                    }}
                > */}
        <Grid
          container
          spacing={3}
          sx={{
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { xs: "center", md: "space-between" },
            alignItems: "center",
            py: 3,
          }}
        >
          <Grid item xs={12} md={6} sx={{ width: { xs: "100%", md: "45%" } }}>
            <DisplayCard_01 Data={card2} mh="312px" />
          </Grid>
          <Grid item xs={12} md={6} sx={{ width: { xs: "100%", md: "45%" } }}>
            <DisplayCard_01 Data={card3} mh="312px" />
          </Grid>
        </Grid>
        {/* </Box> */}
        <DisplayCard_01 Data={card4} mh="212px" />
      </Box>
    </Box>
  );
};

export default ThirdPage;
