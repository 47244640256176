export const faq = [
  {
    title: "General Information",
    subTitle: [
      {
        question: "What is Brand Aura offering?",
        answer:
          "Brand Aura is a comprehensive data analytics platform for the E-Commerce space. It utilizes a crawler and manual system to collect and analyze data from various eCommerce sites, including product details, seller information, pricing trends, and customer reviews.",
      },
      {
        question: "How does Brand Aura collect data?",
        answer:
          "Brand Aura uses advanced web crawlers to scan and collect publicly available information from eCommerce websites. This data is then organized and made accessible through our platform.",
      },
      {
        question: "Who can use Brand Aura?",
        answer:
          "Brand Aura is designed for both individual users and corporate clients. Individual users can access data based on specific categories, brands, or regions, while corporate users benefit from an advanced online system for deeper insights into market trends and competitor activities.",
      },
      {
        question:
          "How does Brand Aura handle website crawling with respect to robots.txt?",
        answer:
          "Brand Aura respects the robots.txt file of every website we crawl. We only crawl sites where bots are explicitly allowed according to the robots.txt file and refrain from crawling sites where it is disallowed.",
      },
    ],
  },
  {
    title: "Data & Customization",
    subTitle: [
      {
        question: "What kind of data can I access with Brand Aura?",
        answer:
          "You can access various types of data, including product descriptions, pricing information, seller details, customer reviews, and more. Corporate users also get advanced insights into price trends,seller performance, and competitor analysis.",
      },
      {
        question:
          "How are the categories, brands, and locations chosen on Brand Aura?",
        answer:
          "Categories, brands, and locations are selected based on the most requested data from our customers. If you don't find the specific data you need, please contact us, and we can provide a custom solution tailored to your needs.",
      },
      {
        question: " Can Brand Aura provide customized solutions?",
        answer:
          "Yes, Brand Aura offers custom solutions beyond our standard offerings. This includes creating custom crawlers, manual extraction methods, and bespoke reporting solutions to meet your specific requirements.",
      },
      {
        question:
          "Can you extract competitors' product data, images, and pricing information?",
        answer:
          "Yes, we can extract product data, images, and pricing information from various eCommerce sites, including those of competitors as long as it is publicly available.",
      },
      {
        question: "What formats are available for data delivery?",
        answer: [
          "Brand Aura delivers data in multiple formats, including:",
          { point: ".CSV (comma-separated values)" },
          { point: ".JSON" },
          { point: ".TXT" },
          { point: ".XMLExcel (XLS)" },
        ],
      },
    ],
  },
  {
    title: "Product & Features",
    subTitle: [
      {
        question:
          " What roles can be assigned to organizational users in the product?",
        answer: [
          "In our product, you can assign the following roles to organizational users:",
          { point: "Marketing Executive" },
          { point: "Brand Manager" },
          { point: "Supply Chain Manager" },
          { point: "CRM Manager" },
          { point: "Sales Manager" },
        ],
      },
      {
        question: " Are there any specific notification/alerts for roles?",
        answer: [
          "Yes, specific notifications and alerts are available for different roles. For organizational users with the Marketing Manager role, the following alerts can be accessed:",
          { point: "Discount and Offers" },
          { point: "Price Drop" },
          { point: "Price Increased" },
          { point: "New Seller" },
          { point: "Stock Available" },
          { point: "Stock Unavailable" },
          { point: "New Product" },
          { point: "Bundle Offers" },
        ],
      },

      {
        question:
          "How many times can I download data from a bundle without using credits?",
        answer:
          "You can download data from a bundle up to three times without any credit charges. After the third download, credits will be required for additional downloads.",
      },
    ],
  },
  {
    title: "Privacy & Compliance",
    subTitle: [
      {
        question: "How does Brand Aura handle user data and privacy?",
        answer:
          "Brand Aura only maintains user data that is provided at the time of registration. We do not extract user-specific private data and adhere to high standards of data protection. We do not offer services related to user data management or privacy requirements.",
      },
      {
        question: " Is web scraping legal?",
        answer:
          "Yes, online data extraction from publicly available sources is generally considered legal. However, compliance with the terms and conditions of the websites being scraped and the robots.txt file is crucial. Brand Aura ensures that all practices adhere to legal and ethical standards.",
      },
      {
        question:
          "How does Brand Aura ensure compliance with legal requirements?",
        answer: [
          "Brand Aura uses both automated and data extraction methods. We are committed to adhering to all statutory and regulatory requirements, ensuring that the data we collect and provide complies with relevant laws and guidelines.",
        ],
      },
    ],
  },
  {
    title: "Pricing & Access",
    subTitle: [
      {
        question: "What is the pricing model for Brand Aura?",
        answer: [
          "For individual users, Brand Aura uses a pay as you go model where you can recharge credits to access data. Corporate users can choose from plans such as Silver, Gold, Platinum, and Custom, each offering various features and options. ",
          { text: "Pricing Details", url: "/contact" },
        ],
      },
      {
        question: "How do I get started with Brand Aura?",
        answer: [
          "Individual users can ",
          { text: "register", url: "/Reg" },
          " select their data preferences, and start downloading data. Corporate users should ",
          { text: "contact us", url: "/contact" },
          " for a demo and pricing options to access our full suite of features.",
        ],
      },
      {
        question:
          " What should I do if I can't find the specific data I'm looking for?",
        answer:
          "If you cannot find the specific data you need, please reach out to us. We will work with you to provide a custom solution tailored to your requirements.",
      },
      {
        question: " How secure is my data on Brand Aura?",
        answer:
          "We prioritize data security and use industry-standard encryption and protocols to protect your data.Your privacy is a top priority, and we ensure compliance with relevant data protection standards.",
      },
      {
        question: " Can I try Brand Aura before purchasing?",
        answer: [
          "Yes, we offer a ",
          { text: "free trial", url: "/Reg" },
          " for individual users to explore our platform and access a limited amount of data. Corporate users can ",
          { text: "request a demo", url: "/contact" },
          " to experience our full capabilities before committing to a plan.",
        ],
      },
      {
        question: " How can I contact support?",
        answer: [
          "You can reach our support team through the ",
          { text: "contact form", url: "/contact" },
          " on our website or by emailing support@brandaura.com",
        ],
      },
      {
        question: " How do I cancel my subscription?",
        answer:
          "Subscriptions can be canceled at any time by logging into your account and following the cancellation process in the account settings. Corporate users should contact their account manager for assistance.",
      },
      {
        question: "  Can Brand Aura be integrated with other tools?",
        answer:
          "Yes, Brand Aura can be integrated with various business intelligence and data analytics tools. For more details on integration options, please contact our technical support team.",
      },
    ],
  },
  {
    title: "Data Management & Delivery",
    subTitle: [
      {
        question: " How does Brand Aura handle large-scale data?",
        answer:
          "Brand Aura operates on a Data as a Service (DaaS) model, handling large-scale data operations and providing end-to-end data delivery. Clients benefit from our comprehensive service without needing to manage set-up or monitoring. Our solution is designed to assist clients who need to scale with data, addressing challenges with large-scale crawling and converting unstructured data into structured formats. Additionally, we offer a low turnaround time, with the capability to upload data every few minutes from highly active sites.",
      },
      {
        question: "Can Brand Aura deliver data in specific formats?",
        answer: [
          "Yes, Brand Aura can deliver the output in the format you need. The available formats include:",
          { point: ".CSV (comma-separated values)" },
          { point: ".JSON" },
          { point: ".TX" },
          { point: ".XML" },
          { point: "Excel(XLS)" },
        ],
      },
      {
        question:
          "What happens if crawling is disallowed in the robots.txt file?",
        answer:
          "Brand Aura respects the robots.txt file of websites and only crawls sites where bots are allowed according to the robots.txt file. We refrain from crawling sites where it is disallowed to avoid potential legal issues for both us and our clients.",
      },
      {
        question:
          "What happens when bots are allowed and data is provided to clients?",
        answer:
          "When bots are allowed and data is provided to clients, it is up to the clients to conform to the Terms of Service for the usage of that data.",
      },
      {
        question:
          " Can Brand Aura provide custom crawlers and manual extraction methods?",
        answer:
          "Yes, Brand Aura offers custom solutions beyond our standard offerings. This includes creating custom crawlers and manual extraction methods tailored to your specific needs, as well as bespoke reporting solutions.",
      },
    ],
  },
];
