
// import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
// import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import { brandIconMap } from '../../components/icons/index';

export const Items = [
    {
        country: 'India',
        itms: [
            {
                name: 'Bosch',
                imgLink: brandIconMap['BoschNav']
            },
            {
                name: 'Makita',
                imgLink: brandIconMap['MakitaNav']
            },
            {
                name: 'Dewalt',
                imgLink: brandIconMap['DewaltNav']
            }
        ]
    },
    {
        country: 'Germany',
        itms: [
            {
                name: 'Bosch',
                imgLink: brandIconMap['BoschNav']
            },
            {
                name: 'Makita',
                imgLink: brandIconMap['MakitaNav']
            },
            {
                name: 'Dewalt',
                imgLink: brandIconMap['DewaltNav']
            }
        ]
    },
    {
        country: 'United States',
        itms: [
            {
                name: 'Bosch',
                imgLink: brandIconMap['BoschNav']
            },
            {
                name: 'Makita',
                imgLink: brandIconMap['MakitaNav']
            },
            {
                name: 'Dewalt',
                imgLink: brandIconMap['DewaltNav']
            }
        ]
    },
    {
        country: 'United Kingdom',
        itms: [
            {
                name: 'Bosch',
                imgLink: brandIconMap['BoschNav']
            },
            {
                name: 'Makita',
                imgLink: brandIconMap['MakitaNav']
            },
            {
                name: 'Dewalt',
                imgLink: brandIconMap['DewaltNav']
            }
        ]
    },
    {
        country: 'World Wide',
        itms: [
            {
                name: 'Bosch',
                imgLink: brandIconMap['BoschNav']
            },
            {
                name: 'Makita',
                imgLink: brandIconMap['MakitaNav']
            },
            {
                name: 'Dewalt',
                imgLink: brandIconMap['DewaltNav']
            }
        ]
    }
]