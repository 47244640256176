import { PieChart } from '@mui/x-charts/PieChart';

export const Pie = ({ data }) => {
    return (
        <PieChart
            series={[
                {
                    data: data,
                },
            ]}
            width={700}
            height={400}
            // sx={{ height: '60%', width: '60%' }}
        />
    )
}
