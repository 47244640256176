/* eslint-disable eqeqeq */
import PropTypes from "prop-types";
import * as React from "react";
import Box from "@mui/material/Box";
import {
  GridActionsCellItem,
  GridToolbar,
  useGridApiRef,
} from "@mui/x-data-grid";
import ReactDOM from "react-dom";
import { Button, Switch, Tooltip } from "@mui/material";
import Iconify from "./Iconify";
import { StyledDataGrid, basePopperStyles, columnMenuStyles } from "./Style";
import DataGridTitle from "./DataGridTitle";
// import DataGridPagination from "./DataGridPagination";
// import { SelectField } from "../../components/newFrame/dynamic";
// import { TextBoxField } from "../../components/newFrame/dynamic";
// import { CheckboxField } from "../../components/newFrame/dynamic";
// import exit from "../../assets/assests/Vector (18).svg";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Webcam from "react-webcam";
import ImageIcon from "@mui/icons-material/Image";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
// import { DateField } from "../../components/newFrame/dynamic";
import { useEffect } from "react";
import { CheckboxField, DateField, SelectField, TextBoxField } from "./dynamic";
function DataGridTemplate(props) {
  const {
    columns,
    data,
    selection,
    onDelete,
    onEdit,
    hidden,
    onView,
    onGateOut,
    actions,
    imageSrc,
    setImageSrc,
    onSwitch,
    switchParams,
    switchParamss,
    title,
    hiddenUniqueId,
    handleAdd,
    createButtonTitle,
    componentVisible,
    setComponentVisible,
    setComponentData,
    setComponent,
    components,
    setAddRule,
    selectionButton,
    handleSelectionClick,
    printTitle,
    handleGate,
    selectedData,
    setSelectedData,
    access = {
      create: true,
      update: true,
      delete: true,
      switch: true,
      approve: true,
      reject: true,
      view: true,
      discard: true,
      gateOut: true,
    },
  } = props;
  const buttonStyles = {
    backgroundColor: "#02AC79",
    color: "white",
    "&:hover": {
      backgroundColor: "#02AC79",
    },
  };

  // const [selectedData, setSelectedData] = React.useState([]);
  const [showWebCam, setShowWebCam] = React.useState({});
  const [rowData, setRowData] = React.useState([]);

  React.useEffect(() => {
    setRowData([...data.map((e, i) => ({ ...e, dynamicId: i }))]);
  }, [data]);

  //const [rowData, setRowData] = React.useState([]);
  const webcamRef = React.useRef(null);
  // const [imageSrc, setImageSrc] = React.useState(null);
  const handleCaptureImage = (columnName, index) => {
    captureImage(columnName, index);
    setShowWebCam((prevImages) => ({
      ...prevImages,
      [`${columnName}-${index}`]: false,
    }));
  };
  const captureImage = (columnName, index) => {
    const imageSrc = webcamRef?.current?.getScreenshot();
    setImageSrc((prevImages) => ({
      ...prevImages,
      [`${columnName}-${index}`]: imageSrc,
    }));
  };

  const generateGridActionsCellItem = (
    params,
    key,
    icon,
    label,
    onClick,
    color,
    showInMenu
  ) => (
    <GridActionsCellItem
      key={key}
      icon={icon}
      label={label}
      onClick={() => onClick(params.row)}
      // sx={{ mr: "22px", ml: "10px", color }}
      showInMenu={showInMenu === false ? false : true}
    />
  );
  const getActions = {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    headerClassName: "customHeader",
    flex: columns.length > 10 ? 1 : 1,
    getActions: (params) => {
      const actions = [];
      components?.forEach((e) => {
        actions.push(
          <GridActionsCellItem
            key={e}
            showInMenu
            icon={
              <Button
                variant={"outlined"}
                type={"button"}
                color={"primary"}
                size={"small"}
                title={e.name}
                onClick={() => {
                  setComponentVisible({ ...componentVisible, [e.name]: true });
                  setComponent(e.name);
                  setComponentData({ ...params.row });
                  setAddRule(true);
                }}
              />
            }
            // onClick={() => onClick(params.row)}
            // sx={{ mr: '22px', ml: '10px', color }}
          />
        );
      });

      const addAction = (key, icon, label, onClick, color, showInMenu) => {
        if (access && access[key] && onClick) {
          actions.push(
            generateGridActionsCellItem(
              params,
              key,
              icon,
              label,
              onClick,
              color,
              showInMenu
            )
          );
        }
      };

      addAction(
        "switch",
        <Switch
          sx={{}}
          checked={params?.row?.[switchParams?.key] === switchParams?.value}
        />,
        "Status",
        onSwitch
      );
      addAction(
        "update",
        <Iconify icon={"solar:pen-bold"} sx={{ mr: "27px", ml: "4px" }} />,
        "Edit",
        onEdit
      );
      addAction(
        "delete",
        <Iconify
          icon={"solar:trash-bin-minimalistic-bold"}
          sx={{ mr: "22px", ml: "10px" }}
        />,
        "Delete",
        onDelete,
        "rgb(255, 86, 48)"
      );

      addAction(
        "view",
        <Iconify icon={"carbon:view"} sx={{ mr: "27px", ml: "4px" }} />,
        "View",
        onView
      );

      addAction(
        "gateOut",
        <Tooltip title='Gate Out'>
          <Iconify icon={"bitcoin-icons:exit-outline"} />,
        </Tooltip>,
        "Gate Out",
        onGateOut,
        "",
        false
      );

      return actions;
    },
  };

  const col = columns
    .map((e) => ({
      headerName:
        e.title === switchParams?.key ?? "hidden_status" ? "Status" : e.title,
      field: e.field,
      hide: e.hidden === true ? true : false,
      flex: columns.length > 10 ? 1 : 1,
      headerClassName: "customHeader",
      align: "center",
      headerAlign: "center",
      width: e.width,
      renderCell: (params) => {
        const { field, value } = params;

        if (typeof value === "object") {
          return JSON.stringify(value);
        }

        if (typeof value === "boolean") {
          return String(value);
        }

        if (field.startsWith("rule") && e.type === "text") {
          return (
            <TextBoxField
              field={field}
              id={e.name}
              name={e.name}
              label={e.title}
              value={e.value.key[params.row[e.value.value]]}
              onChange={(parameter) => {
                e.onChange(parameter, params.row);
              }}
            />
          );
        }
        if (e.type === "text") {
          return (
            <TextBoxField
              field={field}
              id={e.name}
              name={e.name}
              label={e.title}
              shrink={true}
              value={e.value.key[params.row[e.value.value]]}
              onChange={(parameter) => {
                e.onChange(parameter, params.row);
              }}
            />
          );
        }
        if (e.type === "date") {
          return (
            <DateField
              field={field}
              id={e.name}
              name={e.name}
              label={e.title}
              shrink={true}
              value={e.value.key[params.row[e.value.value]]}
              onChange={(parameter) => {
                e.onChange(parameter, params.row);
              }}
            />
          );
        }
        if (e.type === "upload") {
          return (
            <div>
              {!showWebCam?.[`${e.field}-${params.row.id}`] &&
                !imageSrc?.[`${e.field}-${params.row.id}`] && (
                  <FileUploadOutlinedIcon
                    onClick={() => {
                      setShowWebCam((prevImages) => ({
                        ...prevImages,
                        [`${e.field}-${params.row.id}`]: true,
                      }));
                    }}
                  />
                )}
              {showWebCam?.[`${e.field}-${params.row.id}`] && (
                <div>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat='image/jpeg'
                    style={{ width: "100%", height: "auto" }}
                    onClick={() => handleCaptureImage(e.field, params.row.id)}
                  />
                  {/* <button onClick={() => handleCaptureImage(e.field)}>
                    Capture
                  </button> */}
                </div>
              )}
              {imageSrc?.[`${e.field}-${params.row.id}`] && (
                <div style={{ position: "relative" }}>
                  <img
                    src={imageSrc?.[`${e.field}-${params.row.id}`]}
                    alt='Captured'
                    style={{ width: "100%", height: "auto" }}
                  />
                  <button
                    onClick={() => {
                      // Clear the captured image
                      setImageSrc((prevImages) => ({
                        ...prevImages,
                        [`${e.field}-${params.row.id}`]: null,
                      }));
                    }}
                    style={{
                      position: "absolute",
                      marginBottom: "1px",
                      left: "30px",
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}>
                    <ClearIcon />
                  </button>
                </div>
              )}
            </div>
          );
        }
        if (e.type === "view_image") {
          return <ImageIcon onClick={() => e.onClick(params)} />;
        }
        if (e.type === "addOrDelete") {
          return params.row.isDelete ? (
            <DeleteIcon onClick={() => e.onClick(params, "delete")} />
          ) : (
            <AddToPhotosIcon onClick={() => e.onClick(params, "add")} />
          );
        }
        if (e.type === "change_bin") {
          if (params.row?.[e.hidden]) {
            return (
              <Button
                variant='contained'
                style={buttonStyles}
                sx={{ margin: "20px" }}
                onClick={() => e.onClick(params)}>
                Change
              </Button>
            );
          }
          return null;
        }
        if (e.type === "delete") {
          return <DeleteIcon />;
        }
        if (field === "input" && e.type === "checkbox") {
          return (
            <CheckboxField
              checked={e.value.key[params.row[e.value.value]]}
              onChange={(parameter) => {
                e.onChange(parameter, params.row);
              }}
            />
          );
        }
        if (
          ["input", "input1", "input2", "input3"].includes(field) &&
          e.type === "singleSelect"
        ) {
          if (Array.isArray(e.valueOptions)) {
            return (
              <SelectField
                {...field}
                id={e.name}
                name={e.name}
                label={e.title}
                value={e.value.key[params.row[e.value.value]]}
                options={e.valueOptions}
                onChange={(parameter) => {
                  e.onChange(parameter, params.row);
                }}
              />
            );
          }
        }
        if (field.startsWith("status") && e.type === "singleSelect") {
          if (Array.isArray(e.valueOptions)) {
            return (
              <SelectField
                {...field}
                id={e.name}
                name={e.name}
                label={e.title}
                value={e.value.key[params.row[e.value.value]]}
                options={e.valueOptions}
                onChange={(parameter) => {
                  e.onChange(parameter, params.row);
                }}
              />
            );
          }
        }
        if (field.startsWith("action") && e.type === "singleSelect") {
          if (Array.isArray(e.valueOptions)) {
            return (
              <SelectField
                {...field}
                id={e.name}
                name={e.name}
                label={e.title}
                value={e.value.key[params.row[e.value.value]]}
                options={e.valueOptions}
                onChange={(parameter) => {
                  e.onChange(parameter, params.row);
                }}
              />
            );
          }
        }
        if (field === "gate_status") {
          const statusStyle = {
            padding: "4px 8px 4px 8px",
            backgroundColor:
              value === "Gate In"
                ? "#E9F6ED"
                : value === "Gate Out"
                  ? "#F6EFE9"
                  : "#FF974C",
            color:
              value === "Gate In"
                ? "#24A148"
                : value === "Gate Out"
                  ? "#DD7533"
                  : "#000000",
            borderRadius: "4px",
          };

          return (
            <div style={statusStyle}>
              {value === "Gate In"
                ? "Gate In"
                : value === "Gate Out"
                  ? "Gate Out"
                  : "Loaded"}
            </div>
          );
        }
        if (field === "attendance_status") {
          const statusStyle = {
            padding: "4px 8px 4px 8px",
            backgroundColor:
              value === "Approved"
                ? "#E9F6ED"
                : value === "In Progress"
                  ? "#F6EFE9"
                  : "#FF974C",
            color:
              value === "Approved"
                ? "#24A148"
                : value === "In Progress"
                  ? "#DD7533"
                  : "#000000",
            borderRadius: "4px",
          };

          return (
            <div style={statusStyle}>
              {value === "Approved"
                ? "Approved"
                : value === "In Progress"
                  ? "In Progress"
                  : "In Progress"}
            </div>
          );
        }
        if (
          field.toLowerCase() === "status1" ||
          field.toLowerCase() === "hidden_status1" ||
          field === switchParamss?.key
        ) {
          const statusStyle = {
            padding: "4px 8px 4px 8px",
            backgroundColor:
              value === "Approved" || value === "true" ? "#E9F6ED" : "#F6EFE9",
            color:
              value === "Approved" || value === "true" ? "#24A148" : "#DD7533",
            borderRadius: "4px",
          };

          return (
            <div style={statusStyle}>
              {value === "Approved" || value === "true"
                ? "Approved"
                : value === "Rejected" || value === "false"
                  ? "Rejected"
                  : "Pending"}
            </div>
          );
        }
        if (
          field.toLowerCase() === "status" ||
          field.toLowerCase() === "hidden_status" ||
          field === switchParams?.key
        ) {
          const statusStyle = {
            padding: "4px 8px 4px 8px",
            backgroundColor:
              value === "Active" || value === "true" ? "#E9F6ED" : "#F6EFE9",
            color:
              value === "Active" || value === "true" ? "#24A148" : "#DD7533",
            borderRadius: "4px",
          };

          return (
            <div style={statusStyle}>
              {value === "Active" || value === "true"
                ? "Active"
                : value === "Inactive" || value === "false"
                  ? "Inactive"
                  : "Draft"}
            </div>
          );
        }

        // ... (rest of the rendering logic)

        // Default case if none of the conditions match
        return value;
      },
    }))

    .filter(
      (e) =>
        e.headerName.toLowerCase() !== "id" && !e.headerName.includes("hidden")
    )
    .sort((a, b) => {
      const aIsStatus = a.headerName.toLowerCase().includes("status");
      const bIsStatus = b.headerName.toLowerCase().includes("status");

      return aIsStatus ? (bIsStatus ? 0 : 1) : bIsStatus ? -1 : 0;
    });
  const apiRef = useGridApiRef();

  const autosizeOptions = {
    includeHeaders: true,
    includeOutliers: true,
    expand: true,
  };

  /*  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      ReactDOM.flushSync(() => {
        apiRef.current.autosizeColumns(autosizeOptions);
      });
    }, 1000);
    return () => {
      clearInterval(timeoutId);
    };
  }, [rowData, apiRef, selectedData]); */

  return (
    <Box sx={{ width: "100%" }}>
      <StyledDataGrid
        dataLength={data.length}
        apiRef={apiRef}
        autosizeOnMount
        autosizeOptions={autosizeOptions}
        //getRowId={(row) => row.id}
        getRowId={(row) => row.dynamicId}
        slotProps={{
          basePopper: {
            sx: basePopperStyles,
          },
          columnMenu: {
            sx: columnMenuStyles,
          },
          footer: {
            sx: { display: "none" },
          },
        }}
        slots={{
          // pagination: DataGridPagination,
          toolbar: (gridToolbarProps) => (
            <DataGridTitle
              title={title}
              access={access}
              handleAdd={handleAdd}
              printTitle={printTitle}
              createButtonTitle={createButtonTitle}
              gridProps={<GridToolbar {...gridToolbarProps} />}
              selectedData={selectedData}
              selectionButton={selectionButton}
              handleSelectionClick={handleSelectionClick}
            />
          ),
        }}
        rows={rowData}
        columns={actions ? [...col, getActions] : [...col]}
        // columns={[...col, actions ? getActions : ""]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRowData = rowData.filter((row) =>
            selectedIDs.has(row.dynamicId)
          );

          setSelectedData([...selectedRowData]);
        }}
        pageSizeOptions={[10, 20, 50, 100]}
        checkboxSelection={selection}
        // disableRowSelectionOnClick
      />
    </Box>
  );
}

DataGridTemplate.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      field: PropTypes.string,
      hidden: PropTypes.bool,
    })
  ),
  data: PropTypes.array,
  selection: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onSwitch: PropTypes.func,
  switchParams: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  title: PropTypes.string,
  handleAdd: PropTypes.func,
  createButtonTitle: PropTypes.string,
};

export default DataGridTemplate;
