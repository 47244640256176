import Flipkart from '../../assets/brand_icons/Flipkart Logo.png'
import Amazon from '../../assets/brand_icons/Amazon Logo.png'
import Ebay from '../../assets/brand_icons/Ebay Logo.png'
import Indiamart from '../../assets/brand_icons/India Mart Logo.png'
import Bosch from '../../assets/brand_icons/Bosch Logo.png'
import Makita from '../../assets/brand_icons/Makita Logo.png'
import Dewalt from '../../assets/brand_icons/Dewalt Logo.png'
import BoschNav from '../../assets/brand_icons/Bosch Logo_left nav.png'
import MakitaNav from '../../assets/brand_icons/Makita Logo_left nav.png'
import DewaltNav from '../../assets/brand_icons/Dewalt Logo_left nav.png'
import Poor from '../../assets/emojis/Poor.png'
import Not_Bad from '../../assets/emojis/Not Bad.png'
import VG from '../../assets/emojis/Very Good.png'

export const brandIconMap = {
    'Flipkart': Flipkart,
    'Amazon': Amazon,
    'Ebay': Ebay,
    'Indiamart': Indiamart,
    'Bosch': Bosch,
    'Makita': Makita,
    'Dewalt': Dewalt,
    'BoschNav': BoschNav,
    'MakitaNav': MakitaNav,
    'DewaltNav': DewaltNav
};

export const sentimentIconMap = {
    'Very Good': VG,
    'Poor': Poor,
    'Not Bad': Not_Bad
};
